export const CMS_ACCESS_EXISTING_APPLICATION_VERIFY_APPREF =
  'CMS_ACCESS_EXISTING_APPLICATION_VERIFY_APPREF'

export const CMS_ACCESS_EXISTING_APPLICATION_IS_OTP_EXPIRED =
  'CMS_ACCESS_EXISTING_APPLICATION_IS_OTP_EXPIRED'
export const CMS_ACCESS_EXISTING_APPLICATION_IS_MAXIMUM_RETRIES_REACHED =
  'CMS_ACCESS_EXISTING_APPLICATION_IS_MAXIMUM_RETRIES_REACHED'
export const CMS_ACCESS_EXISTING_APPLICATION_IS_OTP_TRUE =
  'CMS_ACCESS_EXISTING_APPLICATION_IS_OTP_TRUE'

export const CMS_ACCESS_EXISTING_APPLICATION_RESEND_OTP =
  'CMS_ACCESS_EXISTING_APPLICATION_RESEND_OTP'
export const CMS_ACCESS_EXISTING_APPLICATION_CANCEL_OTP =
  'CMS_ACCESS_EXISTING_APPLICATION_CANCEL_OTP'
