import DOMPurify from 'dompurify'
import React from 'react'
import ReactDOM from 'react-dom'
import ReactModal from 'react-modal'
import './06-scss/main.scss'
import App from './App'
// import reportWebVitals from './reportWebVitals'

// Global hook for the two cases where we need sanitizing HTML (in Account and
// FAQs). This being a global hook is fine since there are no special cases.
// In case a need for special cases arise, `.removeHook` could be useful, but
// that will require DOMPurify to be updated to at least v2.3.8.
DOMPurify.addHook('afterSanitizeAttributes', function (node) {
    if (node instanceof HTMLAnchorElement) {
        node.setAttribute('rel', 'noopener noreferrer')
    }
})

ReactModal.setAppElement('#root')

ReactDOM.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>,
    document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals()
