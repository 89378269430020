import TextField from '../TextField'

function OTPField({ value, error, ...props }) {
    const handleChange = (event) => {
        if (/^\d*$/.test(event.target.value)) {
            props.onChange?.(event)
        }
    }

    return (
        <TextField
            {...props}
            value={value}
            error={error}
            pattern="\d{6}"
            inputMode="numeric"
            maxLength={6}
            size={8}
            style={{
                fontSize: '1.5rem',
                letterSpacing: '0.5rem',
                maxWidth: '10rem',
            }}
            onChange={handleChange}
            autoFocus
        />
    )
}

export default OTPField
