import {
    REQUEST_START,
    REQUEST_SUCCESS,
    REQUEST_FAILURE,
    REQUEST_DEFAULT,
} from '../types/requestTypes'

export const requestStart = () => {
    return (dispatch) => {
        dispatch({
            type: REQUEST_START,
        })
    }
}

export const requestSuccess = (payload) => {
    return (dispatch) => {
        dispatch({
            type: REQUEST_SUCCESS,
            payload,
        })
    }
}

export const requestFailure = (payload) => {
    return (dispatch) => {
        dispatch({
            type: REQUEST_FAILURE,
            payload,
        })
    }
}

export const requestDefault = () => {
    return { type: REQUEST_DEFAULT }
}
