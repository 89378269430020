// @ts-check

import {
    REFRESH_SESSION,
    SESSION_LOGIN,
    SESSION_LOGOUT,
} from '../types/authTypes'

const initialState = {
    session: {},
    /** @type {boolean | undefined} */
    isLoggedIn: undefined,
}

/**
 * @param {typeof initialState} state
 * @returns {typeof initialState}
 */
export default function authReducers(state = initialState, { type, payload }) {
    switch (type) {
        case SESSION_LOGIN:
            return {
                ...state,
                isLoggedIn: true,
                session: payload,
            }

        case SESSION_LOGOUT:
            return {
                ...state,
                isLoggedIn: false,
                session: {},
            }
        case REFRESH_SESSION:
            return {
                ...state,
                isLoggedIn: true,
                session: payload,
            }

        default:
            return state
    }
}
