import moment from 'moment'
import DatePicker from 'react-datepicker'
import { MdDateRange } from 'react-icons/md'
import ErrorMessage from '../ErrorMessage'
import './index.scss'

const yearRange = (start, stop, step) =>
    Array.from({ length: (stop - start) / step + 1 }, (_, i) => start + i * step)

//prettier-ignore
const months = ['January','February','March','April','May','June','July','August','September','October','November','December']

const years = yearRange(new Date().getFullYear(), new Date().getFullYear() - 200, -1)

function CustomDatePicker({
    name,
    startDate,
    format,
    onChange,
    onBlur,
    placeholder,
    error,
}) {
    const m = moment(startDate, format)
    const momentDate = m.isValid() ? m.toDate() : null

    const handleBlur = () => {
        onBlur?.({ target: { name } })
    }

    const handleChange = (date) => {
        onChange({
            target: {
                name,
                value: date ? moment(date).format(format) : '',
            },
        })


        // DatePicker doesn't consider picking a date from the popup widget as a
        // blur event, so we need to manually invoke the blur event handler.
        // The forms perform validations on blur, and it's not unreasonable to
        // run a validation when a date is picked.
        // This is kind of a hack, but future maintainers are welcome to improve
        // this.
        handleBlur()
    }

    return (
        <div
            className={`custom-datepicker ${momentDate ? 'has-value' : ''} ${
                error ? 'error' : ''
            }`}
        >
            <span>
                {placeholder.trim().length === 0 ? '\u00A0' : placeholder}
            </span>
            <div className="custom-datepicker__container">
                <DatePicker
                    renderCustomHeader={({
                        date,
                        changeYear,
                        changeMonth,
                        decreaseMonth,
                        increaseMonth,
                        prevMonthButtonDisabled,
                        nextMonthButtonDisabled,
                    }) => (
                        <div
                            style={{
                                margin: 10,
                                display: 'flex',
                                justifyContent: 'center',
                            }}
                        >
                            <button
                                type='button'
                                onClick={decreaseMonth}
                                disabled={prevMonthButtonDisabled}
                                style={{ padding: 5 }}
                            >
                                {'<'}
                            </button>
                            <select
                                value={date.getFullYear()}
                                onChange={({ target: { value } }) => {
                                    changeYear(value)
                                }}
                            >
                                {years.map((option) => (
                                    <option key={option} value={option}>
                                        {option}
                                    </option>
                                ))}
                            </select>

                            <select
                                value={months[date.getMonth()]}
                                onChange={({ target: { value } }) => {
                                    changeMonth(months.indexOf(value))
                                }}
                            >
                                {months.map((option) => (
                                    <option key={option} value={option}>
                                        {option}
                                    </option>
                                ))}
                            </select>
                            <button
                                type='button'
                                onClick={increaseMonth}
                                disabled={nextMonthButtonDisabled}
                                style={{ padding: 5 }}
                            >
                                {'>'}
                            </button>
                        </div>
                    )}
                    dateFormat="yyyy/MM/dd"
                    className="custom-datepicker__input"
                    selected={momentDate}
                    onChange={handleChange}
                    onBlur={handleBlur}
                />

                <MdDateRange className="custom-datepicker__logo" />
            </div>

            {error && (<ErrorMessage message={error} />)}
        </div>
    )
}

export default CustomDatePicker
