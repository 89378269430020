// @ts-check

import React from 'react'
import { StatusSteps } from '../../02-components'
import './index.scss'

/**
 * @param {object} props
 * @param {{ [key: string ]: { applicationStatus: string | null; isDone_: boolean | null} }} props.userData
 * @param {{ key: string; title: string }[]} props.steps
 * @param {*} props.clientActionLinkTo
 */
function StatusProgress({ userData, steps, clientActionLinkTo }) {
    return (
        <div className="statusProgress">
            {steps.map(({ key, title }) => {
                const { applicationStatus: status, isDone_: isDone } =
                    userData[key]
                return (
                    <StatusSteps
                        title={title}
                        status={status}
                        isDone={isDone}
                        clientActionLinkTo={clientActionLinkTo}
                        key={key}
                    />
                )
            })}
        </div>
    )
}

export default StatusProgress
