import { useState, useRef, useEffect } from 'react'

export default function useOtpCountDown(timeInSeconds, cb) {
    const [timer, setTimer] = useState(timeInSeconds)
    const [timerEnds, setTimerEnds] = useState(false)
    const id = useRef(null)
    const clear = (_) => window.clearInterval(id.current)

    const timeConvert = (num) => {
        var minutes = Math.floor(num / 60)
        var seconds = num % 60
        minutes = minutes < 10 ? '0' + minutes : minutes
        seconds = seconds < 10 ? '0' + seconds : seconds
        return minutes + ':' + seconds
    }

    const resetTimer = (_) => {
        setTimer(timeInSeconds)
        setTimerEnds(false)
    }

    useEffect(
        (_) => {
            id.current = window.setInterval(function () {
                setTimer((time) => time - 1)
            }, 1000)

            return (_) => clear()
        },
        [timer]
    )

    useEffect(
        (_) => {
            if (timer === 0) {
                cb()
                setTimerEnds(true)
                clear()
            }
        },
        // eslint-disable-next-line
        [timer]
    )

    return { timer, timerEnds, timeConvert, resetTimer, clear }
}
