import { Button, Typography } from '../../02-components'
import Modal from '../Modal'

function BusinessLinkModal({ renderProceedAction, show, handleClose }) {
    return (
        <Modal
            show={show}
            title="Please prepare to upload any of the following documents:"
            handleClose={handleClose}
        >
            <ul>
                <li>- Certificate of Accreditation</li>
                <li>
                    - Letter of Endorsement from Gokongwei Group Business Unit
                </li>
            </ul>
            <Typography type="p">
                If you are a GG supplier and don't have the following documents,
                please coordinate with the Business Unit you are supplying to.
            </Typography>
            {renderProceedAction({
                children: 'Proceed',
                type: 'btn-link',
            })}{' '}
            <Button onClick={handleClose} isSecondary>
                Back
            </Button>
        </Modal>
    )
}

export default BusinessLinkModal
