export const ACCESS_APPLICATION_VERIFY_APP_REF = 'ACCESS_APPLICATION_VERIFY_APP_REF'
export const ACCESS_APPLICATION_IS_OTP_TRUE = 'ACCESS_APPLICATION_IS_OTP_TRUE'
export const ACCESS_APPLICATION_IS_OTP_EXPIRED = 'ACCESS_APPLICATION_IS_OTP_EXPIRED'
export const ACCESS_APPLICATION_IS_MAXIMUM_RETRIES_REACHED =
    'ACCESS_APPLICATION_IS_MAXIMUM_RETRIES_REACHED'
export const ACCESS_EXISTING_APP_SET_ALL_TO_DEFAULT = 'ACCESS_EXISTING_APP_SET_ALL_TO_DEFAULT'
export const ACCESS_EXISTING_APP_RESEND_OTP = 'ACCESS_EXISTING_APP_RESEND_OTP'
export const ACCESS_EXISTING_APP_OTP_TO_FALSE = 'ACCESS_EXISTING_APP_OTP_TO_FALSE'
export const ACCESS_APPLICATION_GET_CUSTOMER = 'ACCESS_APPLICATION_GET_CUSTOMER'
export const ACCESS_APPLICATION_CLEAR_USER_DATA = 'ACCESS_APPLICATION_CLEAR_USER_DATA'
