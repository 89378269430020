// @ts-check

import React from 'react'
import { useRouteMatch } from 'react-router-dom'
import banner1024 from '../../../01-assets/images/banner-cms-1024x150.jpg'
import banner1440 from '../../../01-assets/images/banner-cms-1440x220.jpg'
import banner425 from '../../../01-assets/images/banner-cms-425x100.jpg'
import banner768 from '../../../01-assets/images/banner-cms-768x135.jpg'
import { BaseBanner } from '../../../02-components'

const hiddenBannerPathnames = [
  '/cms/business-information',
  '/cms/user-access-rights',
  '/cms/additional-information',
  '/cms/approval-matrix',
  '/cms/business-documents',
  '/cms/pricing-details',
  '/cms/review-and-submit',
  '/cms/confirmation',
  '/cms/your-application',
]

export default function Banner() {
  const hiddenBannerRouteMatch = useRouteMatch(hiddenBannerPathnames)
  if (hiddenBannerRouteMatch) {
    return null
  }

  return (
    <BaseBanner
      banner1440={banner1440}
      banner1024={banner1024}
      banner768={banner768}
      banner425={banner425}
    />
  )
}
