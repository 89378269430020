import ErrorMessage from '../ErrorMessage'
import UnlabeledDropdown from '../UnlabeledDropdown'
import './index.scss'

function Dropdown({ title, options, error, value, loading, ...props }) {
    return (
        <div className="dropdown">
            <span className="dropdown__label">
                {title.trim().length === 0 ? '\u00A0' : title}
            </span>
            <UnlabeledDropdown
                value={value}
                error={error}
                options={options}
                loading={loading}
                {...props}
            />
            {error && <ErrorMessage message={error} />}
        </div>
    )
}

export default Dropdown
