// @ts-check

import { CMS_PRICING_DETAILS_INIT, CMS_PRICING_DETAILS_RESET } from './types'

/**
 * @typedef {import('./types').PricingBase} PricingBase
 * @typedef {import('./types').PricingInfo} PricingInfo
 */

/**
 * @template T
 * @typedef {object} Entries
 * @property {{ [id: string]: T? }} byId
 * @property {string[]} allIds
 */

const initialState = {
  pricingBaseId: {
    adb: '',
    fee: '',
  },

  /** @type {Entries<PricingInfo>} */
  pricingInfo: {
    byId: {},
    allIds: [],
  },

  /** @type {string[]} */
  productIds: [],
}

/** @typedef {typeof initialState} CmsPricingDetailsState */

/**
 * @template {string} T
 * @template [P=unknown]
 * @typedef {object} Action
 * @property {T} type
 * @property {P} payload
 */

/**
 * @typedef {object} InitActionPayload
 * @property {{ adb: string, fee: string }} pricingBase
 * @property {PricingInfo[]} pricingInfo
 * @property {string[]} productIds
 *
 * @typedef {Action<typeof CMS_PRICING_DETAILS_INIT, InitActionPayload>} InitAction
 *
 * @typedef {Action<typeof CMS_PRICING_DETAILS_RESET>} ResetAction
 *
 * @typedef {InitAction | ResetAction} Actions
 */

/**
 * @param {typeof initialState} state
 * @param {Actions} action
 * @returns {CmsPricingDetailsState}
 */
export default function cmsPricingDetailsReducer(
  state = initialState,
  { type, payload }
) {
  switch (type) {
    case CMS_PRICING_DETAILS_INIT: {
      const { pricingBase, pricingInfo, productIds } = payload
      return {
        pricingBaseId: {
          adb: pricingBase.adb,
          fee: pricingBase.fee,
        },
        pricingInfo: pricingInfo.reduce(
          (/** @type {Entries<PricingInfo>} */ obj, item) => {
            obj.byId[item.id] = item
            obj.allIds.push(item.id)
            return obj
          },
          { byId: {}, allIds: [] }
        ),
        productIds,
      }
    }
    case CMS_PRICING_DETAILS_RESET: {
      return initialState
    }
    default: {
      return state
    }
  }
}
