import React from 'react'
import './index.scss'
function Checkbox({ onChange, checked, name, error, disabled = false }) {
    const handleChange = (event) => {
        if (!disabled) {
            onChange(event)
        }
    }
    return (
        <label className={`checkbox ${error ? 'error' : ''}`}>
            <input
                type="checkbox"
                name={name}
                checked={checked}
                onChange={handleChange}
            />
            <span className="checkbox__check"></span>
        </label>
    )
}

export default Checkbox
