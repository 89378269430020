import { Redirect, Route, useRouteMatch } from 'react-router-dom'
import { IdleTimeChecker, Loader } from '..'
import useIsAuthenticated from '../../05-hooks/useIsAuthenticated'
import useLogout from '../../05-hooks/useLogout'
import { ACCESS_EXISTING_APP } from '../../07-data/paths'
import {
    CMS_ACCESS_EXISTING_APPLICATION,
    CMS_ROOT,
} from '../../cms/constants/pathnames'

const ProtectedRoute = ({ children, ...rest }) => {
    const { isAuthenticated } = useIsAuthenticated()
    const cmsRouteMatch = useRouteMatch(CMS_ROOT)

    const logout = useLogout()

    return (
        <Route
            {...rest}
            render={() => {
                if (isAuthenticated === undefined) {
                    return <Loader />
                }

                if (!isAuthenticated) {
                    if (cmsRouteMatch) {
                        return <Redirect to={CMS_ACCESS_EXISTING_APPLICATION} />
                    } else {
                        return <Redirect to={ACCESS_EXISTING_APP} />
                    }
                }

                return (
                    <>
                        <IdleTimeChecker onLogout={logout} />
                        {children}
                    </>
                )
            }}
        />
    )
}

export default ProtectedRoute
