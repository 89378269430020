export const CMS_APPROVAL_MATRIX_INIT_PRODUCTS =
  'CMS_APPROVAL_MATRIX_INIT_PRODUCTS'
export const CMS_APPROVAL_MATRIX_INIT_APPROVERS =
  'CMS_APPROVAL_MATRIX_INIT_APPROVERS'
export const CMS_APPROVAL_MATRIX_INIT_DATA = 'CMS_APPROVAL_MATRIX_INIT_DATA'
export const CMS_APPROVAL_MATRIX_SET_APPROVER_OPTION =
  'CMS_APPROVAL_MATRIX_SET_APPROVER_OPTION'
export const CMS_APPROVAL_MATRIX_SET_APPROVAL_REQUIREMENT =
  'CMS_APPROVAL_MATRIX_SET_APPROVAL_REQUIREMENT'
export const CMS_APPROVAL_MATRIX_SET_AMOUNT_RANGE =
  'CMS_APPROVAL_MATRIX_SET_AMOUNT_RANGE'
export const CMS_APPROVAL_MATRIX_ADD_APPROVER =
  'CMS_APPROVAL_MATRIX_ADD_APPROVER'
export const CMS_APPROVAL_MATRIX_REMOVE_APPROVER =
  'CMS_APPROVAL_MATRIX_REMOVE_APPROVER'
export const CMS_APPROVAL_MATRIX_SET_MAX_LEVEL =
  'CMS_APPROVAL_MATRIX_SET_MAX_LEVEL'
export const CMS_APPROVAL_MATRIX_SET_CURRENT_PRODUCT_ID =
  'CMS_APPROVAL_MATRIX_SET_CURRENT_PRODUCT_ID'
export const CMS_APPROVAL_MATRIX_COPY_FIRST_PRODUCT_INFORMATION =
  'CMS_APPROVAL_MATRIX_COPY_FIRST_PRODUCT_INFORMATION'
export const CMS_APPROVAL_MATRIX_CLEAR_PRODUCT_INFORMATION =
  'CMS_APPROVAL_MATRIX_CLEAR_PRODUCT_INFORMATION'
export const CMS_APPROVAL_MATRIX_RESET = 'CMS_APPROVAL_MATRIX_RESET'
