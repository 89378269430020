import React from 'react'
import ErrorMessage from '../ErrorMessage'
import './index.scss'

// prettier-ignore
function TextField({ placeholder, placeholderDesc = '', value, error, ...props }) {
    let label
    if (typeof placeholder === 'string') {
        label = placeholder.trim().length === 0 ? '\u00A0' : placeholder
    } else {
        label = placeholder
    }

    return (
        <label className='custom-field'>
            <span className='custom-field__label'>{label}</span>
            <input
                className={`custom-field__input ${value ? 'has-value' : ''} ${error ? 'error' : ''}`}
                {...props}
                value={value}
                placeholder={placeholderDesc}
            />
            {error && <ErrorMessage message={error} />}
        </label>
    )
}

export default TextField
