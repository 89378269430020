import { useCallback, useEffect, useState } from 'react'

/**
 * @template V
 * 
 * @param {V} init 
 * @param {*} callback 
 * @param {*} validation 
 */
export default function useForm(init, callback, validation) {
    const [values, setValues] = useState(init)
    /**
     * @typedef {{ [Key in keyof V]?: string }} Errors
     * @type {[Errors, React.Dispatch<Errors>]}
     */
    const [errors, setErrors] = useState({})
    const [touched, setTouched] = useState({})
    const [isSubmitting, setIsSubmitting] = useState(false)

    //check if value is boolean then return it as boolean rather than string
    const handleType = (value) => {
        if (value && typeof value === 'string') {
            if (value.toLowerCase() === 'true') return true
            if (value.toLowerCase() === 'false') return false
        }
        return value
    }

    const handleInitializeValues = (values) => {
        setValues((x) => ({ ...values }))
    }

    const handleChange = useCallback((e) => {
        try {
            /*
            Old check if a field is a date picker.
            No longer needed as the date picker now goes under the same check as
            the other non-file field types. Also, forms and date pickers are now
            using a string representation of dates, instead of Date objects.

            // if (Object.prototype.toString.call(e.date) === '[object Date]') {
                // setValues((values) => ({
                //     ...values,
                //     [e.name]: e.date,
                // }))
            */
            if (e.target.type === 'file') {
                const { name, files } = e.target

                const newValues = {
                    ...values,
                    [name]: files[0] ?? '',
                }
                setValues(newValues)

                const validationResults = validation(newValues)
                setErrors(errors => ({
                    ...errors,
                    [`${name}.name`]: validationResults[`${name}.name`],
                    [`${name}.type`]: validationResults[`${name}.type`],
                    [`${name}.size`]: validationResults[`${name}.size`],
                }))
                setTouched((touched) => ({
                    ...touched,
                    [`${name}.name`]: true,
                    [`${name}.type`]: true,
                    [`${name}.size`]: true,
                }))
            } else {
                const { value, name, type, checked } = e.target
                setValues((values) => ({
                    ...values,
                    [name]: type === 'checkbox' ? checked : handleType(value),
                }))
            }
        } catch (err) {
            console.log('Error: useForm :' + err.toString())
        }
    }, [validation, values])

    // Display error prompts as the user types, but only for fields that the
    // user has already visited.
    useEffect(() => {
        const validationResults = validation(values)

        setErrors((errors) =>
            Object.keys(values).reduce(
                (accumulatedErrors, fieldName) => {
                    if (!touched[fieldName]) {
                        return accumulatedErrors
                    }
                    if (!validationResults[fieldName]) {
                        const nextAcc = { ...accumulatedErrors }
                        delete nextAcc[fieldName]
                        return nextAcc
                    }
                    return {
                        ...accumulatedErrors,
                        [fieldName]: validationResults[fieldName],
                    }
                },
                errors
            )
        )
    }, [touched, validation, values])

    const createChangeWithResetHandler = useCallback(
        (defaultValues) => (e) => {
            handleChange(e)
            setValues((values) => ({ ...values, ...defaultValues }))
        },
        [handleChange],
    )

    const handleReset = (errors = init) => {
        setValues(init)
        setErrors(errors)
        setTouched({})
        setIsSubmitting(false)
    }

    const resetForm = () => {
        handleReset({})
    }

    const handleBlur = (event) => {
        const name = event.target.name
        const validationResults = validation(values)
        setErrors(errors => ({
            ...errors,
            [name]: validationResults[name]
        }))
        setTouched(touched => ({ ...touched, [name]: true }))
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        let errors = validation(values)
        setIsSubmitting(true)
        setErrors(errors)
        if (Object.keys(errors).length === 0) {
            callback(e)
            return
        }
        setTouched(
            Object.keys(values).reduce(
                (accumulatedTouched, fieldName) => ({
                    ...accumulatedTouched,
                    [fieldName]: true
                }),
                touched
            )
        )
        setIsSubmitting(false)
    }

    return {
        values,
        handleChange,
        handleSubmit,
        handleReset,
        handleBlur,
        errors,
        isSubmitting,
        handleInitializeValues,
        createChangeWithResetHandler,
        resetForm,
        setValues, // escape hatch; use sparingly
    }
}
