//SESSION MANAGEMENT APIs
export const SESSION_LOGOUT_API = '/api/AccessApp/logout'
export const REFRESH_SESSION_API = '/api/AccessApp/refreshToken'

//YOUR INFORMATION && USER VERIFICATION
export const YOUR_INFORMATION_REGISTER_USER_API = '/api/UserReg/UsersRegistration'
export const USER_VERIFICATION_VALIDATE_OTP = '/api/UserReg/validateOTP'
export const USER_VERIFICATION_RESEND_OTP_API = '/api/UserReg/resendOTP'

// ONLINE BANKING ENROLLMENT
export const ONLINE_BANKING_FETCH_USER_API = '/api/OnlineBanking/getOnlineUser'
export const ONLINE_BANKING_DELETE_USER_API = '/api/OnlineBanking/deleteOnlineBankingUser'
export const ONLINE_BANKING_UPDATE_USER_API = '/api/OnlineBanking/insertOnlineBankingUser'
export const ONLINE_BANKING_SAVE_E2BANKING_AGREEMENT_API =
    '/api/OnlineBanking/saveE2bankingAgreement'

// BUSINESS DOCUMENTS
export const BUSINESS_DOCUMENT_UPDATE_API = '/api/BusinessDocs/saveBusinessDocuments'

export const BUSINESS_DOCUMENT_FETCH_TYPE_API = '/api/BusinessDocs/getBusinessType'

export const BUSINESS_DOCUMENTS_FETCH_EXISTING_DATA_API = '/api/BusinessDocs/getBusinessDocs'
// REVIEW LIST
export const REVIEW_FETCH_LIST_API = '/api/ReviewAndSubmit/getReviewList'
export const REVIEW_PAGE_UPDATE_APPLICATION_STATUS_API =
    '/api/ReviewAndSubmit/updateApplicationStatus'
export const REVIEW_PAGE_UPDATE_APPLICATION_TYPE_API = '/api/ReviewAndSubmit/updateAccountType'

// BUSINESS INFORMATION
export const BUSINESS_INFO_1_FETCH_EXISTING_DATA_API = '/api/BusinessInfo1/getBusinessInfo1'

export const BUSINESS_INFO_2_FETCH_EXISTING_DATA_API = '/api/BusinessInfo2/getBusinessInfo2'

export const BUSINESS_INFO_3_FETCH_EXISTING_DATA_API = '/api/BusinessInfo3/getBusinessInfo3'
// OWNERS INFORMATION
export const OWNERS_INFORMATION_FETCH_SIGNING_REQUIREMENTS_API = '/api/OwnerSignatories/getSigningRequirements'
export const OWNERS_INFORMATION_FETCH_USER_API = '/api/OwnerSignatories/getOwnershipInfoList'
export const OWNERS_INFORMATION_DELETE_USER_API = '/api/OwnerSignatories/deleteOwnerSignatories'
export const OWNERS_INFORMATION_ADD_INFO_API = '/api/OwnerSignatories/saveSigningRequirements'

//CONFIRMATION PAGE

export const CONFIRMATION_PAGE_ACCESS_OPENING_FORM =
    '/api/ConfirmationPage/generateAccountOpeningAppData'
export const CONFIRMATION_PAGE_ONLINE_BANKING_ENROLLMENT_FORM =
    'api/ConfirmationPage/generateOnlineBankingForm'

//ACCESS EXISTING APPLICATION
export const ACCESS_APPLICATION_VALIDATE_APPREF = '/api/AccessApp/validateAppRef'
export const ACCESS_APPLICATION_VALIDATE_OTP = '/api/AccessApp/validateOTP'
export const ACCESS_APPLICATION_GET_CUSTOMER_API = '/api/CustomerLanding/getCustomerLandingPage'

//REQUEST CALL BACK
export const REQUEST_CALLBACK_API = '/api/UserReg/RequestCallBack'
