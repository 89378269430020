import React from 'react'
import './index.scss'
function Loader() {
    return (
        <div className='loader'>
            <div className='loader__dot'></div>
            <div className='loader__dot loader__dot_2'></div>
            <div className='loader__dot loader__dot_3'></div>
        </div>
    )
}

export default Loader
