import axios from 'axios'
import store from '../09-store'
import { refreshToken } from '../09-store/actions/authActions'
import { SESSION_LOGOUT } from '../09-store/types/authTypes'
import { toast } from 'react-toastify'

let isAlreadyFetchingAccessToken = false
let subscribers = []

const axiosConfig = {
    baseURL: process.env.REACT_APP_BASE_URL, //backend Dev API server port
    timeout: 0, //Can be change base in how long to make the request
    withCredentials: true,
}

const onAccessTokenFetched = (access_token) => {
    subscribers = subscribers.filter((callback) => callback(access_token))
}

const addSubscriber = (callback) => {
    subscribers.push(callback)
}

const client = axios.create(axiosConfig)

client.interceptors.response.use(
    (response) => response,
    (error) => {
        const {
            config,
            response: { status, data },
        } = error
        const originalRequest = config

        if (status === 401) {
            if (!isAlreadyFetchingAccessToken) {
                isAlreadyFetchingAccessToken = true
                store.dispatch(refreshToken()).then((access_token) => {
                    isAlreadyFetchingAccessToken = false
                    onAccessTokenFetched(access_token)
                    subscribers = []
                })
            }

            return new Promise((resolve) => {
                addSubscriber((access_token) => {
                    originalRequest.headers.Authorization = 'Bearer ' + access_token
                    resolve(axios(originalRequest))
                })
            })
        }

        if (status === 403) {
            store.dispatch({
                type: SESSION_LOGOUT,
            })
            if (data) toast.error(data)

            return Promise.reject(data)
        }

        return Promise.reject(error)
    }
)

export const aXios = client

export const setAuthToken = (token) => {
    if (token) aXios.defaults.headers.common['Authorization'] = `Bearer ${token}`
    else delete aXios.defaults.headers.common['Authorization']
}
