// @ts-check

import { CMS_DOCUMENTS_INIT } from './types'

export const timestampFormat = 'M/D/YYYY H:mm:ss A'

/**
 * @typedef {object} ProductDocument
 * @prop {string} productId
 * @prop {string} productName
 * @prop {string} timestamp
 * @prop {import('../userAccessRights/reducer').Documents} documents
 */

const initialState = {
  cmsMasterAgreementTimestamp: '',
  /** @type {string | undefined} */
  e2BankingEnrollmentFormTimestamp: undefined,
  /** @type {string | undefined} */
  e2BankingEnrollmentFormProductId: undefined,
  /** @type {ProductDocument[]} */
  productDocuments: [],
}

/**
 * @typedef {object} CmsDocumentsInitAction
 * @prop {typeof CMS_DOCUMENTS_INIT} type
 * @prop {typeof initialState} payload
 */

/**
 * @param {typeof initialState} state
 * @param {*} action
 * @returns {typeof initialState}
 */
export default function cmsDocumentsReducer(state = initialState, action) {
  switch (action.type) {
    case CMS_DOCUMENTS_INIT: {
      const { payload } = /** @type {CmsDocumentsInitAction} */ (action)
      return {
        ...state,
        cmsMasterAgreementTimestamp: payload.cmsMasterAgreementTimestamp,
        e2BankingEnrollmentFormTimestamp:
          payload.e2BankingEnrollmentFormTimestamp,
        e2BankingEnrollmentFormProductId:
          payload.e2BankingEnrollmentFormProductId,
        productDocuments: payload.productDocuments,
      }
    }
    default:
      return state
  }
}
