import { GET_ERRORS, CLEAR_ERRORS } from '../types/errorTypes'

const initialState = {
    errorData: [] || {},
}

export default function errorReducers(state = initialState, { type, payload }) {
    switch (type) {
        case GET_ERRORS:
            return {
                ...state,
                errorData: payload,
            }
        case CLEAR_ERRORS:
            return {
                ...state,
                errorData: [] || {},
            }
        default:
            return state
    }
}
