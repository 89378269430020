// @ts-check

import { FINACLE_BUSINESS_INFO_FETCH } from '../types/finacleBusinessInfoTypes'

/**
 * @typedef {object} AccountNumber
 * @prop {string} id
 * @prop {string} description
 * @prop {string} accountType
 * @prop {string} accountOpeningDate
 *
 * @typedef {object} FinacleBusinessInfo
 * @prop {string} cifNumber
 * @prop {AccountNumber[]} accountNumbers
 * @prop {string} otherName
 * @prop {string} dateOfRegistration
 * @prop {string} companyName
 * @prop {string} businessTIN
 * @prop {string} dtiSecRegistrationNumber
 * @prop {string} businessType
 * @prop {string} corporateType
 * @prop {string} sourceOfFunds
 * @prop {string} onlinePresence
 * @prop {string} registeredBusinessAddress
 * @prop {string} region
 * @prop {string} province
 * @prop {string} cityTown
 * @prop {string} zipCode
 * @prop {string} country
 */

const initialState = {
    /** @type {FinacleBusinessInfo | null} */
    data: null,
}

/**
 * @typedef {object} FinacleBusinessInfoFetchAction
 * @prop {typeof FINACLE_BUSINESS_INFO_FETCH} type
 * @prop {FinacleBusinessInfo} data
 */

/**
 * @param {typeof initialState} state
 * @param {FinacleBusinessInfoFetchAction} action
 * @returns {typeof initialState}
 */
export default function finacleBusinessInfoReducer(
    state = initialState,
    action
) {
    switch (action.type) {
        case FINACLE_BUSINESS_INFO_FETCH: {
            return {
                ...state,
                data: action.data,
            }
        }
        default: {
            return state
        }
    }
}
