// @ts-check

const productNames = /** @type {const} */ ({
  EGOV: 'eGOV',
  PAYROLL_PAYOUT: 'Payroll Payout',
  PAYROLL_PAYOUT_WITH_FREE_INSURANCE: 'Payroll Payout (with FREE Insurance)',
  PAYROLL_SUITE_LITE: 'Payroll Suite (Lite)',
  PAYROLL_SUITE_EXPANDED: 'Payroll Suite (Expanded)',
  SUPPLIER_SETTLEMENT: 'Supplier Settlement (Batch EFT)',
  PESONET: 'Pesonet (Direct2Bank)',
  CORPORATE_ADA_BILLER: 'Corporate ADA (Biller)',
  CORPORATE_ADA_PAYOR: 'Corporate ADA (Payor)',
  CHECKWRITER_PLUS_MC: "Checkwriter Plus - Manager's Check",
  CHECKWRITER_PLUS_CC: 'Checkwriter Plus - Corporate Check',
  SUPPLY_CHAIN_FINANCING: 'Supply Chain Financing (Supplier)',
  BASIC_ACCOUNT_SERVICES: 'Basic Account Services',
})

export default productNames

export const lowerCaseProductNames = Object.entries(productNames).reduce(
  (acc, [key, value]) => ({
    ...acc,
    [key]: value.toLowerCase(),
  }),
  /** @type {{ [K in keyof typeof productNames]: Lowercase<(typeof productNames)[K]> }} */ ({})
)
