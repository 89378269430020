import { AiOutlineHome } from 'react-icons/ai'
import { CustomAlert } from '../../02-components'
import useCustomAlert from '../../05-hooks/useCustomAlert'

function NavbarHomeButton() {
    const { openAlert, handleCloseAlert, handleOpenAlert } = useCustomAlert()

    return (
        <>
            <a
                className="navbar__menu-item"
                href="https://www.robinsonsbank.com.ph"
                onClick={(e) => {
                    e.preventDefault()
                    handleOpenAlert()
                }}
            >
                <AiOutlineHome
                    className="navbar__menu-icon"
                    aria-hidden="true"
                />{' '}
                Home
            </a>

            <CustomAlert
                secondaryAction={handleCloseAlert}
                secondaryActionText="Cancel"
                openAlert={openAlert}
                primaryAction={() => {
                    handleCloseAlert()
                    const a = document.createElement('a')
                    document.body.appendChild(a)
                    a.style = 'display: none'
                    a.href = 'https://www.robinsonsbank.com.ph/'
                    a.target = '_blank'
                    a.click()
                    a.remove()
                }}
                primaryActionText="Ok"
            >
                Kindly make sure to check your progress before proceeding.
            </CustomAlert>
        </>
    )
}

export default NavbarHomeButton
