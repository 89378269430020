// @ts-check

import { CMS_ADDITIONAL_INFORMATION_PRODUCTS_INIT } from '../types'

const initialState = {
  /** @type {boolean | undefined} */
  corporateAdaBiller: undefined,
  /** @type {boolean | undefined} */
  corporateAdaPayor: undefined,
  /** @type {boolean | undefined} */
  egov: undefined,
  /** @type {boolean | undefined} */
  scf: undefined,
}

/**
 * @typedef {object} ProductsInitActionPayload
 * @property {boolean} corporateAdaBiller
 * @property {boolean} corporateAdaPayor
 * @property {boolean} egov
 * @property {boolean} scf
 *
 * @typedef {object} ProductsInitAction
 * @property {typeof CMS_ADDITIONAL_INFORMATION_PRODUCTS_INIT} type
 * @property {ProductsInitActionPayload} payload
 */

/**
 * @param {typeof initialState} state
 * @param {ProductsInitAction} action
 * @returns {typeof initialState}
 */
export default function productsReducer(
  state = initialState,
  { type, payload }
) {
  switch (type) {
    case CMS_ADDITIONAL_INFORMATION_PRODUCTS_INIT: {
      const { corporateAdaBiller, corporateAdaPayor, egov, scf } = payload
      return { corporateAdaBiller, corporateAdaPayor, egov, scf }
    }
    default: {
      return state
    }
  }
}
