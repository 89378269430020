// @ts-check

import { CMS_ADDITIONAL_INFORMATION_CORPORATE_ADA_BILLER_INIT } from '../types'

// `userID` here is user-provided data, as opposed to the usual `id`
/**
 * @typedef {object} CorporateAdaBillerUserIDs
 * @property {string} id
 * @property {string} userID
 */

const initialState = {
  fields: {
    depositAccountNumber: '',
    accountType: '',
  },
}

/**
 * @typedef {object} CorporateAdaBillerInitActionPayload
 * @property {{ depositAccountNumber: string; accountType: string }} fields
 *
 * @typedef {object} CorporateAdaBillerInitAction
 * @property {typeof CMS_ADDITIONAL_INFORMATION_CORPORATE_ADA_BILLER_INIT} type
 * @property {CorporateAdaBillerInitActionPayload} payload
 *
 * @typedef {object} CorporateAdaBillerSetUserIDActionPayload
 * @property {string} id
 * @property {string} userID
 */

/**
 * @param {typeof initialState} state
 * @param {CorporateAdaBillerInitAction} action
 * @returns {typeof initialState}
 */
export default function corporateAdaBillerReducer(
  state = initialState,
  { type, payload }
) {
  switch (type) {
    case CMS_ADDITIONAL_INFORMATION_CORPORATE_ADA_BILLER_INIT: {
      const { fields } = payload

      return {
        ...state,
        fields: {
          depositAccountNumber: fields.depositAccountNumber,
          accountType: fields.accountType,
        },
      }
    }
    default: {
      return state
    }
  }
}
