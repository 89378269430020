import React from 'react'
import { useSelector } from 'react-redux'
import { useRouteMatch } from 'react-router-dom'
import { Spinner } from '../../02-components'
import { NavBar } from '../../03-ui'
import Footer from '../../03-ui/Footer'
import CMSBanner from '../../cms/components/Banner'
import { CMS_ROOT } from '../../cms/constants/pathnames'
import DepositBanner from '../DepositBanner'
import './index.scss'

function TheLayout({ children }) {
    const cmsRouteMatch = useRouteMatch(CMS_ROOT)
    const isLoading = useSelector((state) => state.request.isLoading)

    return (
        <>
            <NavBar />
            <DepositBanner />
            {cmsRouteMatch && <CMSBanner />}
            {isLoading && <Spinner />}
            <div className="layout">{children}</div>
            <Footer />
        </>
    )
}

export default TheLayout
