// @ts-check

/**
 * @template T
 * @param {T | undefined} x
 * @returns {x is T}
 */
export default function notUndefined(x) {
  return x !== undefined
}
