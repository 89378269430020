import { useState, useEffect } from 'react'
import { aXios } from '../07-data/aXios'
import { toast } from 'react-toastify'

export default function useResponseData(api, token = '') {
    const [data, setData] = useState([])
    const [loading, setIsLoading] = useState(true)

    useEffect(() => {
        if (!api) return

        const fetchData = async () => {
            try {
                let res
                //If Bearer Token is needed in GET Request
                if (token) {
                    res = await aXios.get(api, {
                        headers: { Authorization: `Bearer ${token}` },
                    })
                } else {
                    res = await aXios.get(api)
                }

                setData(res.data)
                setIsLoading(false)
            } catch (error) {
                if (error.response?.data?.Message !== undefined) {
                    toast.error('There seems to be a problem, Please try again later')
                    toast.error(error?.message)
                }
            }
        }

        fetchData()

        return () => {
            setData([])
            setIsLoading(false)
        }
        // eslint-disable-next-line
    }, [api])

    return { data, loading }
}
