import { AiOutlineQuestionCircle } from 'react-icons/ai'
import { Link as RRLink } from 'react-router-dom'
import { HELP_PAGE } from '../../07-data/paths'

function NavbarHelpButton() {
    return (
        // This app's custon Link internally uses React Router's NavLink,
        // which has no target prop. Using standard React Router Link
        // instead.
        <RRLink className="navbar__menu-item" to={HELP_PAGE} target="_blank">
            <AiOutlineQuestionCircle
                className="navbar__menu-icon"
                aria-hidden="true"
            />{' '}
            Help
        </RRLink>
    )
}

export default NavbarHelpButton
