import React from 'react'
import './index.scss'
import { NavLink } from 'react-router-dom'
import Helper from '../../08-util/helper'

const helper = new Helper()

function Link({ href, download, children, to = '/', type = '', onClick, style, disabled, target }) {
    return href ? (
        <a target={target} href={href} download={download}>
            {children}
        </a>
    ) : (
        <NavLink
            style={style}
            onClick={onClick}
            to={to}
            className={`${helper.setClassName(type)} ${disabled && 'disabled'}`}
        >
            {children}
        </NavLink>
    )
}

export default Link
