import { useEffect, useRef } from 'react'
import './index.scss'

function Step({ num, info, isActive = false, isDone }) {
    const ref = useRef()

    useEffect(() => {
        if (isActive) {
            ref.current.scrollIntoView(false)
        }
    }, [isActive])

    let classModifier = ''
    if (isActive) {
        classModifier = ' step_status_active'
    } else if (isDone) {
        classModifier = ' step_status_done'
    }

    return (
        <li ref={ref} className={`step${classModifier}`}>
            <div className="step__content">
                <span className='step__num'>{num}</span>
                <span className='step__label'>{info}</span>
            </div>
        </li>
    )
}

export default Step
