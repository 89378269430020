// @ts-check

import {
  CMS_ADDITIONAL_INFORMATION_EGOV_INIT,
  CMS_ADDITIONAL_INFORMATION_EGOV_SET_USER_INFO,
} from '../types'

/**
 * @typedef {object} EGovUserInfo
 * @property {string} id
 * @property {string} designation
 * @property {string} companyEmail
 */

const initialState = {
  fields: {
    sssSelected: false,
    sss: '',
    philhealthSelected: false,
    philhealth: '',
    pagibigSelected: false,
    pagibig: '',
  },
  userInfo: {
    /** @type {{ [id: string]: EGovUserInfo }} */
    byId: {},
    /** @type {string[]} */
    allIds: [],
  },
}

/**
 * @typedef {object} EGovInitActionPayload
 * @property {EGovUserInfo[]} users
 * @property {{
 *  sssSelected: boolean,
 *  sss: string,
 *  philhealthSelected: boolean,
 *  philhealth: string,
 *  pagibigSelected: boolean,
 *  pagibig: string,
 * }} fields
 *
 * @typedef {object} EGovInitAction
 * @property {typeof CMS_ADDITIONAL_INFORMATION_EGOV_INIT} type
 * @property {EGovInitActionPayload} payload
 *
 * @typedef {object} EGovSetUserInfoActionPayload
 * @property {string} id
 * @property {string} designation
 * @property {string} companyEmail
 *
 * @typedef {object} EGovSetUserInfoAction
 * @property {typeof CMS_ADDITIONAL_INFORMATION_EGOV_SET_USER_INFO} type
 * @property {EGovSetUserInfoActionPayload} payload
 */

/**
 * @param {typeof initialState} state
 * @param {EGovInitAction | EGovSetUserInfoAction} action
 * @returns {typeof initialState}
 */
export default function egovReducer(state = initialState, { type, payload }) {
  switch (type) {
    case CMS_ADDITIONAL_INFORMATION_EGOV_INIT: {
      const { users, fields } = payload

      /** @type {typeof state.userInfo.byId} */
      const byId = {}
      const allIds = []
      for (const user of users) {
        byId[user.id] = {
          id: user.id,
          designation: user.designation,
          companyEmail: user.companyEmail,
        }
        allIds.push(user.id)
      }

      return {
        ...state,
        fields: {
          sssSelected: fields.sssSelected,
          sss: fields.sss,
          philhealthSelected: fields.philhealthSelected,
          philhealth: fields.philhealth,
          pagibigSelected: fields.pagibigSelected,
          pagibig: fields.pagibig,
        },
        userInfo: { byId, allIds },
      }
    }
    case CMS_ADDITIONAL_INFORMATION_EGOV_SET_USER_INFO: {
      const { id, designation, companyEmail } = payload
      return {
        ...state,
        userInfo: {
          ...state.userInfo,
          byId: {
            ...state.userInfo.byId,
            [id]: {
              ...state.userInfo.byId[id],
              designation,
              companyEmail,
            },
          },
        },
      }
    }
    default: {
      return state
    }
  }
}
