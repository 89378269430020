// @ts-check

import { CMS_ADDITIONAL_INFORMATION_CORPORATE_ADA_PAYOR_INIT } from '../types'

const initialState = {
  fields: {
    accountType: '',
    name: '',
    accountToBeDebited: '',
    referenceOrAccountNumber: '',
    emailRecipientsOfPaymentAdvise: '',
    billerOrMerchantName: '',
  },
}

/**
 * @typedef {object} CorporateAdaPayorInitActionPayload
 * @property {{
 *  accountType: string
 *  name: string
 *  accountToBeDebited: string
 *  referenceOrAccountNumber: string
 *  emailRecipientsOfPaymentAdvise: string
 *  billerOrMerchantName: string
 * }} fields
 *
 * @typedef {object} CorporateAdaPayorInitAction
 * @property {typeof CMS_ADDITIONAL_INFORMATION_CORPORATE_ADA_PAYOR_INIT} type
 * @property {CorporateAdaPayorInitActionPayload} payload
 */

/**
 * @param {typeof initialState} state
 * @param {CorporateAdaPayorInitAction} action
 * @returns {typeof initialState}
 */
export default function corporateAdaPayorReducer(
  state = initialState,
  { type, payload }
) {
  switch (type) {
    case CMS_ADDITIONAL_INFORMATION_CORPORATE_ADA_PAYOR_INIT: {
      const { fields } = payload

      return {
        ...state,
        fields: {
          accountType: fields.accountType,
          name: fields.name,
          accountToBeDebited: fields.accountToBeDebited,
          referenceOrAccountNumber: fields.referenceOrAccountNumber,
          emailRecipientsOfPaymentAdvise: fields.emailRecipientsOfPaymentAdvise,
          billerOrMerchantName: fields.billerOrMerchantName,
        },
      }
    }
    default: {
      return state
    }
  }
}
