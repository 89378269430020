import useModal from '../../05-hooks/useModal'
import useResponseData from '../../05-hooks/useResponseData'
import BusinessLinkModal from './BusinessLinkModal'
import './index.scss'
import ProductTableCell from './ProductTableCell'

function ProductTable({
    actionText,
    renderBusinessSavingsAction,
    renderBusinessCheckingAction,
    renderBusinessLinkProceedAction,
}) {
    const { show, handleShow, handleClose } = useModal()
    const { data, loading } = useResponseData('/api/DX/ProductDetails')

    // Use a dummy array so <Skeleton> can render while waiting for data
    const rows = loading ? Array(6).fill({}) : data

    return (
        <div className="product-table">
            <table className="product-table__table">
                <thead className="product-table__header">
                    <tr>
                        <th className="product-table__empty-header-cell" />
                        <th className="product-table__header-cell">
                            Business Savings
                        </th>
                        <th className="product-table__header-cell">
                            Business Checking
                        </th>
                        <th className="product-table__header-cell">
                            Business Link{' '}
                            <small className="product-table__header-small-text">
                                (Gokongwei Group Supplier)
                            </small>
                        </th>
                    </tr>
                </thead>
                <tbody className="product-table__body">
                    {rows?.map((row, index) => (
                        <tr
                            // Use index as keys when rows is the dummy array
                            key={row.parentDesc || index}
                            className="product-table__body-row"
                        >
                            <th
                                className="product-table__body-row-header"
                                scope="row"
                            >
                                <ProductTableCell
                                    value={row.parentDesc}
                                    loading={loading}
                                />
                            </th>
                            <td className="product-table__body-cell">
                                <ProductTableCell
                                    value={row.businessSavingsDesc}
                                    loading={loading}
                                />
                            </td>
                            <td className="product-table__body-cell">
                                <ProductTableCell
                                    value={row.businessCheckingDesc}
                                    loading={loading}
                                />
                            </td>
                            <td className="product-table__body-cell">
                                <ProductTableCell
                                    value={row.businessLinkDesc}
                                    loading={loading}
                                />
                            </td>
                        </tr>
                    ))}
                </tbody>
                <tfoot>
                    <tr>
                        <th
                            className="product-table__empty-footer-cell"
                            scope="row"
                        />
                        <td className="product-table__footer-cell">
                            {renderBusinessSavingsAction({
                                className: 'product-table__action',
                                children: actionText,
                            })}
                        </td>
                        <td className="product-table__footer-cell">
                            {renderBusinessCheckingAction({
                                className: 'product-table__action',
                                children: actionText,
                            })}
                        </td>
                        <td className="product-table__footer-cell">
                            <button
                                className="product-table__action"
                                type="button"
                                onClick={handleShow}
                            >
                                {actionText}
                            </button>
                        </td>
                    </tr>
                </tfoot>
            </table>

            <BusinessLinkModal
                renderProceedAction={renderBusinessLinkProceedAction}
                show={show}
                handleClose={handleClose}
            />
        </div>
    )
}

export default ProductTable
