export const BUSINESS_CHECKING_ACCOUNT = 'business-checking'
export const BUSINESS_SAVINGS_ACCOUNT = 'business-savings'
export const BUSINESS_LINK_ACCOUNT = 'business-link'
export const FX_SAVINGS_ACCOUNT = 'fx-savings'

// Keywords
export const BUSINESS_CHECKING_ACCOUNT_KEYWORD = 'Business Checking Account'
export const BUSINESS_SAVINGS_ACCOUNT_KEYWORD = 'Business Savings Account'
export const BUSINESS_LINK_ACCOUNT_KEYWORD = 'Business Link Account'
export const FX_SAVINGS_ACCOUNT_KEYWORD = 'FX Savings Account'

export const checkAccountByKeyword = (keyword) => {
    switch (keyword) {
        case BUSINESS_CHECKING_ACCOUNT_KEYWORD:
            return BUSINESS_CHECKING_ACCOUNT
        case BUSINESS_SAVINGS_ACCOUNT_KEYWORD:
            return BUSINESS_SAVINGS_ACCOUNT
        case BUSINESS_LINK_ACCOUNT_KEYWORD:
            return BUSINESS_LINK_ACCOUNT
        case FX_SAVINGS_ACCOUNT_KEYWORD:
            return FX_SAVINGS_ACCOUNT
        default:
            return 'N/A'
    }
}

export default function typeOfAccount(type) {
    switch (type) {
        case BUSINESS_CHECKING_ACCOUNT:
            return BUSINESS_CHECKING_ACCOUNT_KEYWORD
        case BUSINESS_SAVINGS_ACCOUNT:
            return BUSINESS_SAVINGS_ACCOUNT_KEYWORD
        case BUSINESS_LINK_ACCOUNT:
            return BUSINESS_LINK_ACCOUNT_KEYWORD
        case FX_SAVINGS_ACCOUNT:
            return FX_SAVINGS_ACCOUNT_KEYWORD
        default:
            return 'N/A'
    }
}
