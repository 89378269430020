// @ts-check

import notUndefined from '../../utils/notUndefined'

/** @typedef {import('../../redux/userAccessRights/reducer').CmsUserAccessRightsState} State */

/**
 * @param {string} productId
 * @param {Pick<State, 'products'| 'selectedProducts'>} state
 */
export function isCorpAdaPayor(productId, state) {
  const product = state.products.byId[productId]
  if (!product) {
    return false
  }
  if (product.tags.corpAdaPayor) {
    return true
  }

  const activeSelectedProducts = state.selectedProducts.filter(
    (p) => p.isActive
  )
  if (
    productId === 'all' &&
    activeSelectedProducts.length === 1 &&
    state.products.byId[activeSelectedProducts[0].id]?.tags.corpAdaPayor
  ) {
    return true
  }

  return false
}

/**
 * @typedef {object} AccountNumberErrors
 * @property {boolean} hasErrors
 * @property {boolean} noUsers
 * @property {boolean} noApprovers
 * @property {boolean} noMakers
 *
 * @param {Pick<State, 'productAccountUserRoleJunction' | 'roles' | 'selectedProducts' | 'products' | 'accountNumberEnrollmentOption' >} state
 * @param {string} productId
 * @param {string} accountNumber
 * @returns {AccountNumberErrors}
 */
export function validateAccountNumber(state, productId, accountNumber) {
  /** @type {AccountNumberErrors} */
  const errors = {
    noUsers: false,
    noApprovers: false,
    noMakers: false,
    hasErrors: true,
  }

  // Skip validation for account number if under Corporate ADA (Payor).
  // Users cannot be enrolled under account numbers under Corp Ada Payor and so
  // there is nothing to validate.
  if (isCorpAdaPayor(productId, state)) {
    return {
      ...errors,
      hasErrors: false,
    }
  }

  const { byId, allIds } = state.productAccountUserRoleJunction
  const accountUsers = allIds
    .map((id) => byId[id])
    .filter(notUndefined)
    .filter(
      (entry) =>
        entry.productId === productId &&
        entry.accountNumber === accountNumber &&
        entry.isActive
    )

  if (accountUsers.length === 0) {
    errors.noUsers = true
    return errors
  }

  const roleNames = accountUsers
    .flatMap((entry) => entry.roles)
    .filter((role) => role.isActive)
    .map((role) => state.roles[role.id]?.description.toLowerCase())

  const approverCount = roleNames.filter((name) => name === 'approver').length
  const makerCount = roleNames.filter((name) => name === 'maker').length

  const activeSelectedProducts = state.selectedProducts.filter(
    (p) => p.isActive
  )
  const isEgovOnly =
    activeSelectedProducts.length === 1 &&
    state.products.byId[activeSelectedProducts[0].id]?.tags.egov
  const isApproverMakerOptional =
    (state.accountNumberEnrollmentOption === 'same' && isEgovOnly) ||
    (state.accountNumberEnrollmentOption === 'different' &&
      state.products.byId[productId]?.tags.egov)
  if (isApproverMakerOptional) {
    if (approverCount === 0 && makerCount > 0) {
      return { ...errors, noApprovers: true }
    }
    if (makerCount === 0 && approverCount > 0) {
      return { ...errors, noMakers: true }
    }
    return { ...errors, hasErrors: false }
  }

  if (approverCount === 0 && makerCount === 0) {
    return { ...errors, noApprovers: true, noMakers: true }
  }
  if (approverCount === 0) {
    return { ...errors, noApprovers: true }
  }
  if (makerCount === 0) {
    return { ...errors, noMakers: true }
  }

  return {
    ...errors,
    hasErrors: false,
  }
}

/**
 * @param {Pick<import('../../redux/userAccessRights/reducer').Product, 'selectedAccountNumbers'>} product
 */
export function hasSelectedAccountNumbers(product) {
  return product.selectedAccountNumbers.length !== 0
}

/**
 * @typedef {object} ProductErrors
 * @property {boolean} hasErrors
 * @property {boolean} noSelectedAccountNumbers
 * @property {{ [accountNumber: string]: ReturnType<typeof validateAccountNumber> }} accountNumberErrors
 *
 * @param {State} state
 * @param {string} productId
 * @returns {ProductErrors}
 */
function validateProduct(state, productId) {
  /** @type {ProductErrors} */
  const errors = {
    noSelectedAccountNumbers: false,
    accountNumberErrors: {},
    hasErrors: true,
  }

  const product = state.products.byId[productId]
  if (!product) {
    return { ...errors, hasErrors: false }
  }

  if (!hasSelectedAccountNumbers(product)) {
    errors.noSelectedAccountNumbers = true
    return errors
  }

  if (product.userEnrollmentOption === 'same') {
    const accountNumberErrors = validateAccountNumber(state, productId, 'all')
    if (accountNumberErrors.hasErrors) {
      errors.accountNumberErrors.all = accountNumberErrors
      return errors
    }
  } else {
    let hasAccountNumberErrors = false
    product.selectedAccountNumbers.forEach((accountNumber) => {
      const accountNumberErrors = validateAccountNumber(
        state,
        productId,
        accountNumber
      )
      if (accountNumberErrors.hasErrors) {
        errors.accountNumberErrors[accountNumber] = accountNumberErrors
        hasAccountNumberErrors = true
      }
    })
    if (hasAccountNumberErrors) {
      return errors
    }
  }

  return {
    ...errors,
    hasErrors: false,
  }
}

/** @param {Pick<State, 'selectedProducts'>} state */
export function hasSelectedProducts(state) {
  return state.selectedProducts.filter((p) => p.isActive).length !== 0
}

/**
 * @typedef {object} Errors
 * @property {boolean} hasErrors
 * @property {boolean} noSelectedProducts
 * @property {{ [productId: string ]: ReturnType<typeof validateProduct>}} productErrors
 *
 * @param {State} state
 * @returns {Errors}
 */
export function validate(state) {
  /** @type {Errors} */
  const errors = {
    noSelectedProducts: false,
    productErrors: {},
    hasErrors: true,
  }

  const activeSelectedProducts = state.selectedProducts.filter(
    (p) => p.isActive
  )

  if (!hasSelectedProducts(state)) {
    errors.noSelectedProducts = true
    return errors
  }

  if (state.accountNumberEnrollmentOption === 'same') {
    const productErrors = validateProduct(state, 'all')
    if (productErrors.hasErrors) {
      errors.productErrors.all = productErrors
      return errors
    }
  } else {
    let hasProductErrors = false
    activeSelectedProducts.forEach(({ id }) => {
      const productErrors = validateProduct(state, id)
      if (productErrors.hasErrors) {
        errors.productErrors[id] = productErrors
        hasProductErrors = true
      }
    })
    if (hasProductErrors) {
      return errors
    }
  }

  return {
    ...errors,
    hasErrors: false,
  }
}
