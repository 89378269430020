export const BUSINESS_INFO_1_FETCH_EXISTING_DATA = 'BUSINESS_INFO_1_FETCH_EXISTING_DATA'
export const BUSINESS_INFO_2_FETCH_EXISTING_DATA = 'BUSINESS_INFO_2_FETCH_EXISTING_DATA'

export const BUSINESS_INFO_3_FETCH_EXISTING_DATA = 'BUSINESS_INFO_3_FETCH_EXISTING_DATA'

export const BUSINESS_INFORMATION_1_ADD_BUSINESS_INFO_ONE =
    'BUSINESS_INFORMATION_1_ADD_BUSINESS_INFO_ONE'
export const BUSINESS_INFORMATION_2_ADD_BUSINESS_INFO_TWO =
    'BUSINESS_INFORMATION_2_ADD_BUSINESS_INFO_TWO'
export const BUSINESS_INFORMATION_3_ADD_BUSINESS_INFO_THREE =
    'BUSINESS_INFORMATION_3_ADD_BUSINESS_INFO_THREE'
