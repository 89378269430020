import { useHistory, useLocation } from 'react-router-dom'
import useClientAccount from '../../05-hooks/useClientAccount'
import { REVIEW_AND_SUBMIT } from '../../07-data/paths'
import Button from '../Button'

function StepBackButton({ toPreviousStep }) {
    const history = useHistory()
    const location = useLocation()
    const { type } = useClientAccount()

    const destination = location.state?.isFromReviewAndSubmit
        ? {
              pathname: `/${type}${REVIEW_AND_SUBMIT}`,
              state: location.state,
          }
        : toPreviousStep

    return (
        <Button
            type="button"
            onClick={() => {
                history.push(destination)
            }}
            isSecondary
        >
            {location.state?.isFromReviewAndSubmit ? 'Cancel' : 'Back'}
        </Button>
    )
}

export default StepBackButton
