import React from 'react'
import Button from '../Button'
import ReactModal from 'react-modal'
import './index.scss'

export default function CustomAlert({
    primaryAction,
    primaryActionText,
    primaryActionDisabled = false,
    secondaryAction,
    secondaryActionText,
    openAlert,
    onRequestClose,
    children,
}) {
    const handleRequestClose = onRequestClose || secondaryAction

    return (
        <ReactModal
            isOpen={openAlert}
            contentLabel='Modal'
            onRequestClose={handleRequestClose}
            shouldCloseOnOverlayClick={false}
            className={{
                base: 'modal-base',
                afterOpen: 'modal-base_after-open',
                beforeClose: 'modal-base_before-close',
            }}
            overlayClassName={{
                base: 'overlay-base',
                afterOpen: 'overlay-base_after-open',
                beforeClose: 'overlay-base_before-close',
            }}
            closeTimeoutMS={1000}
        >
            {/* <!-- Modal content --> */}
            <div className='modal-content'>
                <div className='modal-header'>
                    <p className='close' onClick={handleRequestClose}>
                        &times;
                    </p>
                </div>
                <div className='modal-body'>{children}</div>
                <div className='modal-footer'>
                    <Button onClick={primaryAction} disabled={primaryActionDisabled}>{primaryActionText}</Button>
                    {secondaryActionText && <Button isSecondary onClick={secondaryAction}>{secondaryActionText}</Button>}
                </div>
            </div>
        </ReactModal>
    )
}
