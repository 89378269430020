import { lazy as reactLazy } from 'react'

/**
 * @param {() => Promise} importFn A function that returns a call to a dynamic
 * import
 * @returns The lazy-loaded component
 */
export default function lazy(importFn) {
    async function helper(retriesLeft = 5) {
        try {
            return await importFn()
        } catch (error) {
            if (!retriesLeft) {
                console.error(error)
                throw new Error('Could not load component')
            }

            return new Promise((resolve) => {
                setTimeout(() => {
                    resolve(helper(retriesLeft - 1))
                }, 1000)
            })
        }
    }

    return reactLazy(helper)
}
