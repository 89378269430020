import { useMemo } from 'react'
import './index.scss'

function UnlabeledDropdown({
    value,
    error,
    options,
    loading,
    extraClassName = '',
    showPrompt = true,
    compact = false,
    ...props
}) {
    let menuClassName = 'unlabeled-dropdown'
    if (value) {
        menuClassName += ' unlabeled-dropdown_has-value'
    }
    if (error) {
        menuClassName += ' unlabeled-dropdown_error'
    }
    if (props.disabled) {
        menuClassName += ' unlabeled-dropdown_disabled'
    }

    if (compact) {
        menuClassName += ' unlabeled-dropdown_compact'
    }

    if (extraClassName) {
        menuClassName += ` ${extraClassName}`
    }

    const renderedOptions = useMemo(
        () =>
            options?.map((option, index) => (
                <option key={index} value={option.id}>
                    {option.description}
                </option>
            )),
        [options]
    )

    return (
        <div className={menuClassName}>
            <select
                {...props}
                className="unlabeled-dropdown__select"
                value={value}
            >
                {loading ? (
                    <option value="">Loading...</option>
                ) : (
                    <>
                        {showPrompt && (
                            <option value="">---Choose one---</option>
                        )}
                        {renderedOptions}
                    </>
                )}
            </select>
            <span className="unlabeled-dropdown__icon" aria-hidden="true" />
        </div>
    )
}

export default UnlabeledDropdown
