import Typography from '../Typography'

function SignatureSpecimenLink() {
    return (
        <Typography type="p">
            To download the Signature Specimen Card template,{' '}
            <a
                href="https://www.robinsonsbank.com.ph/wp-content/uploads/2021/03/Signature-Specimen-Card.pdf"
                target="_blank"
                rel="noreferrer"
            >
                click here
            </a>
            .
        </Typography>
    )
}

export default SignatureSpecimenLink
