// @ts-check

import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { useRouteMatch } from 'react-router-dom'
import { ACCESS_EXISTING_APP } from '../07-data/paths'
import { sessionLogout } from '../09-store/actions/authActions'
import {
    CMS_ACCESS_EXISTING_APPLICATION,
    CMS_ROOT,
} from '../cms/constants/pathnames'

export default function useLogout() {
    const dispatch = useDispatch()
    const cmsRouteMatch = useRouteMatch(CMS_ROOT)

    const logout = useCallback(() => {
        dispatch(
            sessionLogout({
                redirectTo: cmsRouteMatch?.url
                    ? CMS_ACCESS_EXISTING_APPLICATION
                    : ACCESS_EXISTING_APP,
            })
        )
    }, [cmsRouteMatch?.url, dispatch])

    return logout
}
