// @ts-check

export const ACCESS_EXISTING_APP = '/access-existing-application'
export const ACCOUNT = '/account'
export const ADD_INDIVIDUAL_ROLES = '/add-individual-roles'
export const BUSINESS_DOCUMENTS = '/business-documents'
export const BUSINESS_INFORMATION_1 = '/business-information-1'
export const BUSINESS_INFORMATION_2 = '/business-information-2'
export const BUSINESS_INFORMATION_3 = '/business-information-3'
export const BUSINESS_INFORMATION = '/business-information'
export const CONFIRMATION_PAGE = '/confirmation-page'
export const DOCUMENTARY_REQUIREMENTS = '/documentary-requirements'
export const HELP_PAGE = '/help'
export const HOME = '/'
export const ONLINE_BANKING_ENROLLMENT = '/online-banking-enrollment'
export const OWNERS_INFORMATION_1 = '/owners-information-1'
export const REGISTRATION_ERROR_PAGE = '/registration-error'
export const REVIEW_AND_SUBMIT = '/review-and-submit'
export const SELECT_PRODUCT_PAGE = '/select-product'
export const USER_VERIFICATION = '/user-verification'
export const VALIDATE_OTP_PAGE = '/validate-otp'
export const YOUR_APPLICATION_PAGE = '/your-application'
export const YOUR_INFORMATION = '/your-information'