import {
    BUSINESS_INFO_1_FETCH_EXISTING_DATA,
    BUSINESS_INFO_2_FETCH_EXISTING_DATA,
    BUSINESS_INFO_3_FETCH_EXISTING_DATA,
    BUSINESS_INFORMATION_1_ADD_BUSINESS_INFO_ONE,
    BUSINESS_INFORMATION_2_ADD_BUSINESS_INFO_TWO,
    BUSINESS_INFORMATION_3_ADD_BUSINESS_INFO_THREE,
} from '../types/businessInfoTypes'
const initialState = {
    data: [] || {},
}

function businessInfoReducers(state = initialState, { type, payload }) {
    switch (type) {
        case BUSINESS_INFO_1_FETCH_EXISTING_DATA:
            return {
                ...state,
                data: payload.data,
            }
        case BUSINESS_INFO_2_FETCH_EXISTING_DATA:
            return {
                ...state,
                data: payload.data,
            }
        case BUSINESS_INFO_3_FETCH_EXISTING_DATA:
            return {
                ...state,
                data: payload.data,
            }
        case BUSINESS_INFORMATION_1_ADD_BUSINESS_INFO_ONE:
            return {
                ...state,
                data: payload,
            }
        case BUSINESS_INFORMATION_2_ADD_BUSINESS_INFO_TWO:
            return {
                ...state,
                data: payload,
            }
        case BUSINESS_INFORMATION_3_ADD_BUSINESS_INFO_THREE:
            return {
                ...state,
                data: payload,
            }
        default:
            return state
    }
}

export default businessInfoReducers
