import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import Button from '../Button'

/**
 * @typedef {Object} Props
 * @property {?boolean} disabled
 * Set to `true` if this button should be disabled. Note that this button will
 * always be disabled when the page is making a request regardless of this
 * setting.
 * 
 * This prop satisfies the edge case for the Business Documents page.
 * 
 * @property {?boolean} isUpdateButton
 * Set to `true` if this button's label should be "Update" instead of
 * "Continue".  If not specified, this button will display "Update" if coming
 * from Review & Submit page. Otherwise this button will display "Continue".
 * 
 * This prop satisfies the edge case for the Add Owner/Signatory page.
 * 
 * @param {Props} props
 * @returns 
 */
function StepProceedButton({ disabled, isUpdateButton = null, ...props }) {
    const location = useLocation()
    const isLoading = useSelector((state) => state.request.isLoading)

    const shouldShowUpdateLabel =
        isUpdateButton === null
            ? location.state?.isFromReviewAndSubmit
            : isUpdateButton

    return (
        <Button {...props} type="submit" disabled={isLoading || disabled}>
            {shouldShowUpdateLabel ? 'Update' : 'Continue'}
        </Button>
    )
}

export default StepProceedButton
