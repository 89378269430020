import React, { useMemo } from 'react'
import { useTable } from 'react-table'
import { FiEdit2 } from 'react-icons/fi'
import { AiOutlineDelete } from 'react-icons/ai'
import { GrView } from 'react-icons/gr'
import './index.scss'

// prettier-ignore
function ReactTable({columns,data,hiddenColumns,handleDelete,handleEdit,handleView,hideHeader}) {
    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
        useTable({
            columns: useMemo(() => {
                return [
                    ...columns,
                    {
                        Header: 'Actions',
                        accessor: 'actions',
                        Cell: (data) => (
                            <div className='actions'>
                                { handleView && (
                                    <GrView title='View'
                                        onClick={() =>
                                            handleView(data.row.values)
                                        }
                                    />
                                )}

                                { handleEdit && (
                                    <FiEdit2 title='Edit'
                                        onClick={() =>
                                            handleEdit(data.row.values)
                                        }
                                    />
                                )}

                                {handleDelete && (
                                    <AiOutlineDelete title='Delete'
                                        onClick={() =>
                                            handleDelete(data.row.values)}
                                    />
                                )}
                            </div>
                        ),
                    },
                ]
            }, [columns,handleDelete,handleEdit,handleView]),
            data: useMemo(() => data, [data]),
            initialState: {
                hiddenColumns,
            },
        })

    return (
        <div className='table-responsive'>
            <table className='custom-table' {...getTableProps()}>
                <thead className={`${hideHeader && 'hide'}`}>
                    {headerGroups.map((headerGroup) => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column) => (
                                <th {...column.getHeaderProps()}>
                                    {column.render('Header')}
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                {data.length === 0 && (
                    <tbody>
                        <tr>
                            <td colSpan='100'></td>
                        </tr>
                    </tbody>
                )}
                <tbody {...getTableBodyProps()}>
                    {rows.map((row) => {
                        prepareRow(row)
                        return (
                            <tr {...row.getRowProps()}>
                                {row.cells.map((cell) => (
                                    <td {...cell.getCellProps()}>
                                        {cell.render('Cell')}
                                    </td>
                                ))}
                            </tr>
                        )
                    })}
                </tbody>
            </table>
        </div>
    )
}

export default ReactTable
