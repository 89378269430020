// @ts-check

import React from 'react'
import { useRouteMatch } from 'react-router-dom'
import banner1024 from '../../01-assets/images/banner-1024x150_t.png'
import banner1440 from '../../01-assets/images/banner-1440x220_t.png'
import banner425 from '../../01-assets/images/banner-425x100_t.png'
import banner768 from '../../01-assets/images/banner-768x135_t.png'
import {
    ADD_INDIVIDUAL_ROLES,
    BUSINESS_DOCUMENTS,
    BUSINESS_INFORMATION,
    BUSINESS_INFORMATION_1,
    BUSINESS_INFORMATION_2,
    BUSINESS_INFORMATION_3,
    CONFIRMATION_PAGE,
    HELP_PAGE,
    ONLINE_BANKING_ENROLLMENT,
    OWNERS_INFORMATION_1,
    REVIEW_AND_SUBMIT,
    SELECT_PRODUCT_PAGE,
    USER_VERIFICATION,
    YOUR_APPLICATION_PAGE,
    YOUR_INFORMATION,
} from '../../07-data/paths'
import { typeParam } from '../../07-data/routes'
import { CMS_ROOT } from '../../cms/constants/pathnames'
import BaseBanner from '../BaseBanner'

const hiddenBannerPathnames = [
    `/${typeParam}${YOUR_INFORMATION}`,
    `/${typeParam}${USER_VERIFICATION}`,
    `/${typeParam}${BUSINESS_INFORMATION}`,
    `/${typeParam}${BUSINESS_INFORMATION_1}`,
    `/${typeParam}${BUSINESS_INFORMATION_2}`,
    `/${typeParam}${BUSINESS_INFORMATION_3}`,
    `/${typeParam}${OWNERS_INFORMATION_1}`,
    `/${typeParam}${ADD_INDIVIDUAL_ROLES}`,
    `/${typeParam}${BUSINESS_DOCUMENTS}`,
    `/${typeParam}${ONLINE_BANKING_ENROLLMENT}`,
    `/${typeParam}${REVIEW_AND_SUBMIT}`,
    `/${typeParam}${CONFIRMATION_PAGE}`,
    YOUR_APPLICATION_PAGE,
    HELP_PAGE,
    SELECT_PRODUCT_PAGE,
    CMS_ROOT,
]

export default function DepositBanner() {
    const hiddenBannerRouteMatch = useRouteMatch(hiddenBannerPathnames)
    if (hiddenBannerRouteMatch) {
        return null
    }

    return (
        <BaseBanner
            banner1440={banner1440}
            banner1024={banner1024}
            banner768={banner768}
            banner425={banner425}
        />
    )
}
