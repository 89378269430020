import { REQUEST_CALLBACK } from '../types/registrationErrorTypes'
const initialState = {
    data: [],
}

function registrationErrorReducers(state = initialState, { type, payload }) {
    switch (type) {
        case REQUEST_CALLBACK:
            return {
                ...state,
                data: payload.data,
            }

        default:
            return state
    }
}

export default registrationErrorReducers
