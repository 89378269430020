// @ts-check

import { CMS_YOUR_APPLICATION_INIT } from './types'

/** @typedef {'Incomplete' | 'Disapproved' | 'Default' | undefined} ApplicationStatus */

const initialState = {
  /** @type {ApplicationStatus} */
  applicationStatus: undefined,
  appRef: '',
  url: '',
  firstName: '',
  message: '',

  evalSubmittedApplication: {
    applicationStatus: '',
  },

  reviewCMSSubmittedApp: {
    applicationStatus: null,
    isDone_: false,
  },
  performCMSClientVerification: {
    applicationStatus: null,
    isDone_: false,
  },
  reviewCMSDocument: {
    applicationStatus: null,
    isDone_: false,
  },
  approveCMSApplication: {
    applicationStatus: null,
    isDone_: false,
  },
  enrollementCMSProduct: {
    applicationStatus: null,
    isDone_: false,
  },
}

/** @typedef {typeof initialState} CmsYourApplicationState */

/**
 * @typedef {object} InitActionPayload
 * @property {ApplicationStatus} applicationStatus
 * @property {string} appRef
 * @property {string} url
 * @property {string} firstName
 * @property {string} message
 *
 * @typedef {object} InitAction
 * @property {typeof CMS_YOUR_APPLICATION_INIT} type
 * @property {InitActionPayload} payload
 */

/**
 * @param {CmsYourApplicationState} state
 * @param {InitAction} action
 * @returns {CmsYourApplicationState}
 */
export default function cmsYourApplicationReducer(
  state = initialState,
  { type, payload }
) {
  switch (type) {
    case CMS_YOUR_APPLICATION_INIT: {
      return {
        ...state,
        ...payload,
      }
    }
    default:
      return state
  }
}
