import {
    ACCESS_APPLICATION_VERIFY_APP_REF,
    ACCESS_APPLICATION_IS_OTP_TRUE,
    ACCESS_APPLICATION_IS_OTP_EXPIRED,
    ACCESS_APPLICATION_IS_MAXIMUM_RETRIES_REACHED,
    ACCESS_EXISTING_APP_SET_ALL_TO_DEFAULT,
    ACCESS_EXISTING_APP_RESEND_OTP,
    ACCESS_EXISTING_APP_OTP_TO_FALSE,
    ACCESS_APPLICATION_GET_CUSTOMER,
    ACCESS_APPLICATION_CLEAR_USER_DATA,
} from '../types/accessExistingAppTypes'

const initialState = {
    data: {},
    resendOTPData: {},
    userData: {},
    isOtpCorrect: false,
    isOtpExpired: false,
    isMaximumRetriesReached: false,
    validateAppRefDone: false,
    isBankInitiated: false,
    withAccountType: false,
}
//prettier-ignore
export default function accessExistingAppReducers(state = initialState, { type, payload}) {
    switch(type) {
        case ACCESS_APPLICATION_VERIFY_APP_REF:
            return { 
                ...state,
                data: payload.data,
                validateAppRefDone: true
            }
        case ACCESS_APPLICATION_IS_OTP_TRUE: 
            return {
                ...state,
                isOtpCorrect: true,
                isBankInitiated: payload.isBankInitiated,
                withAccountType: payload.withAccountType


            }
        case ACCESS_APPLICATION_IS_OTP_EXPIRED:
            return {
                ...state,
                isOtpExpired: true,
            }   
        case ACCESS_EXISTING_APP_OTP_TO_FALSE: 
            return {
                ...state,
                isOtpCorrect: false,
                isBankInitiated: false,
                withAccountType: false
            }   
        case ACCESS_APPLICATION_IS_MAXIMUM_RETRIES_REACHED:
            return {
                ...state,
                isMaximumRetriesReached: true
            }
        case ACCESS_EXISTING_APP_SET_ALL_TO_DEFAULT:
            return {
                ...state,
                data: {},
                isOtpCorrect: false,
                isOtpExpired: false,
                isMaximumRetriesReached: false,
                validateAppRefDone: false
            }

        case ACCESS_EXISTING_APP_RESEND_OTP: 
            return {
                ...state,
                isOtpExpired: false,
                isMaximumRetriesReached: false,
                resendOTPData: payload.data
            }
        case ACCESS_APPLICATION_CLEAR_USER_DATA:
                return {
                    ...state,
                    userData: {},
                    data: {}
                }
        case ACCESS_APPLICATION_GET_CUSTOMER:
            return {
                ...state,
                userData: payload
            }

        default:
            return state
    }
}
