/**
 * @param {string} amount
 */
export function formatAmount(amount) {
  const numberFormat = new Intl.NumberFormat('en-PH', { style: 'decimal' })

  if (/\.$/.test(amount)) {
    return `${numberFormat.format(amount)}.`
  } else if (/\.0$/.test(amount)) {
    return `${numberFormat.format(amount)}.0`
  } else if (/\.00$/.test(amount)) {
    return `${numberFormat.format(amount)}.00`
  } else if (/\.\d0$/.test(amount)) {
    return `${numberFormat.format(amount)}0`
  } else {
    return numberFormat.format(amount)
  }
}
