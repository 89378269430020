import Typography from '../Typography'

function CardItem({ label, value }) {
    return (
        <div className="card__item">
            <Typography type="h4">{label}</Typography>
            <Typography type="h3primary">{value}</Typography>
        </div>
    )
}

export default CardItem
