import { Suspense, useEffect } from 'react'
import { Switch, useLocation } from 'react-router-dom'
import { ErrorBoundary, Loader } from '..'
import { routes } from '../../07-data/routes'

function Routes() {
    const location = useLocation()

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [location.pathname])

    return (
        <ErrorBoundary>
            <Suspense fallback={<Loader />}>
                <Switch>
                    {routes.map(
                        ({ RouteComponent, Component, name, path, exact }) => (
                            <RouteComponent
                                key={name}
                                // For submodules with their own routes
                                // (e.g., CMS), `exact` has to be `false` or
                                // their subroutes will not render.
                                exact={exact ?? true}
                                path={path}
                            >
                                <Component />
                            </RouteComponent>
                        )
                    )}
                </Switch>
            </Suspense>
        </ErrorBoundary>
    )
}

export default Routes
