import dataToJwt from './dataToJwt'

const allowedExtensions = /(\.jpeg|\.png|\.jpg|\.pdf)$/i

function splitCamelCase(str) {
    return str.replace(/([a-z0-9])([A-Z])/g, '$1 $2').toLowerCase()
}

/**
 * @desc convert the 1st 7 characters of number to asterisk
 * @param {int} number : the number that need to be covered
 * @returns string of numbers with the asterisk
 */
export const hashContactNumber = (number) => {
    let tel = number
    tel = '' + tel
    let ary = tel.split('')
    ary.splice(0, 7, '*******')
    return ary.join('')
}

// Deprecated function.
// This is now implemented in lazy.js
//
// /**
//  * @desc retry to lazy load 5 times
//  * @param {Function} fn - the imported component
//  * @param {number} retriesLeft - How many times the component will retry downloading chunks
//  * @param {number} interval - Millis between retries. If exponential set to true will be doubled each retry
//  * @param {boolean} exponential - Flag for exponential back-off mode
//  * @returns new Promise retrying the lazy load
//  */
// export async function retry(fn, retriesLeft = 5, interval = 1000, exponential = false) {
//     try {
//         return await fn()
//     } catch (error) {
//         if (retriesLeft) {
//             await new Promise((r) => setTimeout(r, interval))
//             return retry(fn, retriesLeft - 1, exponential ? interval * 2 : interval, exponential)
//         } else throw new Error('Max retries reached')
//     }
// }

class Helper {
    setClassName(type) {
        switch (type) {
            case 'hyperlink':
                return 'hyperlink'
            case 'btn-link':
                return 'btn-link'
            case 'normal':
                return 'normal'
            default:
                return 'btn-link ' + type
        }
    }

    isEmpty(value) {
        return value.trim().length === 0
    }

    validateUploadFile([value, id, desc], errors, size) {
        let error = {}

        if (value.length === 0) error[id] = `${desc} is required.`
        else {
            if (!allowedExtensions.exec(value.name)) {
                error[id] = 'Invalid file type.'
            }
            if (Math.round(value.size / 1024) > 1024 * size) {
                error[id] = `File size exceeded to ${size}mb.`
            }
        }

        if (error[id] === undefined) return { ...errors }
        else return { ...errors, [id]: error[id] }
    }

    checkIfBlink(value, isBLINK = false) {
        let type

        if (value === 'Sole Proprietor') {
            //* Checks if the appRef is a business link product
            type = isBLINK ? ['soleProp', 'coa'] : ['soleProp', null]
        } else if (value === 'Partnership') {
            //* Checks if the appRef is a business link product
            type = isBLINK ? ['partnership', 'coa'] : ['partnership', null]
        } else if (value === 'Corporation') {
            //* Checks if the appRef is a business link product
            type = isBLINK ? ['corporation', 'coa'] : ['corporation', null]
        } else {
            type = ['N/A', null]
        }

        return type
    }

    getBusinessEntityTypeCode(value) {
        switch (value) {
            case 'Sole Proprietor':
                return 'soleProp'
            case 'Partnership':
                return 'partnership'
            case 'Corporation':
                return 'corporation'
            default:
                return 'N/A'
        }
    }

    toBase64 = (file) =>
        new Promise((resolve, reject) => {
            const reader = new FileReader()
            reader.readAsDataURL(file)
            //.replace(/^data:image\/[a-z]+;base64,/, '')
            reader.onload = () => resolve(reader.result)

            reader.onerror = (error) => reject(error)
        })

    dataURLtoFile(dataurl, filename) {
        var arr = dataurl.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]),
            n = bstr.length,
            u8arr = new Uint8Array(n)

        while (n--) {
            u8arr[n] = bstr.charCodeAt(n)
        }

        return new File([u8arr], filename, { type: mime })
    }

    // CONVERT DATA TO JWT
    convertToJWT(data) {
        return {
            param: dataToJwt(data),
        }
    }

    errorOutput(errors) {
        let newError = {}
        for (let key of Object.keys(errors)) {
            newError = {
                ...newError,
                [key]: errors[key][0].replace(key, splitCamelCase(key)),
            }
        }
        return newError
    }

    /* OTPs are now just one single string instead of an object with six
       values */
    // trimOtp(values) {
    //     let arr = []
    //     for (let i of Object.values(values)) {
    //         arr.push(i)
    //     }
    //     return arr.toString().replaceAll(',', '')
    // }

    /* Process now done directly by fetchPdf function. */
    // converApiResponseToPdf(data) {
    //     const file = new Blob([data], { type: 'application/pdf' })
    //     return URL.createObjectURL(file)
    // }
}

export default Helper
