import { Routes, TheLayout } from './02-components'
import { ToastContainer } from 'react-toastify'
import { Provider } from 'react-redux'
import store from './09-store'
import { BrowserRouter as Router } from 'react-router-dom'

/*
 * Dedupe hack. Importing code in eager chunks removes them from the lazy-loaded
 * chunks. Imported code tend to duplicate in several lazy chunks unless
 * configured correctly in webpack, but I don't want to accidentally mess up the 
 * webpack config by ejecting react-scripts.
 */
// eslint-disable-next-line no-unused-vars
import useForm from './05-hooks/useForm'
// eslint-disable-next-line no-unused-vars
import useResponseData from './05-hooks/useResponseData'
// eslint-disable-next-line no-unused-vars
import createPdfFetchAction from './08-util/createPdfFetchAction'

function App() {
    return (
        <Provider store={store}>
            <Router>
                <TheLayout>
                    <Routes />
                </TheLayout>
            </Router>
            <ToastContainer
                position='bottom-center'
                autoClose={3000}
                pauseOnFocusLoss={false}
            />
        </Provider>
    )
}

export default App
