// @ts-check

import { isCorpAdaPayor } from '../../pages/UserAccessRights/helpers'
import notUndefined from '../../utils/notUndefined'
import { setEntries } from './reducer'
import {
  CMS_USER_ACCESS_RIGHTS_INIT,
  CMS_USER_ACCESS_RIGHTS_SELECT_PRODUCT,
} from './types'

/**
 * Reducer for handling Corporate ADA (Payor) product. This is basically a
 * post-processor to maintain that Corp Ada Payor is always at the end of the
 * list of selected products.
 *
 * @param {import("./reducer").CmsUserAccessRightsState} state
 * @param {*} action
 * @returns {import("./reducer").CmsUserAccessRightsState}
 */
export default function corpAdaPayorReducer(state, action) {
  switch (action.type) {
    case CMS_USER_ACCESS_RIGHTS_INIT:
    case CMS_USER_ACCESS_RIGHTS_SELECT_PRODUCT: {
      const intermediateState = {
        ...state,
        selectedProducts: moveCorpAdaPayorToEndOfSelctedProducts(state),
      }
      return maintainFirstCurrentProduct(
        removeCorpAdaPayorUserEntries(intermediateState)
      )
    }
    default:
      return state
  }
}

/**
 * @param {import('./reducer').CmsUserAccessRightsState} state
 * @returns {import('./reducer').CmsUserAccessRightsState['selectedProducts']}
 */
function moveCorpAdaPayorToEndOfSelctedProducts(state) {
  const { products, selectedProducts } = state
  const selectedCorpAdaPayor = selectedProducts.find(
    (p) => products.byId[p.id]?.tags.corpAdaPayor
  )

  if (!selectedCorpAdaPayor) {
    return selectedProducts
  }

  return selectedProducts
    .filter((p) => p.id !== selectedCorpAdaPayor.id)
    .concat(selectedCorpAdaPayor)
}

/**
 * Maintain that the current product is the first selected product if
 * `accountNumberEnrollmentOption` is `"same"`
 *
 * @param {import('./reducer').CmsUserAccessRightsState} state
 * @returns {import('./reducer').CmsUserAccessRightsState}
 */
function maintainFirstCurrentProduct(state) {
  if (state.accountNumberEnrollmentOption === 'same') {
    const activeSelectedProducts = state.selectedProducts.filter(
      (p) => p.isActive
    )
    return {
      ...state,
      currentProductId: activeSelectedProducts[0]?.id ?? '',
    }
  }

  return state
}

/**
 * @param {import('./reducer').CmsUserAccessRightsState} state
 * @returns {import('./reducer').CmsUserAccessRightsState}
 */
function removeCorpAdaPayorUserEntries(state) {
  if (state.accountNumberEnrollmentOption === 'same') {
    return state
  }

  const updatedEntries = state.productAccountUserRoleJunction.allIds
    .map((id) => state.productAccountUserRoleJunction.byId[id])
    .filter(notUndefined)
    .filter((entry) => !isCorpAdaPayor(entry.productId, state))

  return {
    ...state,
    productAccountUserRoleJunction: setEntries(updatedEntries),
  }
}
