import React from 'react'
import { Typography } from '../../02-components/'
import { AiFillCloseCircle } from 'react-icons/ai'
import './index.scss'

function Modal({ show, handleClose, title, children }) {
    return (
        <div onClick={handleClose} className={`modal ${show ? 'show' : ''}`}>
            <div
                onClick={(e) => e.stopPropagation()}
                className={`modal__content ${show ? 'show' : ''}`}
            >
                <div className='modal__header'>
                    <AiFillCloseCircle onClick={handleClose} className='modal__close' />
                </div>
                <div className='modal__title'>
                    <Typography type='h2'>{title}</Typography>
                </div>
                <div className='modal__body'>{children}</div>
            </div>
        </div>
    )
}

export default Modal
