import jwt from 'jsonwebtoken'

/**
 * @func: dataToJwt
 *
 * @param {Object} data
 *
 * @desc returns a data that is converted to JWT
 */
export default function dataToJwt(data) {
    return jwt.sign(data, process.env.REACT_APP_PRIVATE_KEY, {
        algorithm: 'RS256',
    })
}
