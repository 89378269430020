import {
    BUSINESS_DOCUMENT_UPDATE,
    BUSINESS_DOCUMENT_FETCH_TYPE,
    BUSINESS_DOCUMENTS_FETCH_EXISTING_DATA,
} from '../types/businessDocTypes'
const initialState = {
    data: [],
    existingData: {},
}

function businessDocReducers(state = initialState, { type, payload }) {
    switch (type) {
        case BUSINESS_DOCUMENT_FETCH_TYPE:
            return {
                ...state,
                data: payload.data,
            }
        case BUSINESS_DOCUMENTS_FETCH_EXISTING_DATA:
            return {
                ...state,
                existingData: payload.existingData,
            }
        case BUSINESS_DOCUMENT_UPDATE:
            return {
                ...state,
            }
        default:
            return state
    }
}

export default businessDocReducers
