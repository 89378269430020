// @ts-check

import { combineReducers } from 'redux'
import corporateAdaBillerReducer from './corporateAdaBiller/reducer'
import corporateAdaPayorReducer from './corporateAdaPayor/reducer'
import egovReducer from './egov/reducer'
import productsReducer from './products/reducer'
import supplyChainFinancingReducer from './supplyChainFinancing/reducer'
import usersReducer from './users/reducer'

const cmsAdditionalInformationReducer = combineReducers({
  products: productsReducer,
  users: usersReducer,
  corporateAdaBiller: corporateAdaBillerReducer,
  corporateAdaPayor: corporateAdaPayorReducer,
  egov: egovReducer,
  supplyChainFinancing: supplyChainFinancingReducer,
})

export default cmsAdditionalInformationReducer
