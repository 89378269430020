// @ts-check

import { aXios } from '../07-data/aXios'
import { FAILURE, SUCCESSFULLY_FETCH } from '../07-data/messages'
import {
    requestDefault,
    requestFailure,
    requestStart,
    requestSuccess,
} from '../09-store/actions/requestActions'
import Helper from './helper'

const { convertToJWT } = new Helper()

/**
 * @param {string} url
 * @param {object} [data]
 */
async function fetchPdf(url, data) {
    let response
    if (typeof data === 'object') {
        response = await aXios.post(url, data, {
            responseType: 'blob',
        })
    } else {
        response = await aXios.get(url, { responseType: 'blob' })
    }
    const file = new Blob([response.data], { type: 'application/pdf' })
    const urlFile = URL.createObjectURL(file)
    window.open(urlFile)
}

/**
 * Creates an async thunk that retrieves a PDF file from the given URL and
 * displays it in a new tab. It can accept an optional `extraPayload` parameter.
 * If omitted, the thunk will make a GET request to `url`. Otherwise, a POST
 * request will be made. For POST requests, an `appRef` field will automatically
 * be added to the request payload. If the POST request has no parameters other
 * than an appRef, this function must be called with an empty object as the
 * second argument.
 *
 * Note that the browser may block the new tab, and manual intervention must be
 * done on the user's part.
 *
 * @example
 * ```
 * const fetchPdfViaGET = () => createPdfFetchAction('/api/get-endpoint')
 * const fetchPdfViaPOST = () => createPdfFetchAction('/api/post-endpoint', {})
 * const fetchPdfViaPOSTWithArgs =
 *   () => createPdfFetchAction('/api/another-post-endpoint', { id: '42' })
 * ```
 *
 * @param {string} url The URL to fetch the PDF from
 * @param {object} [extraPayload] Payload for POST requests. Must be an object.
 * If the endpoint is a GET request, do not pass anything.
 */
export default function createPdfFetchAction(url, extraPayload) {
    return async (dispatch, getState) => {
        dispatch(requestStart())
        try {
            if (extraPayload) {
                const payload = convertToJWT({
                    appRef: getState().globalAppRef.appRef,
                    ...extraPayload,
                })
                await fetchPdf(url, payload)
            } else {
                await fetchPdf(url)
            }
            dispatch(requestSuccess({ message: SUCCESSFULLY_FETCH }))
        } catch (error) {
            console.error(error)
            dispatch(requestFailure({ message: FAILURE }))
            throw error
        } finally {
            dispatch(requestDefault())
        }
    }
}
