import { applyMiddleware, combineReducers, createStore } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import thunk from 'redux-thunk'
import cmsRootReducer from '../cms/redux/rootReducer'
import accessExistingAppReducers from './reducers/accessExistingAppReducers'
import authReducers from './reducers/authReducers'
import businessDocReducers from './reducers/businessDocReducers'
import businessInfoReducers from './reducers/businessInfoReducers'
import errorReducers from './reducers/errorReducers'
import finacleBusinessInfoReducer from './reducers/finacleBusinessInfoReducer'
import globalAppRefReducers from './reducers/globalAppRefReducers'
import onlineBankingReducers from './reducers/onlineBankingReducers'
import ownersInfoReducers from './reducers/ownersInfoReducers'
import registrationErrorReducers from './reducers/registrationErrorReducers'
import requestReducers from './reducers/requestReducers'
import reviewReducers from './reducers/reviewReducers'
import yourInformationReducers from './reducers/yourInformationReducers'
import { SESSION_LOGOUT } from './types/authTypes'

const allReducers = combineReducers({
    request: requestReducers,
    onlineBanking: onlineBankingReducers,
    ownersInformation: ownersInfoReducers,
    businessDocument: businessDocReducers,
    review: reviewReducers,
    businessInfo: businessInfoReducers,
    finacleBusinessInfo: finacleBusinessInfoReducer,
    globalAppRef: globalAppRefReducers,
    yourInformation: yourInformationReducers,
    accessExistingApp: accessExistingAppReducers,
    errorsState: errorReducers,
    registrationError: registrationErrorReducers,
    auth: authReducers,
    cms: cmsRootReducer,
})

// Reset the store on logout.
// See https://www.digitalocean.com/community/tutorials/redux-reset-state-redux
const rootReducer = (state, action) => {
    if (action.type === SESSION_LOGOUT) {
        state = undefined
    }
    return allReducers(state, action)
}

const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(thunk)))

export default store

/** @typedef {ReturnType<store['getState']>} AppRootState */
