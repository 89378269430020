import { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { Typography } from '..'

class ErrorBoundary extends Component {
    constructor(props) {
        super(props)
        this.state = { hasError: false }
    }

    static getDerivedStateFromError(error) {
        return { hasError: true }
    }

    componentDidCatch(error, errorInfo) {
        console.error('Error:', error.message)
        console.error('Component stack:', errorInfo.componentStack)
    }

    componentDidUpdate(prevProps) {
        if (prevProps.location.pathname !== this.props.location.pathname) {
            this.setState({ hasError: false })
        }
    }

    render() {
        if (this.state.hasError) {
            return (
                <div>
                    <Typography type="h1">
                        This page could not be loaded.
                    </Typography>
                    <Typography type="p">
                        Reload the page and try again.
                    </Typography>
                </div>
            )
        }
        return this.props.children
    }
}

export default withRouter(ErrorBoundary)
