import { REVIEW_FETCH_LIST, REVIEW_PAGE_UPDATE_APPLICATION_STATUS } from '../types/reviewTypes'

const initialState = {
    data: [],
}

function reviewReducers(state = initialState, { type, payload }) {
    switch (type) {
        case REVIEW_FETCH_LIST:
            return {
                ...state,
                data: payload.data,
            }
        case REVIEW_PAGE_UPDATE_APPLICATION_STATUS:
            return {
                ...state,
            }

        default:
            return state
    }
}

export default reviewReducers
