import { AiOutlineCheck, AiOutlineExclamation } from 'react-icons/ai'
import { Link } from '../index'
import './index.scss'

export default function StatusSteps({
    title,
    status,
    isDone,
    clientActionLinkTo,
}) {
    const isStatusOngoing =
        status === 'Ongoing Application' || status === 'Ongoing'

    const returnStatus = () => {
        if (isDone === null) return ''
        if (isDone) return 'statusStep_status_done'
        if (isStatusOngoing) return 'statusStep_status_ongoing'
        return 'statusStep_status_disabled'
    }

    const icon = isDone ? (
        <AiOutlineCheck className="statusStep__icon" aria-hidden="true" />
    ) : (
        <AiOutlineExclamation className="statusStep__icon" aria-hidden="true" />
    )

    const clientActionRequired =
        status === 'Returned to Client' || status === 'Ongoing Application'

    return (
        <div className={`statusStep ${returnStatus()}`}>
            {icon}

            <div className="statusStep__info">
                <h5 className="statusStep__title">{title}</h5>
                {status && (
                    <p className="statusStep__status-text">Status: {status}</p>
                )}
                {clientActionRequired && (
                    <p className="statusStep__actions">
                        Client Action Required:{' '}
                        <Link type="hyperlink" to={clientActionLinkTo}>
                            Click here
                        </Link>
                    </p>
                )}
            </div>
        </div>
    )
}
