import {
    ONLINE_BANKING_DELETE_USER,
    ONLINE_BANKING_FETCH_USER,
    ONLINE_BANKING_UPDATE_USER,
} from '../types/onlineBankingTypes'
const initialState = {
    data: [],
}

function onlineBankingReducers(state = initialState, { type, payload }) {
    switch (type) {
        case ONLINE_BANKING_FETCH_USER:
            return {
                ...state,
                data: payload.data,
            }
        case ONLINE_BANKING_UPDATE_USER:
            return {
                ...state,
                data: payload.data,
            }
        case ONLINE_BANKING_DELETE_USER:
            return {
                ...state,
                data: payload.data,
            }

        default:
            return state
    }
}

export default onlineBankingReducers
