// @ts-check

import { CMS_ADDITIONAL_INFORMATION_USERS_INIT } from '../types'

/**
 * @typedef {object} AdditionalInformationUser
 * @property {string} id
 * @property {string} name
 */

const initialState = {
  /** @type {{ [id: string]: AdditionalInformationUser }} */
  byId: {},
  /** @type {string[]} */
  allIds: [],
}

/**
 * @typedef {object} UsersInitActionPayload
 * @property {AdditionalInformationUser[]} users
 *
 * @typedef {object} UsersInitAction
 * @property {typeof CMS_ADDITIONAL_INFORMATION_USERS_INIT} type
 * @property {UsersInitActionPayload} payload
 */

/**
 * @param {typeof initialState} state
 * @param {UsersInitAction} action
 * @returns {typeof initialState}
 */
export default function usersReducer(state = initialState, { type, payload }) {
  switch (type) {
    case CMS_ADDITIONAL_INFORMATION_USERS_INIT: {
      const { users } = payload

      /** @type {typeof state['byId']} */
      const byId = {}
      const allIds = []
      for (const user of users) {
        byId[user.id] = {
          id: user.id,
          name: user.name,
        }
        allIds.push(user.id)
      }

      return { byId, allIds }
    }
    default: {
      return state
    }
  }
}
