import { VscCheck, VscChromeClose } from 'react-icons/vsc'
import Skeleton from 'react-loading-skeleton'

function ProductTableCell({ value, loading }) {
    if (loading) {
        return <Skeleton />
    }

    if (value === 'true') {
        return <VscCheck className="product-table__icon" aria-label="Yes" />
    } else if (value === 'false') {
        return (
            <VscChromeClose className="product-table__icon" aria-label="No" />
        )
    } else {
        return value
    }
}

export default ProductTableCell
