import usePolicies from '../../05-hooks/usePolicies'
import PolicyModal from '../PolicyModal'

function PolicyModals({
    privacyConsent,
    termsAndConditions,
    termsOfUse,
}) {
    const policies = usePolicies()

    return (
        <>
            <PolicyModal
                show={termsAndConditions.show}
                title="Terms and Conditions"
                handleClose={termsAndConditions.handleClose}
                loading={policies.loading}
                content={policies.termsAndConditions}
            />

            <PolicyModal
                show={privacyConsent.show}
                handleClose={privacyConsent.handleClose}
                loading={policies.loading}
                content={policies.privacyPolicy}
            />

            <PolicyModal
                show={termsOfUse.show}
                title="Terms of Use"
                handleClose={termsOfUse.handleClose}
                loading={policies.loading}
                content={policies.termsOfUse}
            />
        </>
    )
}

export default PolicyModals
