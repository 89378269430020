import DOMPurify from 'dompurify'
import { Fragment } from 'react'
import ClipLoader from 'react-spinners/ClipLoader'
import { Typography } from '../../../02-components'
import './index.scss'

export default function FAQTab({ data, loading }) {
    return (
        <>
            <Typography type='h1'>Frequently Asked Questions</Typography>

            <div className='faqs'>
                {loading ? (
                    <div className='clip-loader'>
                        <ClipLoader
                            size={150}
                            color='#6ab63e'
                            loading={loading}
                            speedMultiplier={0.5}
                        />
                        <br />
                        Loading ...
                    </div>
                ) : (
                    <ul>
                        {data?.map((items) => (
                            <Fragment key={items.id}>
                                <li className='faqs-questions'>{items.question}</li>
                                <p
                                    dangerouslySetInnerHTML={{
                                        __html: DOMPurify.sanitize(items.answer),
                                    }}
                                    className='faqs-answers'
                                />

                                {items.answerList.length > 0 && (
                                    <ul className='faqs-otherList'>
                                        {items.answerList.map((list, id) => (
                                            <li key={id}>{list.description}</li>
                                        ))}
                                    </ul>
                                )}
                            </Fragment>
                        ))}
                    </ul>
                )}
            </div>
        </>
    )
}
