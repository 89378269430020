// @ts-check

import notUndefined from '../../utils/notUndefined'
import corpAdaPayorReducer from './corpAdaPayorReducer'
import { selectedProductsCanUseSameAccounts } from './helpers'
import {
  CMS_USER_ACCESS_RIGHTS_ADD_USER,
  CMS_USER_ACCESS_RIGHTS_DESELECT_ACCOUNT_NUMBER,
  CMS_USER_ACCESS_RIGHTS_DESELECT_PRODUCT,
  CMS_USER_ACCESS_RIGHTS_INIT,
  CMS_USER_ACCESS_RIGHTS_REMOVE_USER,
  CMS_USER_ACCESS_RIGHTS_SELECT_ACCOUNT_NUMBER,
  CMS_USER_ACCESS_RIGHTS_SELECT_PRODUCT,
  CMS_USER_ACCESS_RIGHTS_SET_ACCOUNT_NUMBER_ENROLLMENT_OPTION_TO_DIFFERENT,
  CMS_USER_ACCESS_RIGHTS_SET_ACCOUNT_NUMBER_ENROLLMENT_OPTION_TO_SAME,
  CMS_USER_ACCESS_RIGHTS_SET_CURRENT_ACCOUNT_NUMBER,
  CMS_USER_ACCESS_RIGHTS_SET_CURRENT_PRODUCT_ID,
  CMS_USER_ACCESS_RIGHTS_SET_PRODUCT_USER_ENROLLMENT_OPTION_TO_DIFFERENT,
  CMS_USER_ACCESS_RIGHTS_SET_PRODUCT_USER_ENROLLMENT_OPTION_TO_SAME,
} from './types'

/**
 * @typedef {object} Product
 * @property {string} id
 * @property {string} value
 * @property {string} description
 * @property {string[]} selectedAccountNumbers
 * @property {EnrollmentOption} userEnrollmentOption
 * @property {string} productClass
 * @property {ProductTags} tags
 * @property {Documents} documents
 *
 * @typedef {object} ProductTags
 * @property {boolean} basicAccountServices
 * @property {boolean} egov
 * @property {boolean} checkwriter
 * @property {boolean} corpAdaPayor
 * @property {'corpAdaBiller' | 'corpAdaPayor' | 'egov' | 'scf' | null} additionalInfo
 *
 * @typedef {object} Documents
 * Documents that a product may have. Note that all products also have a Terms
 * and Conditions, but adding a `termsAndConditions` property would be
 * needlessly redundant.
 * @property {boolean} [e2BankingEnrollmentForm]
 * @property {boolean} [corpAdaBillerEnrollmentForm] This is a special form for
 * Terms and Conditions, specifically for the Corporate Ada (Biller) product.
 * Setting this to `true` means that "Terms and Conditions" labels in the UI
 * _may_ be replaced by "Enrollment Form".
 * @property {boolean} [egovEnrollmentForm]
 * @property {boolean} [addendum]
 * @property {boolean} [scfReceivablesDiscountingAgreement]
 * @property {boolean} [cmsMasterAgreement] Show a link to view CMS Master
 * Agreement in document lists if this is `true` for at least one selected
 * product.
 *
 * @typedef {object} AccountNumber
 * @property {string} id
 * @property {string} value
 * @property {boolean} isCheckingAccount
 *
 * @typedef {object} User
 * @property {string} id
 * @property {string} firstName
 * @property {string} middleName
 * @property {string} lastName
 * @property {string} email
 * @property {string} contactNumber
 * @property {boolean} self
 * @property {string} status
 *
 * @typedef {object} Role
 * @property {string} id
 * @property {string} description
 * @property {string} permissions
 *
 * @typedef {object} ProductAccountUserRoleJunction
 * @property {string} id
 * @property {string} productId
 * @property {string} accountNumber
 * @property {string} userId
 * @property {JunctionRole[]} roles
 * @property {string} userRoleId
 * @property {boolean} isActive
 *
 * @typedef {object} JunctionRole
 * @property {string} id
 * @property {boolean} isActive
 *
 * @typedef {'same' | 'different'} EnrollmentOption
 */

/**
 * @template T
 * @typedef {object} Entries<T>
 * @property {{ [id: string]: T | undefined }} byId
 * @property {string[]} allIds
 */

const initialState = {
  products: {
    /** @type {{ all: Product; [id: string]: Product | undefined }} */
    byId: {
      // 1: { id: '1', value: 'Basic Account Services', selectedAccountNumbers: [], userEnrollmentOption: 'same', productClass: '', tags: { basicAccountServices: false, egov: false } },
      /**
       * A virtual product used when `accountNumberEnrollmentOption` is set to
       * `"same"`. Because this is a virtual product, it's id should not be
       * included in the `allIds` array.
       *
       * @type {Product}
       */
      all: {
        id: 'all',
        value: 'All',
        description: '',
        selectedAccountNumbers: [],
        userEnrollmentOption: 'same',
        productClass: '',
        tags: {
          basicAccountServices: false,
          egov: false,
          checkwriter: false,
          corpAdaPayor: false,
          additionalInfo: null,
        },
        documents: {},
      },
    },
    /** @type {string[]} */
    allIds: [],
  },
  /** @type {Entries<AccountNumber>} */
  accountNumbers: {
    byId: {
      // 1234567890123456: { id: '1234567890123456', value: '1234567890123456' },
    },
    allIds: [],
  },
  /** @type {{ [id: string]: Role | undefined }} */
  roles: {
    // 1: { id: '1', description: 'Balance Inquiry', permissions: 'Account Summary' },
  },
  // prettier-ignore
  /** @type {{ [id: string]: User | undefined }} */
  users: {
    // 1: { id: '1', firstName: 'John', middleName: 'Smith', lastName: 'Doe', email: 'itdxinstitutionalfe2@robinsonsbank.com.ph', contactNumber: '+639194844452', self: true, status: '1234' },
  },
  registeredUserInfo: {
    firstName: '',
    middleName: '',
    lastName: '',
    email: '',
    contactNumber: '',
  },
  /** @type {Entries<ProductAccountUserRoleJunction>} */
  productAccountUserRoleJunction: {
    byId: {},
    allIds: [],
  },
  /** @type {Array<{ id: string, isActive?: boolean }>} */
  selectedProducts: [],
  /** @type {EnrollmentOption} */
  accountNumberEnrollmentOption: 'same',
  currentProductId: '',
  currentAccountNumbers: {
    /** @type {{ all: string; [productId: string]: string }} */
    byId: {
      all: '',
    },
    /** @type {string[]} */
    allIds: [],
  },
}

/** @typedef {typeof initialState} CmsUserAccessRightsState */

/**
 * @typedef {object} InitActionPayload
 * @property {Product[]} products
 * @property {AccountNumber[]} accountNumbers
 * @property {Role[]} roles
 * @property {User[]} users
 * @property {typeof initialState.registeredUserInfo} registeredUserInfo
 * @property {ProductAccountUserRoleJunction[]} junction
 * @property {typeof initialState.selectedProducts} selectedProducts
 * @property {EnrollmentOption} accountNumberEnrollmentOption
 *
 * @typedef {object} InitAction
 * @property {typeof CMS_USER_ACCESS_RIGHTS_INIT} type
 * @property {InitActionPayload} payload
 *
 * @typedef {object} SelectProductAction
 * @property {typeof CMS_USER_ACCESS_RIGHTS_SELECT_PRODUCT} type
 * @property {{ productId: string }} payload
 *
 * @typedef {object} DeselectProductAction
 * @property {typeof CMS_USER_ACCESS_RIGHTS_DESELECT_PRODUCT} type
 * @property {{ productId: string }} payload
 *
 * @typedef {object} SelectAccountNumberAction
 * @property {typeof CMS_USER_ACCESS_RIGHTS_SELECT_ACCOUNT_NUMBER} type
 * @property {{ productId: string; accountNumber: string }} payload
 *
 * @typedef {object} DeselectAccountNumberAction
 * @property {typeof CMS_USER_ACCESS_RIGHTS_DESELECT_ACCOUNT_NUMBER} type
 * @property {{ productId: string; accountNumber: string }} payload
 *
 * @typedef {object} SetAccountNumberEnrollmentOptionToSameAction
 * @property {typeof CMS_USER_ACCESS_RIGHTS_SET_ACCOUNT_NUMBER_ENROLLMENT_OPTION_TO_SAME} type
 * @property {{ sourceProductId: string }} payload
 *
 * @typedef {object} SetAccountNumberEnrollmentOptionToDifferentAction
 * @property {typeof CMS_USER_ACCESS_RIGHTS_SET_ACCOUNT_NUMBER_ENROLLMENT_OPTION_TO_DIFFERENT} type
 * @property {undefined} payload
 *
 * @typedef {object} SetProductUserEnrollmentOptionToSameAction
 * @property {typeof CMS_USER_ACCESS_RIGHTS_SET_PRODUCT_USER_ENROLLMENT_OPTION_TO_SAME} type
 * @property {{ productId: string; sourceAccountNumber: string }} payload
 *
 * @typedef {object} SetProductUserEnrollmentOptionToDifferentAction
 * @property {typeof CMS_USER_ACCESS_RIGHTS_SET_PRODUCT_USER_ENROLLMENT_OPTION_TO_DIFFERENT} type
 * @property {{ productId: string }} payload
 *
 * @typedef {object} AddUserActionPayload
 * @property {string} productId
 * @property {string} accountNumber
 * @property {User} user
 * @property {JunctionRole[]} roles
 *
 * @typedef {object} AddUserAction
 * @property {typeof CMS_USER_ACCESS_RIGHTS_ADD_USER} type
 * @property {AddUserActionPayload} payload
 *
 * @typedef {object} RemoveUserActionPayload
 * @property {string} productId
 * @property {string} accountNumber
 * @property {string} userId
 *
 * @typedef {object} RemoveUserAction
 * @property {typeof CMS_USER_ACCESS_RIGHTS_REMOVE_USER} type
 * @property {RemoveUserActionPayload} payload
 *
 * @typedef {object} SetCurrentProductIdAction
 * @property {typeof CMS_USER_ACCESS_RIGHTS_SET_CURRENT_PRODUCT_ID} type
 * @property {{ productId: string }} payload
 *
 * @typedef {object} SetCurrentAccountNumberAction
 * @property {typeof CMS_USER_ACCESS_RIGHTS_SET_CURRENT_ACCOUNT_NUMBER} type
 * @property {{ productId: string; accountNumber: string }} payload
 */

/**
 * @param {typeof initialState} state
 * @param {| InitAction
 * | SelectProductAction
 * | DeselectProductAction
 * | SelectAccountNumberAction
 * | DeselectAccountNumberAction
 * | SetAccountNumberEnrollmentOptionToSameAction
 * | SetAccountNumberEnrollmentOptionToDifferentAction
 * | SetProductUserEnrollmentOptionToSameAction
 * | SetProductUserEnrollmentOptionToDifferentAction
 * | AddUserAction
 * | RemoveUserAction
 * | SetCurrentProductIdAction
 * | SetCurrentAccountNumberAction} action
 * @returns {typeof initialState}
 */
function reducer(state = initialState, { type, payload }) {
  switch (type) {
    case CMS_USER_ACCESS_RIGHTS_INIT: {
      const {
        products,
        accountNumbers,
        roles,
        users,
        registeredUserInfo,
        junction,
        selectedProducts,
        accountNumberEnrollmentOption,
      } = payload

      const initializeVirtualProduct = () => {
        const virtualProduct = initialState.products.byId.all
        if (
          selectedProducts.length === 0 ||
          accountNumberEnrollmentOption === 'different'
        ) {
          return virtualProduct
        }

        const firstSelectedProductId = selectedProducts[0].id
        const firstSelectedProduct = productEntries.byId[firstSelectedProductId]
        if (!firstSelectedProduct) {
          return virtualProduct
        }

        return {
          ...virtualProduct,
          selectedAccountNumbers: firstSelectedProduct.selectedAccountNumbers,
          userEnrollmentOption: firstSelectedProduct.userEnrollmentOption,
        }
      }

      const productEntries = setEntries(products)
      const newProducts = {
        ...productEntries,
        byId: {
          ...productEntries.byId,
          all: initializeVirtualProduct(),
        },
      }

      return {
        ...state,
        products: newProducts,
        accountNumbers: setEntries(accountNumbers),
        roles: setEntries(roles).byId,
        users: setEntries(users).byId,
        registeredUserInfo,
        productAccountUserRoleJunction: setEntries(junction),
        selectedProducts,
        accountNumberEnrollmentOption,
        currentProductId: selectedProducts[0]?.id ?? '',
        currentAccountNumbers: {
          byId: {
            ...newProducts.allIds.reduce(
              (acc, id) => ({
                ...acc,
                [id]: newProducts.byId[id]?.selectedAccountNumbers[0] ?? '',
              }),
              {}
            ),
            all: newProducts.byId.all.selectedAccountNumbers[0] ?? '',
          },
          allIds: newProducts.allIds,
        },
      }
    }
    case CMS_USER_ACCESS_RIGHTS_SELECT_PRODUCT: {
      const { productId } = payload

      const productToAdd = state.products.byId[productId]
      if (!productToAdd) {
        return state
      }

      const existingSelections = state.selectedProducts.reduce(
        (acc, p) => acc.set(p.id, p.isActive),
        /** @type {Map<string, boolean | undefined>} */ (new Map())
      )

      const newSelectedProducts = state.products.allIds
        .filter((id) => id === productToAdd.id || existingSelections.has(id))
        .map((id) => state.products.byId[id])
        .filter(notUndefined)
        .map(({ id, tags }) => ({
          id,
          isActive:
            id === productToAdd.id ||
            ((productToAdd.tags.basicAccountServices ||
              !tags.basicAccountServices) &&
              existingSelections.get(id)),
        }))

      const newActiveSelectedProducts = newSelectedProducts.filter(
        (p) => p.isActive
      )

      let newCurrentProductId = state.currentProductId
      if (
        !state.currentProductId ||
        state.products.byId[state.currentProductId]?.tags.basicAccountServices
      ) {
        // We're updating currentProductId to the newly selected product when
        // the current product when Basic Account Services is the current
        // product, since VoA gets deselected when other products are selected.
        // Setting it to the new product id works because VoA effectively gets
        // replaced by the new product.
        newCurrentProductId = productId
      } else if (state.accountNumberEnrollmentOption === 'same') {
        newCurrentProductId = newActiveSelectedProducts[0].id
      }

      const intermediateState = {
        ...state,
        selectedProducts: newSelectedProducts,
        currentProductId: newCurrentProductId,
      }

      if (!selectedProductsCanUseSameAccounts(intermediateState)) {
        return removeNonCheckingAccountsUnderCheckwriter(
          updateStateToUseRealProducts(intermediateState)
        )
      } else {
        return intermediateState
      }
    }
    case CMS_USER_ACCESS_RIGHTS_DESELECT_PRODUCT: {
      const { productId } = payload

      const newCurrentProductId = (() => {
        if (productId !== state.currentProductId) {
          return state.currentProductId
        }
        const activeSelectedProducts = state.selectedProducts.filter(
          (p) => p.isActive
        )
        if (activeSelectedProducts.length === 1) {
          return ''
        }
        const currentProductIndex = activeSelectedProducts.findIndex(
          (p) => p.id === state.currentProductId
        )
        const nextIndex =
          currentProductIndex === activeSelectedProducts.length - 1
            ? currentProductIndex - 1
            : currentProductIndex + 1
        return activeSelectedProducts[nextIndex].id
      })()

      return {
        ...state,
        selectedProducts: state.selectedProducts.map((p) =>
          p.id === productId ? { ...p, isActive: false } : p
        ),
        currentProductId: newCurrentProductId,
      }
    }
    case CMS_USER_ACCESS_RIGHTS_SELECT_ACCOUNT_NUMBER: {
      const { productId, accountNumber } = payload

      const productToUpdate = state.products.byId[productId]
      if (!productToUpdate) {
        return state
      }

      /**
       * @param {Product} product
       * @returns {Product}
       */
      const addAccountNumber = (product) => ({
        ...product,
        selectedAccountNumbers: state.accountNumbers.allIds.filter(
          (id) =>
            id === accountNumber || product.selectedAccountNumbers.includes(id)
        ),
      })

      const updatedProduct = addAccountNumber(productToUpdate)

      let newCurrentAccountNumber = state.currentAccountNumbers.byId[productId]
      if (!newCurrentAccountNumber) {
        newCurrentAccountNumber = accountNumber
      } else if (productToUpdate.userEnrollmentOption === 'same') {
        newCurrentAccountNumber = updatedProduct.selectedAccountNumbers[0]
      }

      return {
        ...state,
        products: {
          ...state.products,
          byId: {
            ...state.products.byId,
            [productToUpdate.id]: updatedProduct,
          },
        },
        currentAccountNumbers: {
          ...state.currentAccountNumbers,
          byId: {
            ...state.currentAccountNumbers.byId,
            [productToUpdate.id]: newCurrentAccountNumber,
          },
        },
      }
    }
    case CMS_USER_ACCESS_RIGHTS_DESELECT_ACCOUNT_NUMBER: {
      const { productId, accountNumber } = payload

      const productToUpdate = state.products.byId[productId]
      if (!productToUpdate) {
        return state
      }

      /**
       * @param {Product} product
       * @returns {Product}
       */
      const removeAccountNumber = (product) => ({
        ...product,
        selectedAccountNumbers: product.selectedAccountNumbers.filter(
          (n) => n !== accountNumber
        ),
      })

      const newCurrentAccountNumber = (() => {
        const currentAccountNumber =
          state.currentAccountNumbers.byId[productToUpdate.id]

        if (accountNumber !== currentAccountNumber) {
          return currentAccountNumber
        }

        const { selectedAccountNumbers } = productToUpdate

        if (selectedAccountNumbers.length === 1) {
          return ''
        }

        const currentAccountNumberIndex = selectedAccountNumbers.findIndex(
          (n) => n === currentAccountNumber
        )
        const nextIndex =
          currentAccountNumberIndex === selectedAccountNumbers.length - 1
            ? currentAccountNumberIndex - 1
            : currentAccountNumberIndex + 1
        return selectedAccountNumbers[nextIndex]
      })()

      return {
        ...state,
        products: {
          ...state.products,
          byId: {
            ...state.products.byId,
            [productToUpdate.id]: removeAccountNumber(productToUpdate),
          },
        },
        currentAccountNumbers: {
          ...state.currentAccountNumbers,
          byId: {
            ...state.currentAccountNumbers.byId,
            [productToUpdate.id]: newCurrentAccountNumber,
          },
        },
      }
    }
    case CMS_USER_ACCESS_RIGHTS_SET_ACCOUNT_NUMBER_ENROLLMENT_OPTION_TO_SAME: {
      return updateStateToUseVirtualProduct(state, payload.sourceProductId)
    }
    case CMS_USER_ACCESS_RIGHTS_SET_ACCOUNT_NUMBER_ENROLLMENT_OPTION_TO_DIFFERENT: {
      return updateStateToUseRealProducts(state)
    }
    case CMS_USER_ACCESS_RIGHTS_SET_PRODUCT_USER_ENROLLMENT_OPTION_TO_SAME: {
      const { products } = state
      const { productId, sourceAccountNumber } = payload

      const productToUpdate = products.byId[productId]
      if (!productToUpdate) {
        return state
      }

      return updateStateToUseVirtualAccount(
        state,
        productToUpdate,
        sourceAccountNumber
      )
    }
    case CMS_USER_ACCESS_RIGHTS_SET_PRODUCT_USER_ENROLLMENT_OPTION_TO_DIFFERENT: {
      const { products } = state
      const { productId } = payload

      const productToUpdate = products.byId[productId]
      if (!productToUpdate) {
        return state
      }

      return updateStateToUseRealAccounts(state, productToUpdate)
    }
    case CMS_USER_ACCESS_RIGHTS_ADD_USER: {
      const { productId, accountNumber, user, roles } = payload

      const junctionId = createProductAccountUserJunctionId(
        productId,
        accountNumber,
        user.id
      )

      const entry = state.productAccountUserRoleJunction.byId[junctionId]

      let newJunctionIds = state.productAccountUserRoleJunction.allIds
      if (!entry) {
        newJunctionIds = [
          ...state.productAccountUserRoleJunction.allIds,
          junctionId,
        ]
      }

      return {
        ...state,
        users: { ...state.users, [user.id]: user },
        productAccountUserRoleJunction: {
          byId: {
            ...state.productAccountUserRoleJunction.byId,
            [junctionId]: {
              id: junctionId,
              productId,
              accountNumber,
              userId: user.id,
              isActive: true,
              roles,
              userRoleId: entry?.userRoleId ?? '',
            },
          },
          allIds: newJunctionIds,
        },
      }
    }
    case CMS_USER_ACCESS_RIGHTS_REMOVE_USER: {
      const { productId, accountNumber, userId } = payload
      const { byId, allIds } = state.productAccountUserRoleJunction

      const entryToUpdate = allIds
        .map((id) => byId[id])
        .find(
          (entry) =>
            entry?.productId === productId &&
            entry.accountNumber === accountNumber &&
            entry.userId === userId
        )
      if (!entryToUpdate) {
        return state
      }

      return {
        ...state,
        productAccountUserRoleJunction: {
          byId: {
            ...byId,
            [entryToUpdate.id]: {
              ...entryToUpdate,
              isActive: false,
            },
          },
          allIds,
        },
      }
    }
    case CMS_USER_ACCESS_RIGHTS_SET_CURRENT_PRODUCT_ID: {
      const { productId } = payload
      return {
        ...state,
        currentProductId: productId,
      }
    }
    case CMS_USER_ACCESS_RIGHTS_SET_CURRENT_ACCOUNT_NUMBER: {
      const { productId, accountNumber } = payload
      return {
        ...state,
        currentAccountNumbers: {
          ...state.currentAccountNumbers,
          byId: {
            ...state.currentAccountNumbers.byId,
            [productId]: accountNumber,
          },
        },
      }
    }
    default: {
      return state
    }
  }
}

/**
 * @param {string} productId
 * @param {string} accountNumber
 * @param {string} userId
 */
function createProductAccountUserJunctionId(productId, accountNumber, userId) {
  return `${productId}|${accountNumber}|${userId}`
}

/**
 * @param {CmsUserAccessRightsState} state
 * @param {string} sourceProductId
 * @returns {CmsUserAccessRightsState}
 */
function updateStateToUseVirtualProduct(state, sourceProductId) {
  const { products, selectedProducts } = state

  const currentProduct = products.byId[sourceProductId]
  if (!currentProduct) {
    return state
  }

  const allJunctionEntries = getAllJunctionEntries(state)
  const currentProductEntries = allJunctionEntries.filter(
    (entry) => entry.productId === sourceProductId
  )
  const virtualEntriesFromCurrentProductEntries = currentProductEntries.map(
    (entry) => ({
      ...entry,
      id: createProductAccountUserJunctionId(
        'all',
        entry.accountNumber,
        entry.userId
      ),
      productId: 'all',
      roles: Object.values(state.roles)
        .filter(notUndefined)
        .map((role) => ({
          id: role.id,
          isActive:
            entry.roles.find((r) => r.id === role.id)?.isActive ?? false,
        })),
    })
  )
  const virtualEntryIdsFromCurrentProductEntries = new Set(
    virtualEntriesFromCurrentProductEntries.map((entry) => entry.id)
  )

  const otherProductEntries = allJunctionEntries.filter(
    (entry) => entry.productId !== sourceProductId
  )
  const virtualEntriesFromOtherProductEntries = otherProductEntries
    .map((entry) => ({
      ...entry,
      id: createProductAccountUserJunctionId(
        'all',
        entry.accountNumber,
        entry.userId
      ),
      productId: 'all',
      isActive: false,
    }))
    .filter((entry) => !virtualEntryIdsFromCurrentProductEntries.has(entry.id))

  const newEntries = [
    ...virtualEntriesFromCurrentProductEntries,
    ...virtualEntriesFromOtherProductEntries,
  ]

  return {
    ...state,
    products: {
      ...products,
      byId: {
        ...products.byId,
        all: {
          ...products.byId.all,
          selectedAccountNumbers: currentProduct.selectedAccountNumbers,
          userEnrollmentOption: currentProduct.userEnrollmentOption,
        },
      },
    },
    productAccountUserRoleJunction: setEntries(newEntries),
    accountNumberEnrollmentOption: 'same',
    currentProductId: selectedProducts.filter((p) => p.isActive)[0]?.id ?? '',
    currentAccountNumbers: {
      ...state.currentAccountNumbers,
      byId: {
        ...state.currentAccountNumbers.byId,
        all: state.currentAccountNumbers.byId[sourceProductId],
      },
    },
  }
}

/**
 * Applies all virtual product information to every active product and sets the
 * account enrollment option to `"different"`.
 *
 * This function assumes that account enrollment option is set to `"same"` when
 * called. Otherwise it will just return the state as-is.
 *
 * @param {CmsUserAccessRightsState} state
 * @returns {CmsUserAccessRightsState}
 */
function updateStateToUseRealProducts(state) {
  if (state.accountNumberEnrollmentOption === 'different') {
    return state
  }

  const { products, selectedProducts, currentAccountNumbers } = state
  const activeSelectedProducts = selectedProducts.filter((p) => p.isActive)

  const virtualEntries = getAllJunctionEntries(state).filter(
    (entry) => entry.productId === 'all'
  )
  const newSelectedEntries = activeSelectedProducts.flatMap((p) =>
    virtualEntries.map((entry) => ({
      ...entry,
      id: createProductAccountUserJunctionId(
        p.id,
        entry.accountNumber,
        entry.userId
      ),
      productId: p.id,
    }))
  )

  const newEntries = newSelectedEntries

  return {
    ...state,
    products: {
      ...products,
      byId: {
        ...products.byId,
        ...activeSelectedProducts
          .map((p) => products.byId[p.id])
          .filter(notUndefined)
          .map((product) => ({
            ...product,
            selectedAccountNumbers: products.byId.all.selectedAccountNumbers,
            userEnrollmentOption: products.byId.all.userEnrollmentOption,
          }))
          .reduce(
            (
              /** @type {Omit<typeof products.byId, 'all'>} */ acc,
              product
            ) => ({
              ...acc,
              [product.id]: product,
            }),
            {}
          ),
      },
    },
    productAccountUserRoleJunction: setEntries(newEntries),
    accountNumberEnrollmentOption: 'different',
    currentAccountNumbers: {
      ...currentAccountNumbers,
      byId: activeSelectedProducts
        .map((p) => p.id)
        .reduce(
          (/** @type {typeof currentAccountNumbers.byId} */ acc, id) => ({
            ...acc,
            [id]: currentAccountNumbers.byId.all,
          }),
          currentAccountNumbers.byId
        ),
    },
  }
}

/**
 * @param {CmsUserAccessRightsState} state
 * @param {Product} productToUpdate
 * @param {string} sourceAccountNumber
 * @returns {CmsUserAccessRightsState}
 */
function updateStateToUseVirtualAccount(
  state,
  productToUpdate,
  sourceAccountNumber
) {
  const { products, currentAccountNumbers } = state

  const { currentAccountEntries, productEntries, otherEntries } =
    getAllJunctionEntries(state).reduce(
      (acc, entry) => {
        if (entry.productId !== productToUpdate.id) {
          return {
            ...acc,
            otherEntries: [...acc.otherEntries, entry],
          }
        }
        if (entry.accountNumber !== sourceAccountNumber) {
          return {
            ...acc,
            productEntries: [...acc.productEntries, entry],
          }
        }
        return {
          ...acc,
          currentAccountEntries: [...acc.currentAccountEntries, entry],
        }
      },
      {
        /** @type {ProductAccountUserRoleJunction[]} */
        currentAccountEntries: [],
        /** @type {ProductAccountUserRoleJunction[]} */
        productEntries: [],
        /** @type {ProductAccountUserRoleJunction[]} */
        otherEntries: [],
      }
    )

  const virtualEntriesFromCurrentAccountEntries = currentAccountEntries.map(
    (entry) => ({
      ...entry,
      id: createProductAccountUserJunctionId(
        entry.productId,
        'all',
        entry.userId
      ),
      accountNumber: 'all',
      roles: Object.values(state.roles)
        .filter(notUndefined)
        .map((role) => ({
          id: role.id,
          isActive:
            entry.roles.find((r) => r.id === role.id)?.isActive ?? false,
        })),
    })
  )
  const virtualEntryIdsFromCurrentAccountEntries = new Set(
    virtualEntriesFromCurrentAccountEntries.map((entry) => entry.id)
  )

  const virtualEntriesFromProductEntries = productEntries
    .map((entry) => ({
      ...entry,
      id: createProductAccountUserJunctionId(
        entry.productId,
        'all',
        entry.userId
      ),
      accountNumber: 'all',
      isActive: false,
    }))
    .filter((entry) => !virtualEntryIdsFromCurrentAccountEntries.has(entry.id))

  const newEntries = [
    ...virtualEntriesFromCurrentAccountEntries,
    ...virtualEntriesFromProductEntries,
    ...otherEntries,
  ]

  const newCurrentAccountNumber =
    productToUpdate.selectedAccountNumbers[0] ?? ''

  return {
    ...state,
    products: {
      ...products,
      byId: {
        ...products.byId,
        [productToUpdate.id]: {
          ...productToUpdate,
          userEnrollmentOption: 'same',
        },
      },
    },
    productAccountUserRoleJunction: setEntries(newEntries),
    currentAccountNumbers: {
      ...currentAccountNumbers,
      byId: {
        ...currentAccountNumbers.byId,
        [productToUpdate.id]: newCurrentAccountNumber,
      },
    },
  }
}

/**
 * @param {CmsUserAccessRightsState} state
 * @param {Product} productToUpdate
 * @returns {CmsUserAccessRightsState}
 */
function updateStateToUseRealAccounts(state, productToUpdate) {
  const { products } = state
  const allJunctionEntries = getAllJunctionEntries(state)
  const virtualEntries = allJunctionEntries.filter(
    (entry) =>
      entry.productId === productToUpdate.id && entry.accountNumber === 'all'
  )
  const newSelectedEntries = productToUpdate.selectedAccountNumbers.flatMap(
    (accountNumber) =>
      virtualEntries.map((entry) => ({
        ...entry,
        id: createProductAccountUserJunctionId(
          entry.productId,
          accountNumber,
          entry.userId
        ),
        accountNumber,
      }))
  )
  const newEntries = allJunctionEntries
    .filter((entry) => entry.productId !== productToUpdate.id)
    .concat(newSelectedEntries)

  return {
    ...state,
    products: {
      ...products,
      byId: {
        ...products.byId,
        [productToUpdate.id]: {
          ...productToUpdate,
          userEnrollmentOption: 'different',
        },
      },
    },
    productAccountUserRoleJunction: setEntries(newEntries),
  }
}

/**
 * @param {CmsUserAccessRightsState} state
 * @returns {CmsUserAccessRightsState}
 */
function removeNonCheckingAccountsUnderCheckwriter(state) {
  const updatedCheckwriterSelectedAccounts = state.selectedProducts
    .filter((p) => p.isActive)
    .map((p) => state.products.byId[p.id])
    .filter(notUndefined)
    .filter((product) => product.tags.checkwriter)
    .map((product) => ({
      ...product,
      selectedAccountNumbers: product.selectedAccountNumbers.filter(
        (account) => state.accountNumbers.byId[account]?.isCheckingAccount
      ),
    }))

  const updatedCheckwriterJunctionEntries = getAllJunctionEntries(state).filter(
    (entry) =>
      !state.products.byId[entry.productId]?.tags.checkwriter ||
      entry.accountNumber === 'all' ||
      state.accountNumbers.byId[entry.accountNumber]?.isCheckingAccount
  )

  return {
    ...state,
    products: {
      ...state.products,
      byId: {
        ...state.products.byId,
        ...updatedCheckwriterSelectedAccounts.reduce(
          (
            /** @type {Omit<CmsUserAccessRightsState['products']['byId'], 'all'>} */ acc,
            product
          ) => ({
            ...acc,
            [product.id]: product,
          }),
          {}
        ),
      },
    },
    productAccountUserRoleJunction: setEntries(
      updatedCheckwriterJunctionEntries
    ),
    currentAccountNumbers: {
      ...state.currentAccountNumbers,
      byId: {
        ...state.currentAccountNumbers.byId,
        ...updatedCheckwriterSelectedAccounts.reduce(
          (acc, product) => ({
            ...acc,
            [product.id]: product.selectedAccountNumbers[0] ?? '',
          }),
          {}
        ),
      },
    },
  }
}

/**
 * @template {{ id: string }} T
 * @param {T[]} entries
 * @return {Entries<T>}
 */
export function setEntries(entries) {
  return {
    byId: entries.reduce(
      (/** @type {Entries<T>['byId']} */ acc, entry) => ({
        ...acc,
        [entry.id]: entry,
      }),
      {}
    ),
    allIds: entries.map((entry) => entry.id),
  }
}

/** @param {CmsUserAccessRightsState} state */
function getAllJunctionEntries(state) {
  return state.productAccountUserRoleJunction.allIds
    .map((id) => state.productAccountUserRoleJunction.byId[id])
    .filter(notUndefined)
}

export default function cmsUserAccessRightsReducer(state, action) {
  const intermediateState = reducer(state, action)
  return corpAdaPayorReducer(intermediateState, action)
}
