// @ts-check

import { lowerCaseProductNames } from '../../constants/productNames'
import notUndefined from '../../utils/notUndefined'

/** @param {string} productName */
export const determineProductClass = (productName) => {
  const lower = productName.toLowerCase()
  switch (lower) {
    case lowerCaseProductNames.CORPORATE_ADA_BILLER:
    case lowerCaseProductNames.CORPORATE_ADA_PAYOR:
      return 'corporate ada'
    case lowerCaseProductNames.PAYROLL_PAYOUT:
    case lowerCaseProductNames.PAYROLL_PAYOUT_WITH_FREE_INSURANCE:
    case lowerCaseProductNames.PAYROLL_SUITE_LITE:
    case lowerCaseProductNames.PAYROLL_SUITE_EXPANDED:
      return 'payroll'
    default:
      return lower
  }
}

/**
 * @param {string} productName
 * @return {import('./reducer').ProductTags}
 */
export const determineProductTags = (productName) => {
  const tags = {
    basicAccountServices: false,
    egov: false,
    checkwriter: false,
    corpAdaPayor: false,
    /** @type {import('./reducer').ProductTags['additionalInfo']} */
    additionalInfo: null,
  }

  switch (productName.toLowerCase()) {
    case lowerCaseProductNames.BASIC_ACCOUNT_SERVICES:
      return { ...tags, basicAccountServices: true }
    case lowerCaseProductNames.CHECKWRITER_PLUS_CC:
    case lowerCaseProductNames.CHECKWRITER_PLUS_MC:
      return { ...tags, checkwriter: true }
    case lowerCaseProductNames.EGOV:
      return { ...tags, egov: true, additionalInfo: 'egov' }
    case lowerCaseProductNames.CORPORATE_ADA_BILLER:
      return { ...tags, additionalInfo: 'corpAdaBiller' }
    case lowerCaseProductNames.CORPORATE_ADA_PAYOR:
      return {
        ...tags,
        corpAdaPayor: true,
        additionalInfo: 'corpAdaPayor',
      }
    case lowerCaseProductNames.SUPPLY_CHAIN_FINANCING:
      return { ...tags, additionalInfo: 'scf' }
    default:
      return tags
  }
}

/**
 * @param {string} productName
 * @returns {import("./reducer").Documents}
 */
export const determineDocuments = (productName) => {
  switch (productName.toLowerCase()) {
    case lowerCaseProductNames.SUPPLY_CHAIN_FINANCING:
      return {
        scfReceivablesDiscountingAgreement: true,
      }
    case lowerCaseProductNames.CORPORATE_ADA_BILLER:
      return {
        corpAdaBillerEnrollmentForm: true,
        addendum: true,
        cmsMasterAgreement: true,
      }
    case lowerCaseProductNames.PAYROLL_PAYOUT:
    case lowerCaseProductNames.PAYROLL_PAYOUT_WITH_FREE_INSURANCE:
    case lowerCaseProductNames.PAYROLL_SUITE_EXPANDED:
    case lowerCaseProductNames.PAYROLL_SUITE_LITE:
    case lowerCaseProductNames.CHECKWRITER_PLUS_CC:
    case lowerCaseProductNames.CHECKWRITER_PLUS_MC:
    case lowerCaseProductNames.PESONET:
    case lowerCaseProductNames.SUPPLIER_SETTLEMENT:
      return {
        e2BankingEnrollmentForm: true,
        addendum: true,
        cmsMasterAgreement: true,
      }
    case lowerCaseProductNames.EGOV:
      return {
        e2BankingEnrollmentForm: true,
        egovEnrollmentForm: true,
      }
    case lowerCaseProductNames.BASIC_ACCOUNT_SERVICES:
    case lowerCaseProductNames.CORPORATE_ADA_PAYOR:
    default:
      return {}
  }
}

/**
 * @param {Pick<import('./reducer').CmsUserAccessRightsState, 'products' | 'selectedProducts' | 'accountNumbers'>} state
 */
export function selectedProductsCanUseSameAccounts(state) {
  const activeSelectedProducts = state.selectedProducts
    .filter((p) => p.isActive)
    .map((p) => state.products.byId[p.id])
    .filter(notUndefined)
  return (
    activeSelectedProducts.every((p) => p.tags.checkwriter) ||
    activeSelectedProducts.every((p) => !p.tags.checkwriter) ||
    state.accountNumbers.allIds
      .map((id) => state.accountNumbers.byId[id])
      .filter(notUndefined)
      .every((n) => n.isCheckingAccount)
  )
}
