import { CMS_BUSINESS_DOCUMENTS_FETCH } from './types'

const initialState = {
  file: null,
  shouldUpdate: false,
}

export default function cmsBusinessDocumentsReducer(
  state = initialState,
  { type, payload }
) {
  switch (type) {
    case CMS_BUSINESS_DOCUMENTS_FETCH: {
      return {
        ...state,
        file: payload.file,
        shouldUpdate: payload.shouldUpdate,
      }
    }
    default: {
      return state
    }
  }
}
