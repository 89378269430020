import React, { memo } from 'react'
import { Typography, Button } from '../../02-components'
import { useHistory } from 'react-router'
import { useSelector } from 'react-redux'
import './index.scss'

function Incomplete({ user, message, url, businessEntityType = '' }) {
    const history = useHistory()
    const globalAppRef = useSelector((state) => state.globalAppRef)
    const isExistingAccount = useSelector(
        (state) => state.accessExistingApp.userData.withAcctNum
    )

    return (
        <>
            <Typography type='h4'>Welcome back {user}!</Typography>

            <Typography type='p'>
                Complete your account opening application by clicking{' '}
                <Button
                    onClick={() => {
                        history.push({
                            pathname: `${url}`,
                            state: {
                                ...history.location.state,
                                appRef: globalAppRef?.appRef,
                                businessType: businessEntityType,
                                isExistingAccount,
                            },
                        })
                    }}
                >
                    here
                </Button>
            </Typography>

            <div className='incomplete-inquiry'>
                <Typography type='p'>{message}</Typography>
            </div>
        </>
    )
}

export default memo(Incomplete)
