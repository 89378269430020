import { Fragment } from 'react'
import ClipLoader from 'react-spinners/ClipLoader'
import { Typography } from '../../../02-components'
import './index.scss'

export default function Policy({ title, content, loading }) {
    return (
        <>
            <Typography type="h1">{title}</Typography>
            <div className="policy__content">
                {loading ? (
                    <div className="clip-loader">
                        <ClipLoader
                            size={150}
                            color="#6ab63e"
                            loading={loading}
                            speedMultiplier={0.5}
                        />
                        <br />
                        Loading ...
                    </div>
                ) : (
                    content?.map((section, index) => (
                        <Fragment key={index}>
                            <Typography key={index} type="h3">
                                {section.dscp}
                            </Typography>

                            {section.writeupBull.map((desc, index) => (
                                <Typography key={index} type="p">
                                    {desc.dscp}
                                </Typography>
                            ))}
                        </Fragment>
                    ))
                )}
            </div>
        </>
    )
}
