import {
    OWNERS_INFORMATION_FETCH_USER,
    OWNERS_INFORMATION_FETCH_USER_BY_APPREF_AND_ID,
    OWNERS_INFORMATION_DELETE_USER,
    OWNERS_INFORMATION_SET_USER_REDUCER_TO_DEFAULT,
    OWNERS_INFORMATION_FETCH_SIGNING_REQUIREMENTS,
    OWNERS_INFORMATION_FETCH_SERVICING_BRANCH_INFO,
} from '../types/ownersInfoTypes'
const initialState = {
    signRequirements: "",
    signRequirementsOtherTxt: "",
    data: [],
    dataToUpdate: [] || {},
    servicingBranchInformation: {
        error: false,
        data: undefined,
    },
}

function ownersInfoReducers(state = initialState, { type, payload }) {
    switch (type) {
        case OWNERS_INFORMATION_FETCH_SIGNING_REQUIREMENTS:
            return {
                ...state,
                signRequirements: payload.signRequirements,
                signRequirementsOtherTxt: payload.signRequirementsOtherTxt,
            }

        case OWNERS_INFORMATION_FETCH_USER:
            return {
                ...state,
                data: payload.data,
            }

        case OWNERS_INFORMATION_DELETE_USER:
            return {
                ...state,
                data: payload.data,
            }

        case OWNERS_INFORMATION_FETCH_USER_BY_APPREF_AND_ID:
            return {
                ...state,
                dataToUpdate: payload.data,
            }

        case OWNERS_INFORMATION_SET_USER_REDUCER_TO_DEFAULT:
            return {
                ...state,
                dataToUpdate: [] || {},
            }
        case OWNERS_INFORMATION_FETCH_SERVICING_BRANCH_INFO:
            return {
                ...state,
                servicingBranchInformation: {
                    error: payload.error,
                    data: payload.data,
                },
            }
        default:
            return state
    }
}

export default ownersInfoReducers
