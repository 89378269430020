import IdleTimer from 'react-idle-timer'
import { useRef, useEffect } from 'react'
import useCountDown from '../../05-hooks/useCountDown'
import { CustomAlert, Typography } from '../'
import useCustomAlert from '../../05-hooks/useCustomAlert'
import { IDLE_TIMEOUT, FINAL_TIMEOUT } from '../../07-data/global'
import { Redirect } from 'react-router'
import { ACCESS_EXISTING_APP } from '../../07-data/paths'
import useIsAuthenticated from '../../05-hooks/useIsAuthenticated'

export default function IdleTimeChecker({ onLogout }) {
    const { isAuthenticated } = useIsAuthenticated()
    const timeoutRef = useRef(null)
    const timerRef = useRef()
    const { openAlert, handleCloseAlert, handleOpenAlert } = useCustomAlert()

    const { timer, timeConvert, resetTimer, clear } = useCountDown(FINAL_TIMEOUT, () => {
        clear()
    })

    const handleLogout = () => {
        clearTimeout(timeoutRef.current)
        handleCloseAlert()
        onLogout()
    }

    const handleStayConnected = () => {
        clearTimeout(timeoutRef.current)
        timerRef.current.reset()
        handleCloseAlert()
    }

    const handleOnIdle = (event) => {
        handleOpenAlert()
        resetTimer()
        timeoutRef.current = setTimeout(handleLogout, FINAL_TIMEOUT * 1000)
    }

    useEffect(() => {
        return () => {
            clearTimeout(timeoutRef.current)
        }
    }, [])

    if (!isAuthenticated) {
        return <Redirect to={ACCESS_EXISTING_APP} />
    }

    return (
        <>
            <IdleTimer
                ref={timerRef}
                timeout={IDLE_TIMEOUT * 1000}
                onIdle={handleOnIdle}
                // onActive={handleStayConnected}
            />
            {openAlert && (
                <CustomAlert
                    secondaryAction={(_) => {
                        handleLogout()
                        handleCloseAlert()
                    }}
                    secondaryActionText='Logout'
                    openAlert={openAlert}
                    primaryAction={(_) => {
                        handleStayConnected()
                        handleCloseAlert()
                    }}
                    primaryActionText='Keep me signed in'
                >
                    <Typography type='p'>
                        You're being timed out due to inactivity. Please choose to stay signed in or
                        to logout. Otherwise, you will be logged off automatically.
                    </Typography>

                    <Typography type='p'>
                        Time remaining:{' '}
                        <span style={{ color: '#6ab63e' }}>{timeConvert(timer)}</span>
                    </Typography>
                </CustomAlert>
            )}
        </>
    )
}
