import ErrorMessage from '../ErrorMessage'
import './index.scss'

function TextArea({ label, error, rows = 10, cols = 50, ...props }) {
    return (
        <label className="textarea">
            {label}
            <textarea
                {...props}
                className={`textarea__input ${props.value ? 'has-value' : ''} ${
                    error ? 'error' : ''
                }`}
                rows={rows}
                cols={cols}
            />
            {error && <ErrorMessage message={error} />}
        </label>
    )
}

export default TextArea
