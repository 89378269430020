import {
    YOUR_INFORMATION_REGISTER_USER,
    USER_VERIFICATION_VERIFY_OTP,
    USER_VERIFICATION_RESET_IS_OTP_CORRECT,
    USER_VERIFICATION_IS_OTP_EXPIRED,
    USER_VERIFICATION_RESEND_OTP,
    USER_VERIFICATION_IS_MAXIMUM_RETRIES_REACHED,
    SET_ALL_TO_DEFAULT,
} from '../types/yourInformationTypes'

const initialState = {
    data: {} || [],
    resendOTPData: {},
    isOtpCorrect: false,
    userRegistrationDone: false,
    isOtpExpired: false,
    isMaximumRetriesReached: false,
}

export default function yourInformationReducers(state = initialState, { type, payload }) {
    switch (type) {
        case YOUR_INFORMATION_REGISTER_USER:
            return {
                ...state,
                data: payload.data,
                userRegistrationDone: true,
            }
        case USER_VERIFICATION_VERIFY_OTP:
            return {
                ...state,
                isOtpCorrect: true,
            }
        case USER_VERIFICATION_RESET_IS_OTP_CORRECT:
            return {
                ...state,
                isOtpCorrect: false,
            }
        case USER_VERIFICATION_IS_OTP_EXPIRED:
            return {
                ...state,
                isOtpExpired: true,
            }
        case USER_VERIFICATION_IS_MAXIMUM_RETRIES_REACHED:
            return {
                ...state,
                isMaximumRetriesReached: true,
            }

        //Set everything again to default
        case SET_ALL_TO_DEFAULT:
            return {
                ...state,
                data: {} || [],
                resendOTPData: {},
                isOtpCorrect: false,
                userRegistrationDone: false,
                isOtpExpired: false,
                isMaximumRetriesReached: false,
            }

        case USER_VERIFICATION_RESEND_OTP:
            return {
                ...state,
                isOtpExpired: false,
                isMaximumRetriesReached: false,
                resendOTPData: payload.data,
            }

        default:
            return state
    }
}
