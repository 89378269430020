import { Redirect } from 'react-router'
import { StepProgressBar } from '..'
import useClientAccount from '../../05-hooks/useClientAccount'
import {
    ADD_INDIVIDUAL_ROLES,
    BUSINESS_DOCUMENTS,
    BUSINESS_INFORMATION,
    BUSINESS_INFORMATION_1,
    BUSINESS_INFORMATION_2,
    BUSINESS_INFORMATION_3,
    CONFIRMATION_PAGE,
    ONLINE_BANKING_ENROLLMENT,
    OWNERS_INFORMATION_1,
    REVIEW_AND_SUBMIT,
    USER_VERIFICATION,
    YOUR_INFORMATION,
} from '../../07-data/paths'

const stepPaths = [
    [`/:type${YOUR_INFORMATION}`, `/:type${USER_VERIFICATION}`],
    [
        `/:type${BUSINESS_INFORMATION}`,
        `/:type${BUSINESS_INFORMATION_1}`,
        `/:type${BUSINESS_INFORMATION_2}`,
        `/:type${BUSINESS_INFORMATION_3}`,
    ],
    [`/:type${OWNERS_INFORMATION_1}`, `/:type${ADD_INDIVIDUAL_ROLES}`],
    [`/:type${BUSINESS_DOCUMENTS}`],
    [`/:type${ONLINE_BANKING_ENROLLMENT}`],
    [`/:type${REVIEW_AND_SUBMIT}`],
    [`/:type${CONFIRMATION_PAGE}`],
]

function LegitAccount({ children }) {
    const { accountType } = useClientAccount()

    if (accountType === 'N/A') {
        return <Redirect to='/*' />
    } else {
        return (
            <>
                <StepProgressBar
                    steps={[
                        'Your Information',
                        'Business Information',
                        'Owners & Signatories',
                        'Business Documents',
                        'Online Banking',
                        'Review & Submit',
                    ]}
                    stepPaths={stepPaths}
                />
                {children}
            </>
        )
    }
}

export default LegitAccount
