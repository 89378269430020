import { SESSION_LOGOUT, SESSION_LOGIN, REFRESH_SESSION } from '../types/authTypes'
import { ACCESS_APPLICATION_CLEAR_USER_DATA } from '../types/accessExistingAppTypes'
import { aXios, setAuthToken } from '../../07-data/aXios'
import { SESSION_LOGOUT_API, REFRESH_SESSION_API } from '../../07-data/apiEndpoints'
import { FAILURE } from '../../07-data/messages'
//prettier-ignore
import {
    // requestStart, 
    requestFailure, requestSuccess, requestDefault} from './requestActions'
import { toast } from 'react-toastify'
import { setAppRef } from './globalAppRefActions'
import jwt_decode from 'jwt-decode'
/**
 *
 * @param
 * payload: data to store in the authReducer
 * token: the token given by backend
 * @returns the dispatch actions in redux for session management
 */

export const sessionLogin = (payload) => (dispatch) => {
    dispatch({
        type: SESSION_LOGIN,
        payload: payload,
    })
    setAuthToken(payload.param)
}

export const refreshToken = () => async (dispatch) => {
    return new Promise(async (resolve, reject) => {
        // dispatch(requestStart())
        try {
            const res = await aXios.post(REFRESH_SESSION_API)
            if (res) {
                // appRef is stored before dispatching the REFRESH_SESSION
                // action. This ensures that by the time pages that dispatch
                // actions that require an appRef (actions that do network
                // requests) are first rendered, an appRef can be
                // retrieved from the store. Otherwise, those pages will render
                // before an appRef is set, causing network requests that
                // require an appRef to break.
                dispatch(setAppRef(jwt_decode(res.data.param).appRef))
                dispatch({
                    type: REFRESH_SESSION,
                    payload: res.data,
                })
                setAuthToken(res.data.param)
                // dispatch(requestSuccess({ message: 'Successfully Refresh Session' }))
                // dispatch(requestDefault())
                resolve(res.data.param)
            }
        } catch (err) {
            if (err.response?.data?.message !== undefined) {
                dispatch(requestFailure({ message: FAILURE }))

                if (err?.response?.status !== 401) {
                    toast.error(FAILURE)
                    // toast.error(err?.response?.data?.message)
                }
            } else {
                dispatch(requestFailure({ message: REFRESH_SESSION_API }))

                if (err?.response?.status !== 401) {
                    toast.error(FAILURE)
                    // toast.error(err.message)
                }
            }
        }
    })
}

export const sessionLogout = ({ redirectTo }) => {
    return async (dispatch) => {
        try {
            const res = await aXios.post(SESSION_LOGOUT_API)
            dispatch({
                type: SESSION_LOGOUT,
            })
            dispatch({
                type: ACCESS_APPLICATION_CLEAR_USER_DATA,
            })
            dispatch(
                requestSuccess({ message: 'Successfully Logout', redirectTo })
            )
            dispatch(requestDefault())
            toast.success(res.data)
            setAuthToken(false)
        } catch (err) {
            if (err?.response?.status === 403) {
                dispatch(requestFailure({ message: err.response.message }))
            } else {
                if (err?.message === 'Unauthorized Request') {
                    return
                } else {
                    dispatch(requestFailure({ message: FAILURE }))
                    // toast.error(FAILURE)
                    // toast.error(err?.message)
                }
            }
            dispatch(requestDefault())
        }
    }
}
