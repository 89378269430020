// @ts-check

import {
  CMS_ACCESS_EXISTING_APPLICATION_CANCEL_OTP,
  CMS_ACCESS_EXISTING_APPLICATION_IS_MAXIMUM_RETRIES_REACHED,
  CMS_ACCESS_EXISTING_APPLICATION_IS_OTP_EXPIRED,
  CMS_ACCESS_EXISTING_APPLICATION_IS_OTP_TRUE,
  CMS_ACCESS_EXISTING_APPLICATION_RESEND_OTP,
  CMS_ACCESS_EXISTING_APPLICATION_VERIFY_APPREF,
} from './types'

const initialState = {
  data: {
    otp: '',
    param: '',
  },
  resendOTPData: {
    /** @type {string | undefined} */
    otp: undefined,
    /** @type {string | undefined} */
    param: undefined,
  },
  isOtpExpired: false,
  isMaximumRetriesReached: false,
  isOtpCorrect: false,
}

/**
 * @typedef {object} VerifyAppRefAction
 * @property {typeof CMS_ACCESS_EXISTING_APPLICATION_VERIFY_APPREF} type
 * @property {{ otp: string; param: string }} payload
 *
 * @typedef {object} OtpExpiredAction
 * @property {typeof CMS_ACCESS_EXISTING_APPLICATION_IS_OTP_EXPIRED} type
 * @property {undefined} payload
 *
 * @typedef {object} MaximumRetriesReachedAction
 * @property {typeof CMS_ACCESS_EXISTING_APPLICATION_IS_MAXIMUM_RETRIES_REACHED} type
 * @property {undefined} payload
 *
 * @typedef {object} OtpCorrectAction
 * @property {typeof CMS_ACCESS_EXISTING_APPLICATION_IS_OTP_TRUE} type
 * @property {undefined} payload
 *
 * @typedef {object} ResendOtpAction
 * @property {typeof CMS_ACCESS_EXISTING_APPLICATION_RESEND_OTP} type
 * @property {{ otp: string; param: string }} payload
 *
 * @typedef {object} CancelOtpAction
 * @property {typeof CMS_ACCESS_EXISTING_APPLICATION_CANCEL_OTP} type
 * @property {undefined} payload
 *
 * @typedef {| VerifyAppRefAction
 * | OtpExpiredAction
 * | MaximumRetriesReachedAction
 * | OtpCorrectAction
 * | ResendOtpAction
 * | CancelOtpAction} Actions
 */

/**
 * @param {typeof initialState} state
 * @param {Actions} action
 * @returns {typeof initialState}
 */
export default function cmsAccessExistingApplicationReducer(
  state = initialState,
  { type, payload }
) {
  switch (type) {
    case CMS_ACCESS_EXISTING_APPLICATION_VERIFY_APPREF: {
      return {
        ...state,
        data: {
          otp: payload.otp,
          param: payload.param,
        },
      }
    }
    case CMS_ACCESS_EXISTING_APPLICATION_IS_OTP_EXPIRED: {
      return {
        ...state,
        isOtpExpired: true,
      }
    }
    case CMS_ACCESS_EXISTING_APPLICATION_IS_MAXIMUM_RETRIES_REACHED: {
      return {
        ...state,
        isMaximumRetriesReached: true,
      }
    }
    case CMS_ACCESS_EXISTING_APPLICATION_IS_OTP_TRUE: {
      return {
        ...state,
        isOtpCorrect: true,
      }
    }
    case CMS_ACCESS_EXISTING_APPLICATION_RESEND_OTP: {
      return {
        ...state,
        isOtpExpired: false,
        isMaximumRetriesReached: false,
        resendOTPData: {
          otp: payload.otp,
          param: payload.param,
        },
      }
    }
    case CMS_ACCESS_EXISTING_APPLICATION_CANCEL_OTP: {
      return initialState
    }
    default:
      return state
  }
}
