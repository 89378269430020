import {
  CMS_REPRESENTATIVE_INFORMATION_SUBMIT,
  CMS_USER_VERIFICATION_CANCEL_OTP,
  CMS_USER_VERIFICATION_IS_MAXIMUM_RETRIES_REACHED,
  CMS_USER_VERIFICATION_IS_OTP_EXPIRED,
  CMS_USER_VERIFICATION_RESEND_OTP,
  CMS_USER_VERIFICATION_VERIFY_OTP,
} from './types'

const initialState = {
  data: {
    otp: '',
    param: '',
  },
  resendOTPData: {
    otp: undefined,
    param: undefined,
  },
  userRegistrationDone: false,
  isOtpExpired: false,
  isMaximumRetriesReached: false,
  isOtpCorrect: false,
}

/**
 * @param {typeof initialState} state
 * @returns {typeof initialState}
 */
export default function cmsRepresentativeInformationReducer(
  state = initialState,
  { type, payload }
) {
  switch (type) {
    case CMS_REPRESENTATIVE_INFORMATION_SUBMIT: {
      return {
        ...state,
        data: {
          otp: payload.otp,
          param: payload.param,
        },
        userRegistrationDone: true,
      }
    }
    case CMS_USER_VERIFICATION_IS_OTP_EXPIRED: {
      return {
        ...state,
        isOtpExpired: true,
      }
    }
    case CMS_USER_VERIFICATION_IS_MAXIMUM_RETRIES_REACHED: {
      return {
        ...state,
        isMaximumRetriesReached: true,
      }
    }
    case CMS_USER_VERIFICATION_VERIFY_OTP: {
      return {
        ...state,
        isOtpCorrect: true,
      }
    }
    case CMS_USER_VERIFICATION_RESEND_OTP: {
      return {
        ...state,
        isOtpExpired: false,
        isMaximumRetriesReached: false,
        resendOTPData: {
          otp: payload.otp,
          param: payload.param,
        },
      }
    }
    case CMS_USER_VERIFICATION_CANCEL_OTP: {
      return initialState
    }
    default: {
      return state
    }
  }
}
