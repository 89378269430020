// @ts-check

/**
 * @typedef {'same' | 'different'} ApproverOption
 *
 * @typedef {1 | 2 | 3} ApproverLevel
 *
 * @typedef {'A' | 'B' | 'C' | 'D'} ApproverGroup
 *
 * @typedef {| 'any-one-within-group'
 *           | 'any-two-within-group'
 *           | 'any-one-regardless-of-group'
 *           | 'any-two-regardless-of-group'
 * } ApprovalRequirementValue
 *
 * @typedef {object} Product
 * @property {string} id
 * @property {string} description
 * @property {ApproverLevel} maxLevel
 *
 * @typedef {object} ApprovalRequirement
 * @property {string} id
 * @property {string} productId
 * @property {ApproverLevel} approverLevel
 * @property {ApprovalRequirementValue | null} approvalRequirement
 *
 * @typedef {object} AmountRange
 * @property {string} id
 * @property {string} productId
 * @property {ApproverLevel} approverLevel
 * @property {string} amountFrom
 * @property {string} amountTo
 *
 * @typedef {object} Approver
 * @property {string} id
 * @property {string} name
 *
 * @typedef {object} ProductApprover
 * @property {string} id
 * @property {string} productId
 * @property {string} approverId
 *
 * @typedef {object} ProductLevelGroupApprover
 * @property {string} id
 * @property {string} productId
 * @property {ApproverLevel} approverLevel
 * @property {ApproverGroup} group
 * @property {string} approverId
 *
 * @typedef {'firstLevel' | 'secondLevel' | 'thirdLevel'} LevelKeys
 */

/**
 * @template T
 * @typedef {object} Entries<T>
 * @property {{ [id: string]: T }} byId
 * @property {string[]} allIds
 */

import { formatAmount } from '../../utils/formatAmount'
import {
  CMS_APPROVAL_MATRIX_ADD_APPROVER,
  CMS_APPROVAL_MATRIX_CLEAR_PRODUCT_INFORMATION,
  CMS_APPROVAL_MATRIX_COPY_FIRST_PRODUCT_INFORMATION,
  CMS_APPROVAL_MATRIX_INIT_APPROVERS,
  CMS_APPROVAL_MATRIX_INIT_DATA,
  CMS_APPROVAL_MATRIX_INIT_PRODUCTS,
  CMS_APPROVAL_MATRIX_REMOVE_APPROVER,
  CMS_APPROVAL_MATRIX_RESET,
  CMS_APPROVAL_MATRIX_SET_AMOUNT_RANGE,
  CMS_APPROVAL_MATRIX_SET_APPROVAL_REQUIREMENT,
  CMS_APPROVAL_MATRIX_SET_APPROVER_OPTION,
  CMS_APPROVAL_MATRIX_SET_CURRENT_PRODUCT_ID,
  CMS_APPROVAL_MATRIX_SET_MAX_LEVEL,
} from './types'

const initialState = {
  /**
   * `true` if and only if `accountNumberEnrollmentOption` in User Access Rights
   * is set to `"same"`.
   */
  allowSameApproversAcrossProducts: true,
  /** @type {ApproverOption} */
  approverOption: 'same',
  currentProductId: '',
  /** @type {Entries<Product>} */
  products: {
    byId: {
      // 1: { id: '1', description: 'EGOV', maxLevel: 1 },
    },
    allIds: [],
  },
  /** @type {Entries<ApprovalRequirement>} */
  approvalRequirements: {
    byId: {
      // '1|1': { id: '1|1', productId: '1', approverLevel: 1, approvalRequirement: null, },
    },
    allIds: [],
  },
  /** @type {Entries<AmountRange>} */
  amountRanges: {
    byId: {
      // '1|1': { id: '1|1', productId: '1', approverLevel: 1, amountFrom: '0', amountTo: '0', },
    },
    allIds: [],
  },
  /** @type {Entries<Approver>} */
  approvers: {
    byId: {
      // 1: { id: '1', name: 'John Doe' },
    },
    allIds: [],
  },
  /** @type {Entries<ProductApprover>} */
  productApprover: {
    byId: {
      // 1: { id: '1', productId: '1', approverId: '1' },
    },
    allIds: [],
  },
  /** @type {Entries<ProductLevelGroupApprover>} */
  productLevelGroupApprover: {
    byId: {},
    allIds: [],
  },
}

/**
 * @typedef {typeof initialState} CmsApprovalMatrixState
 */

/**
 * @typedef {object} InitProductsAction
 * @property {typeof CMS_APPROVAL_MATRIX_INIT_PRODUCTS} type
 * @property {InitProductsActionPayload} payload
 *
 * @typedef {object} InitProductsActionPayload
 * @property {{ id: string; productName: string }[]} products
 * @property {boolean} allowSameApproversAcrossProducts
 *
 * @typedef {object} InitApproversAction
 * @property {typeof CMS_APPROVAL_MATRIX_INIT_APPROVERS} type
 * @property {InitApproversActionPayload} payload
 *
 * @typedef {object} InitApproversActionPayload
 * @property {{ id: string; fullName: string; productId: string }[]} approvers
 *
 * @typedef {object} InitDataAction
 * @property {typeof CMS_APPROVAL_MATRIX_INIT_DATA} type
 * @property {InitDataActionPayload} payload
 *
 * @typedef {object} InitDataActionPayload
 * @property {({ id: string; maxLevel: ApproverLevel; sameApprovers: boolean } & { [x in LevelKeys]: InitDataActionPayloadLevel | null })[]} data
 *
 * @typedef {object} InitDataActionPayloadLevel
 * @property {ApprovalRequirementValue} approvalRequirement
 * @property {string} amountFrom
 * @property {string} amountTo
 * @property {[{ [G in ApproverGroup as `group${G}`]: { name: string }[] }]} groups
 *
 * @typedef {object} SetApproverOptionAction
 * @property {typeof CMS_APPROVAL_MATRIX_SET_APPROVER_OPTION} type
 * @property {{ approverOption: ApproverOption }} payload
 *
 * @typedef {object} SetApprovalRequirementAction
 * @property {typeof CMS_APPROVAL_MATRIX_SET_APPROVAL_REQUIREMENT} type
 * @property {SetApprovalRequirementActionPayload} payload
 *
 * @typedef {object} SetApprovalRequirementActionPayload
 * @property {string} productId
 * @property {ApproverLevel} approverLevel
 * @property {ApprovalRequirementValue} approvalRequirement
 *
 * @typedef {object} SetAmountRangeAction
 * @property {typeof CMS_APPROVAL_MATRIX_SET_AMOUNT_RANGE} type
 * @property {SetAmountRangeActionPayload} payload
 *
 * @typedef {object} SetAmountRangeActionPayload
 * @property {string} productId
 * @property {ApproverLevel} approverLevel
 * @property {string} amountFrom
 * @property {string} amountTo
 *
 * @typedef {object} AddApproverAction
 * @property {typeof CMS_APPROVAL_MATRIX_ADD_APPROVER} type
 * @property {AddApproverActionPayload} payload
 *
 * @typedef {object} AddApproverActionPayload
 * @property {string} productId
 * @property {ApproverLevel} approverLevel
 * @property {ApproverGroup} group
 * @property {string} approverId
 *
 * @typedef {object} RemoveApproverAction
 * @property {typeof CMS_APPROVAL_MATRIX_REMOVE_APPROVER} type
 * @property {RemoveApproverActionPayload} payload
 *
 * @typedef {object} RemoveApproverActionPayload
 * @property {string} productId
 * @property {ApproverLevel} approverLevel
 * @property {ApproverGroup} group
 * @property {string} approverId
 *
 * @typedef {object} SetMaxLevelAction
 * @property {typeof CMS_APPROVAL_MATRIX_SET_MAX_LEVEL} type
 * @property {SetMaxLevelActionPayload} payload
 *
 * @typedef {object} SetMaxLevelActionPayload
 * @property {string} productId
 * @property {ApproverLevel} maxLevel
 *
 * @typedef {object} SetCurrentProductIdAction
 * @property {typeof CMS_APPROVAL_MATRIX_SET_CURRENT_PRODUCT_ID} type
 * @property {{ productId: string }} payload
 *
 * @typedef {object} CopyInformationAction
 * @property {typeof CMS_APPROVAL_MATRIX_COPY_FIRST_PRODUCT_INFORMATION} type
 * @property {{ productId: string }} payload
 *
 * @typedef {object} ClearProductInformationAction
 * @property {typeof CMS_APPROVAL_MATRIX_CLEAR_PRODUCT_INFORMATION} type
 * @property {{ productId: string }} payload
 *
 * @typedef {object} ResetAction
 * @property {typeof CMS_APPROVAL_MATRIX_RESET} type
 * @property {undefined} payload
 */

/**
 * @param {typeof initialState} state
 * @param {| InitProductsAction
 * | InitApproversAction
 * | InitDataAction
 * | SetApproverOptionAction
 * | SetApprovalRequirementAction
 * | SetAmountRangeAction
 * | AddApproverAction
 * | RemoveApproverAction
 * | SetMaxLevelAction
 * | SetCurrentProductIdAction
 * | CopyInformationAction
 * | ClearProductInformationAction
 * | ResetAction} action
 * @returns {typeof initialState}
 */
export default function cmsApprovalMatrixReducer(
  state = initialState,
  { type, payload }
) {
  switch (type) {
    case CMS_APPROVAL_MATRIX_INIT_PRODUCTS: {
      const { products, allowSameApproversAcrossProducts } = payload

      /** @type {Entries<Product>} */
      const productEntries = {
        byId: {},
        allIds: [],
      }
      /** @type {Entries<ApprovalRequirement>} */
      const approvalRequirements = {
        byId: {},
        allIds: [],
      }
      /** @type {Entries<AmountRange>} */
      const amountRanges = {
        byId: {},
        allIds: [],
      }

      const allProductApproverEntries = state.productApprover.allIds.map(
        (id) => state.productApprover.byId[id]
      )

      products.forEach((product) => {
        if (productEntries.byId[product.id]) {
          return
        }

        if (
          allProductApproverEntries.every(
            (entry) => entry.productId !== product.id
          )
        ) {
          return
        }

        productEntries.byId[product.id] = {
          id: product.id,
          description: product.productName,
          maxLevel: 1,
        }
        productEntries.allIds.push(product.id)

        /** @type {ApproverLevel[]} */
        const approverLevels = [1, 2, 3]
        approverLevels.forEach((approverLevel) => {
          const id = `${product.id}|${approverLevel}`

          approvalRequirements.byId[id] = {
            id,
            productId: product.id,
            approverLevel,
            approvalRequirement: null,
          }
          approvalRequirements.allIds.push(id)

          amountRanges.byId[id] = {
            id,
            productId: product.id,
            approverLevel,
            amountFrom: '0',
            amountTo: '0',
          }
          amountRanges.allIds.push(id)
        })
      })

      return {
        ...state,
        currentProductId: productEntries.allIds[0] ?? '',
        products: productEntries,
        approvalRequirements,
        amountRanges,
        allowSameApproversAcrossProducts,
        approverOption: allowSameApproversAcrossProducts
          ? state.approverOption
          : 'different',
      }
    }
    case CMS_APPROVAL_MATRIX_INIT_APPROVERS: {
      /** @type {Entries<Approver>} */
      const approvers = {
        byId: {},
        allIds: [],
      }
      /** @type {Entries<ProductApprover>} */
      const productApprover = {
        byId: {},
        allIds: [],
      }
      payload.approvers.forEach((approver) => {
        const approverId = approver.id
        if (!approvers.byId[approverId]) {
          approvers.byId[approverId] = {
            id: approverId,
            name: approver.fullName,
          }
          approvers.allIds.push(approverId)
        }

        const productApproverId = `${approver.productId}|${approver.id}`
        if (!productApprover.byId[productApproverId]) {
          productApprover.byId[productApproverId] = {
            id: productApproverId,
            productId: approver.productId,
            approverId,
          }
          productApprover.allIds.push(productApproverId)
        }
      })

      return {
        ...state,
        approvers,
        productApprover,
      }
    }
    case CMS_APPROVAL_MATRIX_INIT_DATA: {
      const { data } = payload
      const {
        products,
        approvalRequirements,
        amountRanges,
        approvers,
        productApprover,
      } = state

      /**
       * @param {object} args
       * @param {string} args.productId
       * @param {ApproverLevel} args.approverLevel
       * @param {ApprovalRequirementValue | null} args.approvalRequirement
       * @param {string} args.amountFrom
       * @param {string} args.amountTo
       */
      const buildLevel = ({
        productId,
        approverLevel,
        approvalRequirement,
        amountFrom,
        amountTo,
      }) => {
        return {
          approvalRequirementEntry: {
            ...approvalRequirements.byId[`${productId}|${approverLevel}`],
            approvalRequirement,
          },
          amountRangeEntry: {
            ...amountRanges.byId[`${productId}|${approverLevel}`],
            amountFrom,
            amountTo,
          },
        }
      }

      /**
       * @param {object} args
       * @param {string} args.productId
       * @param {ApproverLevel} args.approverLevel
       * @param {ApproverGroup} args.group
       * @param {string} args.approverName
       */
      const buildProductLevelGroupApproverEntry = ({
        productId,
        approverLevel,
        group,
        approverName,
      }) => {
        const approverEntry = productApprover.allIds
          .map((id) => productApprover.byId[id])
          .filter((entry) => entry.productId === productId)
          .find(
            (entry) => approvers.byId[entry.approverId].name === approverName
          )

        const approverId = approverEntry?.approverId ?? ''
        const groupEntryId = `${productId}|${approverLevel}|${group}|${approverId}`
        return {
          id: groupEntryId,
          productId,
          approverLevel,
          group,
          approverId,
        }
      }

      const updatedProductsById = {
        ...products.byId,
      }
      const updatedApprovalRequirementsById = {
        ...approvalRequirements.byId,
      }
      const updatedAmountRangesById = {
        ...amountRanges.byId,
      }
      /** @type {Entries<ProductLevelGroupApprover>} */
      const productLevelGroupApprover = {
        byId: {},
        allIds: [],
      }

      data.forEach((item) => {
        updatedProductsById[item.id] = {
          ...updatedProductsById[item.id],
          maxLevel: item.maxLevel,
        }
        //

        /** @type {ApproverLevel[]} */
        const approverLevels = [1, 2, 3]
        /** @type {{ [x in ApproverLevel]: LevelKeys }} */
        const levelKeys = {
          1: 'firstLevel',
          2: 'secondLevel',
          3: 'thirdLevel',
        }
        /** @type {ApproverGroup[]} */
        const groups = ['A', 'B', 'C', 'D']
        approverLevels.forEach((approverLevel) => {
          const levelKey = levelKeys[approverLevel]
          const levelInfo = item[levelKey]

          const { approvalRequirementEntry, amountRangeEntry } = buildLevel({
            productId: item.id,
            approverLevel,
            approvalRequirement: levelInfo?.approvalRequirement ?? null,
            amountFrom: formatAmount(levelInfo?.amountFrom ?? '0'),
            amountTo: formatAmount(levelInfo?.amountTo ?? '0'),
          })

          updatedApprovalRequirementsById[approvalRequirementEntry.id] =
            approvalRequirementEntry
          updatedAmountRangesById[amountRangeEntry.id] = amountRangeEntry

          groups.forEach((group) => {
            levelInfo?.groups[0][`group${group}`].forEach((approver) => {
              const entry = buildProductLevelGroupApproverEntry({
                productId: item.id,
                approverLevel,
                group,
                approverName: approver.name,
              })
              if (!productLevelGroupApprover.byId[entry.id]) {
                productLevelGroupApprover.byId[entry.id] = entry
                productLevelGroupApprover.allIds.push(entry.id)
              }
            })
          })
        })
      })

      /**
       * @param {boolean} b
       * @returns {ApproverOption}
       */
      const booleanToApproverOption = (b) => (b ? 'same' : 'different')

      return {
        ...state,
        approverOption: booleanToApproverOption(
          state.allowSameApproversAcrossProducts &&
            (data[0] === undefined || data[0].sameApprovers)
        ),
        products: {
          ...state.products,
          byId: updatedProductsById,
        },
        approvalRequirements: {
          ...state.approvalRequirements,
          byId: updatedApprovalRequirementsById,
        },
        amountRanges: {
          ...state.amountRanges,
          byId: updatedAmountRangesById,
        },
        productLevelGroupApprover,
      }
    }
    case CMS_APPROVAL_MATRIX_SET_APPROVER_OPTION: {
      const { approverOption } = payload
      return {
        ...state,
        approverOption,
        currentProductId:
          approverOption === 'same'
            ? state.products.allIds[0]
            : state.currentProductId,
      }
    }
    case CMS_APPROVAL_MATRIX_SET_APPROVAL_REQUIREMENT: {
      const { productId, approverLevel, approvalRequirement } = payload

      const oldApprovalRequirementEntry = state.approvalRequirements.allIds
        .map((id) => state.approvalRequirements.byId[id])
        .find(
          (entry) =>
            entry.productId === productId &&
            entry.approverLevel === approverLevel
        )

      if (!oldApprovalRequirementEntry) {
        return state
      }

      const { id } = oldApprovalRequirementEntry
      return {
        ...state,
        approvalRequirements: {
          ...state.approvalRequirements,
          byId: {
            ...state.approvalRequirements.byId,
            [id]: {
              ...state.approvalRequirements.byId[id],
              approvalRequirement,
            },
          },
        },
      }
    }
    case CMS_APPROVAL_MATRIX_SET_AMOUNT_RANGE: {
      const { productId, approverLevel, amountFrom, amountTo } = payload

      const oldAmountRangeEntry = state.amountRanges.allIds
        .map((id) => state.amountRanges.byId[id])
        .find(
          (entry) =>
            entry.productId === productId &&
            entry.approverLevel === approverLevel
        )

      if (!oldAmountRangeEntry) {
        return state
      }

      const { id } = oldAmountRangeEntry
      return {
        ...state,
        amountRanges: {
          ...state.amountRanges,
          byId: {
            ...state.amountRanges.byId,
            [id]: {
              ...state.amountRanges.byId[id],
              amountFrom,
              amountTo,
            },
          },
        },
      }
    }
    case CMS_APPROVAL_MATRIX_ADD_APPROVER: {
      const { productId, approverLevel, group, approverId } = payload

      const entryId = createProductLevelGroupApproverId(
        productId,
        approverLevel,
        group,
        approverId
      )

      return {
        ...state,
        productLevelGroupApprover: {
          ...state.productLevelGroupApprover,
          byId: {
            ...state.productLevelGroupApprover.byId,
            [entryId]: {
              id: entryId,
              productId,
              approverLevel,
              group,
              approverId,
            },
          },
          allIds: [...state.productLevelGroupApprover.allIds, entryId],
        },
      }
    }
    case CMS_APPROVAL_MATRIX_REMOVE_APPROVER: {
      const { productId, approverLevel, group, approverId } = payload
      const { byId, allIds } = state.productLevelGroupApprover

      const existingEntry = allIds
        .map((id) => byId[id])
        .find(
          (entry) =>
            entry.productId === productId &&
            entry.approverLevel === approverLevel &&
            entry.group === group &&
            entry.approverId === approverId
        )

      if (!existingEntry) {
        return state
      }

      const updatedByIdObj = { ...state.productLevelGroupApprover.byId }
      delete updatedByIdObj[existingEntry.id]

      const updatedAllIds = state.productLevelGroupApprover.allIds.filter(
        (id) => id !== existingEntry.id
      )

      return {
        ...state,
        productLevelGroupApprover: {
          ...state.productLevelGroupApprover,
          byId: updatedByIdObj,
          allIds: updatedAllIds,
        },
      }
    }
    case CMS_APPROVAL_MATRIX_SET_MAX_LEVEL: {
      const { productId, maxLevel: newMaxLevel } = payload
      const oldMaxLevel = state.products.byId[productId]?.maxLevel

      if (!oldMaxLevel) {
        return state
      }

      let approvalRequirementEntry =
        state.approvalRequirements.byId[`${productId}|${oldMaxLevel}`]
      let amountRangeEntry =
        state.amountRanges.byId[`${productId}|${oldMaxLevel}`]
      let approverEntries = state.productLevelGroupApprover

      if (newMaxLevel < oldMaxLevel) {
        approvalRequirementEntry = {
          ...approvalRequirementEntry,
          approvalRequirement: null,
        }
        amountRangeEntry = {
          ...amountRangeEntry,
          amountFrom: '0',
          amountTo: '0',
        }

        const newProductLevelGroupApprover =
          state.productLevelGroupApprover.allIds
            .map((id) => state.productLevelGroupApprover.byId[id])
            .filter(
              (entry) =>
                entry.productId !== productId ||
                entry.approverLevel <= newMaxLevel
            )
            .reduce(
              (acc, entry) =>
                !acc.has(entry.id) ? acc.set(entry.id, entry) : acc,
              /** @type {Map<string, ProductLevelGroupApprover>} */ (new Map())
            )

        approverEntries = {
          byId: Array.from(newProductLevelGroupApprover.values()).reduce(
            (acc, entry) => ({ ...acc, [entry.id]: entry }),
            {}
          ),
          allIds: Array.from(newProductLevelGroupApprover.keys()),
        }
      }

      return {
        ...state,
        products: {
          ...state.products,
          byId: {
            ...state.products.byId,
            [productId]: {
              ...state.products.byId[productId],
              maxLevel: newMaxLevel,
            },
          },
        },
        approvalRequirements: {
          ...state.approvalRequirements,
          byId: {
            ...state.approvalRequirements.byId,
            [approvalRequirementEntry.id]: approvalRequirementEntry,
          },
        },
        amountRanges: {
          ...state.amountRanges,
          byId: {
            ...state.amountRanges.byId,
            [amountRangeEntry.id]: amountRangeEntry,
          },
        },
        productLevelGroupApprover: approverEntries,
      }
    }
    case CMS_APPROVAL_MATRIX_SET_CURRENT_PRODUCT_ID: {
      return {
        ...state,
        currentProductId: payload.productId,
      }
    }
    case CMS_APPROVAL_MATRIX_COPY_FIRST_PRODUCT_INFORMATION: {
      if (state.products.allIds.length === 0) {
        return state
      }

      const { productId } = payload
      const firstProductId = state.products.allIds[0]

      const newApprovalRequirements = state.approvalRequirements.allIds
        .map((id) => state.approvalRequirements.byId[id])
        .map((entry) => {
          if (entry.productId !== productId) {
            return entry
          }

          return {
            ...entry,
            approvalRequirement:
              state.approvalRequirements.byId[
                `${firstProductId}|${entry.approverLevel}`
              ].approvalRequirement,
          }
        })

      const newAmountRanges = state.amountRanges.allIds
        .map((id) => state.amountRanges.byId[id])
        .map((entry) => {
          if (entry.productId !== productId) {
            return entry
          }

          const sourceEntry =
            state.amountRanges.byId[`${firstProductId}|${entry.approverLevel}`]
          return {
            ...entry,
            amountFrom: sourceEntry.amountFrom,
            amountTo: sourceEntry.amountTo,
          }
        })

      const copiedEntries = state.productLevelGroupApprover.allIds
        .map((id) => state.productLevelGroupApprover.byId[id])
        .filter((entry) => entry.productId === firstProductId)
        .map((firstProductEntry) => {
          const sourceApprover =
            state.approvers.byId[firstProductEntry.approverId]
          const targetApprover = state.productApprover.allIds
            .map((id) => state.productApprover.byId[id])
            .filter((entry) => entry.productId === productId)
            .map((entry) => entry.approverId)
            .map((approverId) => state.approvers.byId[approverId])
            .find((approver) => approver.name === sourceApprover.name)

          if (!targetApprover) {
            return firstProductEntry
          }

          return {
            ...firstProductEntry,
            id: createProductLevelGroupApproverId(
              productId,
              firstProductEntry.approverLevel,
              firstProductEntry.group,
              targetApprover.id
            ),
            productId,
            approverId: targetApprover.id,
          }
        })

      const newProductLevelGroupApproverMap =
        state.productLevelGroupApprover.allIds
          .map((id) => state.productLevelGroupApprover.byId[id])
          .filter((entry) => entry.productId !== productId)
          .concat(copiedEntries)
          .reduce(
            (acc, entry) =>
              !acc.has(entry.id) ? acc.set(entry.id, entry) : acc,
            /** @type {Map<string, ProductLevelGroupApprover>} */ (new Map())
          )

      return {
        ...state,
        products: {
          ...state.products,
          byId: {
            ...state.products.byId,
            [productId]: {
              ...state.products.byId[productId],
              maxLevel: state.products.byId[firstProductId].maxLevel,
            },
          },
        },
        approvalRequirements: {
          ...state.approvalRequirements,
          byId: newApprovalRequirements.reduce(
            (acc, entry) => ({ ...acc, [entry.id]: entry }),
            /** @type {Entries<ApprovalRequirement>['byId']} */ ({})
          ),
        },
        amountRanges: {
          ...state.amountRanges,
          byId: newAmountRanges.reduce(
            (acc, entry) => ({ ...acc, [entry.id]: entry }),
            /** @type {Entries<AmountRange>['byId']} */ ({})
          ),
        },
        productLevelGroupApprover: {
          byId: Array.from(newProductLevelGroupApproverMap.values()).reduce(
            (acc, entry) => ({ ...acc, [entry.id]: entry }),
            /** @type {Entries<ProductLevelGroupApprover>['byId']} */ ({})
          ),
          allIds: Array.from(newProductLevelGroupApproverMap.keys()),
        },
      }
    }
    case CMS_APPROVAL_MATRIX_CLEAR_PRODUCT_INFORMATION: {
      const { productId } = payload

      const newApprovalRequirements = state.approvalRequirements.allIds
        .map((id) => state.approvalRequirements.byId[id])
        .map((entry) =>
          entry.productId === productId
            ? { ...entry, approvalRequirement: null }
            : entry
        )
      const newAmountRanges = state.amountRanges.allIds
        .map((id) => state.amountRanges.byId[id])
        .map((entry) =>
          entry.productId === productId
            ? { ...entry, amountFrom: '0', amountTo: '0' }
            : entry
        )

      const newProductLevelGroupApproverMap =
        state.productLevelGroupApprover.allIds
          .map((id) => state.productLevelGroupApprover.byId[id])
          .filter((entry) => entry.productId !== productId)
          .reduce(
            (acc, entry) =>
              !acc.has(entry.id) ? acc.set(entry.id, entry) : acc,
            /** @type {Map<string, ProductLevelGroupApprover>} */ (new Map())
          )

      return {
        ...state,
        products: {
          ...state.products,
          byId: {
            ...state.products.byId,
            [productId]: {
              ...state.products.byId[productId],
              maxLevel: 1,
            },
          },
        },
        approvalRequirements: {
          ...state.approvalRequirements,
          byId: newApprovalRequirements.reduce(
            (acc, entry) => ({ ...acc, [entry.id]: entry }),
            /** @type {Entries<ApprovalRequirement>['byId']} */ ({})
          ),
        },
        amountRanges: {
          ...state.amountRanges,
          byId: newAmountRanges.reduce(
            (acc, entry) => ({ ...acc, [entry.id]: entry }),
            /** @type {Entries<AmountRange>['byId']} */ ({})
          ),
        },
        productLevelGroupApprover: {
          byId: Array.from(newProductLevelGroupApproverMap.values()).reduce(
            (acc, entry) => ({ ...acc, [entry.id]: entry }),
            {}
          ),
          allIds: Array.from(newProductLevelGroupApproverMap.keys()),
        },
      }
    }
    case CMS_APPROVAL_MATRIX_RESET: {
      return initialState
    }
    default: {
      return state
    }
  }
}

function createProductLevelGroupApproverId(
  productId,
  approverLevel,
  group,
  approverId
) {
  return `${productId}|${approverLevel}|${group}|${approverId}`
}
