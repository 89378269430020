import { FaEnvelopeOpen, FaGlobe, FaPhone } from 'react-icons/fa'
import { useRouteMatch } from 'react-router-dom'
import rbankLogo from '../../01-assets/images/rbank-logo-green.png'
import { CMS_ROOT } from '../../cms/constants/pathnames'
import styles from './footer.module.scss'

export default function Footer() {
    const cmsRouteMatch = useRouteMatch(CMS_ROOT)

    if (!cmsRouteMatch) {
      return null
    }

    return (
        <footer className={styles.footer}>
            <img
                className={styles.footer__logo}
                src={rbankLogo}
                alt=""
                width={800}
                height={121}
            />

            <div>
                <FaGlobe
                    className={styles.footer__icon}
                    aria-label="Website"
                />{' '}
                www.robinsonsbank.com.ph
            </div>
            <div>
                <FaEnvelopeOpen
                    className={styles.footer__icon}
                    aria-label="Email"
                />{' '}
                cmscare@robinsonsbank.com.ph
            </div>
            <div>
                <FaPhone
                    className={styles.footer__icon}
                    aria-label="Telephone"
                />{' '}
                (+63 02) 8702-9500 local 48642, 48644, 48648, 48650
            </div>
        </footer>
    )
}
