import { matchPath, useLocation } from 'react-router-dom'
import { Step } from '../../02-components'
import './index.scss'

function StepProgressBar({ steps, stepPaths }) {
    const { pathname } = useLocation()
    const currentStep = stepPaths.findIndex((route) =>
        matchPath(pathname, route)
    )

    // This component is a descendant of Account and Your Application, but it's
    // not supposed to be rendered in those pages. `findIndex` above will catch
    // that and return -1 for those two views.
    if (currentStep === -1) {
        return null
    }

    return (
        <div className="step-progress-bar">
            <ol className="step-progress-bar__list">
                {steps.map((step, index) => (
                    <Step
                        key={step}
                        num={index + 1}
                        isActive={index === currentStep}
                        info={step}
                        isDone={index < currentStep}
                    />
                ))}
            </ol>
        </div>
    )
}

export default StepProgressBar
