import useResponseData from './useResponseData'

export default function usePolicies() {
    const { data, loading } = useResponseData('/api/DX/Policy')
    return {
        loading,
        cookiePolicy: data?.[0]?.writeupList,
        termsOfUse: data?.[1]?.writeupList,
        termsAndConditions: data?.[2]?.writeupList,
        contactUs: data?.[3]?.writeupList,
        privacyPolicy: data?.[4]?.writeupList,
    }
}
