import { Route } from 'react-router-dom'
import ProtectedRoute from '../02-components/ProtectedRoute'
import lazy from '../08-util/lazy'
import {
    ACCESS_EXISTING_APP,
    ACCOUNT,
    ADD_INDIVIDUAL_ROLES,
    BUSINESS_DOCUMENTS,
    BUSINESS_INFORMATION,
    BUSINESS_INFORMATION_1,
    BUSINESS_INFORMATION_2,
    BUSINESS_INFORMATION_3,
    CONFIRMATION_PAGE,
    DOCUMENTARY_REQUIREMENTS,
    HELP_PAGE,
    HOME,
    ONLINE_BANKING_ENROLLMENT,
    OWNERS_INFORMATION_1,
    REGISTRATION_ERROR_PAGE,
    REVIEW_AND_SUBMIT,
    SELECT_PRODUCT_PAGE,
    USER_VERIFICATION,
    VALIDATE_OTP_PAGE,
    YOUR_APPLICATION_PAGE,
    YOUR_INFORMATION,
} from './paths'

export const typeParam =
    ':type(business-savings|business-checking|business-link)'

export const routes = [
    {
        name: 'Home',
        path: HOME,
        Component: lazy(() => import('../04-pages/Home')),
        RouteComponent: Route,
    },
    {
        name: 'Account',
        path: `${ACCOUNT}/${typeParam}`,
        Component: lazy(() => import('../04-pages/Account')),
        RouteComponent: Route,
    },
    {
        name: 'YourInformation',
        path: `/${typeParam}${YOUR_INFORMATION}`,
        Component: lazy(() => import('../04-pages/YourInformation')),
        RouteComponent: Route,
    },
    {
        name: 'RegistrationErrorPage',
        path: REGISTRATION_ERROR_PAGE,
        Component: lazy(() => import('../04-pages/RegistrationErrorPage')),
        RouteComponent: Route,
    },
    {
        name: 'UserVerification',
        path: `/${typeParam}${USER_VERIFICATION}`,
        Component: lazy(() => import('../04-pages/UserVerification')),
        RouteComponent: Route,
    },
    {
        name: 'BusinessInformation_1',
        path: `/${typeParam}${BUSINESS_INFORMATION_1}`,
        Component: lazy(() => import('../04-pages/BusinessInformation_1')),
        RouteComponent: ProtectedRoute,
    },
    {
        name: 'BusinessInformation_2',
        path: `/${typeParam}${BUSINESS_INFORMATION_2}`,
        Component: lazy(() => import('../04-pages/BusinessInformation_2')),
        RouteComponent: ProtectedRoute,
    },
    {
        name: 'BusinessInformation_3',
        path: `/${typeParam}${BUSINESS_INFORMATION_3}`,
        Component: lazy(() => import('../04-pages/BusinessInformation_3')),
        RouteComponent: ProtectedRoute,
    },
    {
        name: 'BusinessInformation',
        path: `/${typeParam}${BUSINESS_INFORMATION}`,
        Component: lazy(() => import('../04-pages/FinacleBusinessInfo')),
        RouteComponent: ProtectedRoute,
    },
    {
        name: 'OwnersInformation_1',
        path: `/${typeParam}${OWNERS_INFORMATION_1}`,
        Component: lazy(() => import('../04-pages/OwnersInformation_1')),
        RouteComponent: ProtectedRoute,
    },
    {
        name: 'AddIndividualRoles',
        path: `/${typeParam}${ADD_INDIVIDUAL_ROLES}`,
        Component: lazy(() => import('../04-pages/AddIndividualRoles')),
        RouteComponent: ProtectedRoute,
    },
    {
        name: 'BusinessDocuments',
        path: `/${typeParam}${BUSINESS_DOCUMENTS}`,
        Component: lazy(() => import('../04-pages/BusinessDocuments')),
        RouteComponent: ProtectedRoute,
    },
    {
        name: 'OnlineBankingEnrollment',
        path: `/${typeParam}${ONLINE_BANKING_ENROLLMENT}`,
        Component: lazy(() => import('../04-pages/OnlineBankingEnrollment')),
        RouteComponent: ProtectedRoute,
    },
    {
        name: 'ReviewAndSubmit',
        path: `/${typeParam}${REVIEW_AND_SUBMIT}`,
        Component: lazy(() => import('../04-pages/ReviewAndSubmit')),
        RouteComponent: ProtectedRoute,
    },
    {
        name: 'SelectProduct',
        path: SELECT_PRODUCT_PAGE,
        Component: lazy(() => import('../04-pages/SelectProduct')),
        RouteComponent: ProtectedRoute,
    },
    {
        name: 'Confirmation',
        path: `/${typeParam}${CONFIRMATION_PAGE}`,
        Component: lazy(() => import('../04-pages/Confirmation')),
        RouteComponent: ProtectedRoute,
    },
    {
        name: 'AccessExistingApp',
        path: ACCESS_EXISTING_APP,
        Component: lazy(() => import('../04-pages/AccessExistingApp')),
        RouteComponent: Route,
    },
    {
        name: 'ValidateOtp',
        path: VALIDATE_OTP_PAGE,
        Component: lazy(() => import('../04-pages/ValidateOtp')),
        RouteComponent: Route,
    },
    {
        name: 'YourApplication',
        path: YOUR_APPLICATION_PAGE,
        Component: lazy(() => import('../04-pages/YourApplication')),
        RouteComponent: ProtectedRoute,
    },
    {
        name: 'CMS',
        path: '/cms',
        Component: lazy(() => import('../cms/App')),
        RouteComponent: Route,
        exact: false,
    },
    {
        name: 'DocumentaryRequirements',
        path: `${DOCUMENTARY_REQUIREMENTS}`,
        Component: lazy(() => import('../04-pages/DocumentaryRequirements')),
        RouteComponent: Route,
    },
    {
        name: 'Help',
        path: HELP_PAGE,
        Component: lazy(() => import('../04-pages/Help')),
        RouteComponent: Route,
    },
    {
        name: 'PageNotFound',
        path: '*',
        Component: lazy(() => import('../04-pages/PageNotFound')),
        RouteComponent: Route,
    },
]
