import { combineReducers } from 'redux'
import cmsAccessExistingApplicationReducer from './accessExistingApplication/reducer'
import cmsAdditionalInformationReducer from './additionalInformation/reducer'
import cmsApprovalMatrixReducer from './approvalMatrix/reducer'
import cmsBusinessDocumentsReducer from './businessDocuments/reducer'
import cmsBusinessInformationReducer from './businessInformation/reducer'
import cmsDocumentsReducer from './cmsDocuments/reducer'
import cmsPricingDetailsReducer from './pricingDetails/reducer'
import cmsRegistrationReducer from './registration/reducer'
import cmsReviewAndSubmitReducer from './reviewAndSubmit/reducer'
import cmsUserAccessRightsReducer from './userAccessRights/reducer'
import cmsYourApplicationReducer from './yourApplication/reducer'

const cmsRootReducer = combineReducers({
  registration: cmsRegistrationReducer,
  businessInformation: cmsBusinessInformationReducer,
  userAccessRights: cmsUserAccessRightsReducer,
  additionalInformation: cmsAdditionalInformationReducer,
  approvalMatrix: cmsApprovalMatrixReducer,
  businessDocuments: cmsBusinessDocumentsReducer,
  pricingDetails: cmsPricingDetailsReducer,
  reviewAndSubmit: cmsReviewAndSubmitReducer,
  accessExistingApplication: cmsAccessExistingApplicationReducer,
  yourApplication: cmsYourApplicationReducer,
  cmsDocuments: cmsDocumentsReducer,
})

export default cmsRootReducer
