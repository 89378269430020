import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { refreshToken } from '../09-store/actions/authActions'

function useIsAuthenticated() {
    const isLoggedIn = useSelector((state) => state.auth.isLoggedIn)
    const dispatch = useDispatch()

    useEffect(() => {
        if (isLoggedIn === undefined) {
            dispatch(refreshToken())
        }
    }, [isLoggedIn, dispatch])

    return {
        isAuthenticated: isLoggedIn,
    }
}

export default useIsAuthenticated
