import {
    REQUEST_START,
    REQUEST_SUCCESS,
    REQUEST_FAILURE,
    REQUEST_DEFAULT,
} from '../types/requestTypes'

const initialState = {
    isLoading: false,
    isSuccess: false,
    message: '',
    redirectTo: '',
}

const requestReducers = (state = initialState, { type, payload }) => {
    switch (type) {
        case REQUEST_START:
            return { ...state, isLoading: true }

        case REQUEST_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSuccess: true,
                message: payload.message,
                redirectTo: payload.redirectTo,
            }

        case REQUEST_FAILURE:
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                message: payload.message,
                redirectTo: '',
            }
        case REQUEST_DEFAULT:
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                message: '',
                redirectTo: '',
            }
        default:
            return state
    }
}

export default requestReducers
