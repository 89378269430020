import { IoMdExit } from 'react-icons/io'
import { CustomAlert } from '../../02-components'
import useCustomAlert from '../../05-hooks/useCustomAlert'
import useLogout from '../../05-hooks/useLogout'

function NavbarExitButton() {
    const logout = useLogout()
    const { openAlert, handleCloseAlert, handleOpenAlert } = useCustomAlert()

    return (
        <>
            <button
                className="navbar__menu-item"
                onClick={(e) => {
                    e.preventDefault()
                    handleOpenAlert()
                }}
            >
                <IoMdExit className="navbar__menu-icon" aria-hidden="true" />{' '}
                Exit
            </button>

            <CustomAlert
                secondaryAction={handleCloseAlert}
                secondaryActionText="Cancel"
                openAlert={openAlert}
                primaryAction={() => {
                    handleCloseAlert()
                    logout()
                }}
                primaryActionText="Ok"
            >
                Are you sure you want to exit the app?
            </CustomAlert>
        </>
    )
}

export default NavbarExitButton
