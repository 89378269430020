// @ts-check

import { CMS_REVIEW_AND_SUBMIT_INIT } from './types'

/**
 * @typedef {object} Product
 * @property {string} id
 * @property {string} name
 * @property {ProductEntry[]} entries
 * @property {string[]} remarks
 *
 * @typedef {object} ProductEntry
 * @property {string} id
 * @property {string} accountNumber
 * @property {string} user
 * @property {string} approverInfo
 *
 * @typedef {object} Field
 * @property {string} key
 * @property {string} name
 * @property {string} value
 *
 * @typedef {object} CorporateAdaBiller
 * @property {Field[]} fields
 * @property {string} remarks
 *
 * @typedef {object} CorporateAdaPayor
 * @property {Field[]} fields
 * @property {string} remarks
 *
 * @typedef {object} EGov
 * @property {EGovProduct[]} products
 * @property {EGovUser[]} users
 * @property {string} remarks
 * 
 * @typedef {object} EGovProduct
 * @property {string} key
 * @property {string} value
 *
 * @typedef {object} EGovUser
 * @property {string} key
 * @property {string} info
 *
 * @typedef {object} SupplyChainFinancing
 * @property {Field[]} fields
 * @property {SupplyChainFinancingOwner[]} owners
 * @property {string} remarks
 * 
 * @typedef {object} SupplyChainFinancingOwner
 * @property {string} key
 * @property {string} info
 * 
 * @typedef {object} Pricing
 * @property {Field[]} fields
 * @property {string} remarks
 */

const initialState = {
  /** @type {Product[]} */
  products: [],
  additionalInfo: {
    /** @type {CorporateAdaBiller | null} */
    corporateAdaBiller: null,
    /** @type {CorporateAdaPayor | null} */
    corporateAdaPayor: null,
    /** @type {EGov | null} */
    egov: null,
    /** @type {SupplyChainFinancing | null} */
    supplyChainFinancing: null,
  },
  /** @type {{ value: string; remarks: string } | null} */
  businessDocuments: null,
  /** @type {Pricing} */
  pricingDetails: {
    fields: [],
    remarks: '',
  },
}

/**
 * @typedef {typeof initialState} CmsReviewAndSubmitState
 */

/**
 * @typedef {object} InitAction
 * @property {typeof CMS_REVIEW_AND_SUBMIT_INIT} type
 * @property {CmsReviewAndSubmitState} payload
 */

/**
 * @param {CmsReviewAndSubmitState} state
 * @param {InitAction} action
 * @returns {CmsReviewAndSubmitState}
 */
export default function cmsReviewAndSubmitReducer(
  state = initialState,
  { type, payload }
) {
  switch (type) {
    case CMS_REVIEW_AND_SUBMIT_INIT: {
      return {
        products: payload.products,
        additionalInfo: payload.additionalInfo,
        businessDocuments: payload.businessDocuments,
        pricingDetails: payload.pricingDetails,
      }
    }
    default: {
      return state
    }
  }
}
