export const CMS_REPRESENTATIVE_INFORMATION_SUBMIT =
  'CMS_REPRESENTATIVE_INFORMATION_SUBMIT'

export const CMS_USER_VERIFICATION_IS_OTP_EXPIRED =
  'CMS_USER_VERIFICATION_IS_OTP_EXPIRED'
export const CMS_USER_VERIFICATION_IS_MAXIMUM_RETRIES_REACHED =
  'CMS_USER_VERIFICATION_IS_MAXIMUM_RETRIES_REACHED'
export const CMS_USER_VERIFICATION_VERIFY_OTP =
  'CMS_USER_VERIFICATION_VERIFY_OTP'
export const CMS_USER_VERIFICATION_RESEND_OTP =
  'CMS_USER_VERIFICATION_RESEND_OTP'
export const CMS_USER_VERIFICATION_CANCEL_OTP =
  'CMS_USER_VERIFICATION_CANCEL_OTP'
