import './index.scss'

function BaseBanner({ banner1440, banner1024, banner768, banner425 }) {
    return (
        <div className="banner">
            <picture className="banner__picture">
                <source
                    srcSet={banner1440}
                    media="(min-width: 1440px)"
                    width={1440}
                    height={220}
                />
                <source
                    srcSet={banner1024}
                    media="(min-width: 1024px)"
                    width={1024}
                    height={150}
                />
                <source
                    srcSet={banner768}
                    media="(min-width: 768px)"
                    width={768}
                    height={135}
                />
                <img src={banner425} alt="" width={425} height={100} />
            </picture>
        </div>
    )
}

export default BaseBanner
