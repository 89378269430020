// OWNERS INFORMATION
export const OWNERS_INFORMATION_FETCH_SIGNING_REQUIREMENTS = 'OWNERS_INFORMATION_FETCH_SIGNING_REQUIREMENTS'
export const OWNERS_INFORMATION_FETCH_USER = 'OWNERS_INFORMATION_FETCH_USER'
export const OWNERS_INFORMATION_DELETE_USER = 'OWNERS_INFORMATION_DELETE_USER'
export const OWNERS_INFORMATION_FETCH_USER_BY_APPREF_AND_ID =
    'OWNERS_INFORMATION_FETCH_USER_BY_APPREF_AND_ID'
export const OWNERS_INFORMATION_SET_USER_REDUCER_TO_DEFAULT =
    'OWNERS_INFORMATION_SET_USER_REDUCER_TO_DEFAULT'
export const OWNERS_INFORMATION_ADD_INFO = 'OWNERS_INFORMATION_ADD_INFO'
export const OWNERS_INFORMATION_FETCH_SERVICING_BRANCH_INFO =
    'OWNERS_INFORMATION_FETCH_SERVICING_BRANCH_INFO'
