// @ts-check

/**
 * @typedef {object} PricingBase
 * @property {string} id
 * @property {string} description
 *
 * @typedef {object} PricingInfo
 * @property {string} id
 * @property {string} name
 * @property {string[]} productIds
 * @property {string} adbDescription
 * @property {string} feeDescription
 * @property {string} value
 * @property {boolean} free
 * @property {boolean} adbOnly
 *
 * @typedef {object} UserPricingData
 * @property {string} productId
 * @property {string} basePricing
 */

export const CMS_PRICING_DETAILS_INIT = 'CMS_PRICING_DETAILS_INIT'
export const CMS_PRICING_DETAILS_RESET = 'CMS_PRICING_DETAILS_RESET'
