export const CMS_USER_ACCESS_RIGHTS_INIT = 'CMS_USER_ACCESS_RIGHTS_INIT'
export const CMS_USER_ACCESS_RIGHTS_SELECT_PRODUCT =
  'CMS_USER_ACCESS_RIGHTS_SELECT_PRODUCT'
export const CMS_USER_ACCESS_RIGHTS_DESELECT_PRODUCT =
  'CMS_USER_ACCESS_RIGHTS_DESELECT_PRODUCT'
export const CMS_USER_ACCESS_RIGHTS_SELECT_ACCOUNT_NUMBER =
  'CMS_USER_ACCESS_RIGHTS_SELECT_ACCOUNT_NUMBER'
export const CMS_USER_ACCESS_RIGHTS_DESELECT_ACCOUNT_NUMBER =
  'CMS_USER_ACCESS_RIGHTS_DESELECT_ACCOUNT_NUMBER'
export const CMS_USER_ACCESS_RIGHTS_SET_ACCOUNT_NUMBER_ENROLLMENT_OPTION_TO_SAME =
  'CMS_USER_ACCESS_RIGHTS_SET_ACCOUNT_NUMBER_ENROLLMENT_OPTION_TO_SAME'
export const CMS_USER_ACCESS_RIGHTS_SET_ACCOUNT_NUMBER_ENROLLMENT_OPTION_TO_DIFFERENT =
  'CMS_USER_ACCESS_RIGHTS_SET_ACCOUNT_NUMBER_ENROLLMENT_OPTION_TO_DIFFERENT'
export const CMS_USER_ACCESS_RIGHTS_SET_PRODUCT_USER_ENROLLMENT_OPTION_TO_SAME =
  'CMS_USER_ACCESS_RIGHTS_SET_PRODUCT_USER_ENROLLMENT_OPTION_TO_SAME'
export const CMS_USER_ACCESS_RIGHTS_SET_PRODUCT_USER_ENROLLMENT_OPTION_TO_DIFFERENT =
  'CMS_USER_ACCESS_RIGHTS_SET_PRODUCT_USER_ENROLLMENT_OPTION_TO_DIFFERENT'
export const CMS_USER_ACCESS_RIGHTS_ADD_USER =
  'CMS_USER_ACCESS_RIGHTS_ADD_USER '
export const CMS_USER_ACCESS_RIGHTS_REMOVE_USER =
  'CMS_USER_ACCESS_RIGHTS_REMOVE_USER'
export const CMS_USER_ACCESS_RIGHTS_SET_CURRENT_PRODUCT_ID =
  'CMS_USER_ACCESS_RIGHTS_SET_CURRENT_PRODUCT_ID'
export const CMS_USER_ACCESS_RIGHTS_SET_CURRENT_ACCOUNT_NUMBER =
  'CMS_USER_ACCESS_RIGHTS_SET_CURRENT_ACCOUNT_NUMBER'
