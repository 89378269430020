import { Fragment, memo, useMemo } from 'react'
import { Typography } from '../../02-components'
import { Modal } from '../../03-ui'

function PolicyModal({ show, title, handleClose, loading, content }) {
    const renderedContent = useMemo(
        () =>
            content?.map((section, index) => (
                <Fragment key={index}>
                    <Typography type="h3">{section.dscp}</Typography>

                    {section.writeupBull.map((desc, index) => (
                        <Typography key={index} type="p">
                            {desc.dscp}
                        </Typography>
                    ))}
                </Fragment>
            )),
        [content]
    )

    return (
        <Modal show={show} title={title} handleClose={handleClose}>
            {loading ? (
                <Typography type="p" style={{ textAlign: 'center' }}>
                    Loading ...
                </Typography>
            ) : (
                renderedContent
            )}
        </Modal>
    )
}

export default memo(PolicyModal)
