import React from 'react'
import './index.scss'
function Typography({ type, children }) {
    switch (type) {
        case 'h1':
            return <h1 className='heading'>{children}</h1>
        case 'h2':
            return <h2 className='heading'>{children}</h2>
        case 'h3':
            return <h3 className='heading'>{children}</h3>
        case 'h4':
            return <h4 className='heading'>{children}</h4>
        case 'h5':
            return <h5 className='heading'>{children}</h5>
        case 'h6':
            return <h6 className='heading'>{children}</h6>
        case 'p':
            return <p className='paragraph'>{children}</p>
        case 'h3primary':
            return <p className='headingPrimary'>{children}</p>

        default:
            return null
    }
}

export default Typography
