import ErrorMessage from '../ErrorMessage'
import './index.scss'

function BrowseFile({ name, onChange, fileName, error, accept, disabled }) {
    return (
        <div className={`browseFile ${disabled ? 'browseFile_disabled' : ''}`}>
            <label className="browseFile__label">
                {fileName ? 'Change File' : 'Upload File'}
                <input
                    className="browseFile__input"
                    onChange={onChange}
                    type='file'
                    id={name}
                    name={name}
                    accept={accept}
                    disabled={disabled}
                />
            </label>
            <span className='browseFile__name'>{fileName && 'File name: ' + fileName}</span>
            {error && <ErrorMessage message={error} />}
        </div>
    )
}

export default BrowseFile
