export const SET_APP_REF = 'SET_APP_REF'

const initialState = {
    appRef: '',
}

export default function globalAppRefReducers(state = initialState, { type, payload }) {
    if (type === SET_APP_REF) {
        return {
            ...state,
            appRef: payload.data,
        }
    } else {
        return state
    }
}
