export const CMS_ADDITIONAL_INFORMATION_PRODUCTS_INIT =
  'CMS_ADDITIONAL_INFORMATION_PRODUCTS_INIT'
export const CMS_ADDITIONAL_INFORMATION_USERS_INIT =
  'CMS_ADDITIONAL_INFORMATION_USERS_INIT'
export const CMS_ADDITIONAL_INFORMATION_CORPORATE_ADA_BILLER_INIT =
  'CMS_ADDITIONAL_INFORMATION_CORPORATE_ADA_BILLER_INIT'
export const CMS_ADDITIONAL_INFORMATION_CORPORATE_ADA_PAYOR_INIT =
  'CMS_ADDITIONAL_INFORMATION_CORPORATE_ADA_PAYOR_INIT'
export const CMS_ADDITIONAL_INFORMATION_EGOV_INIT =
  'CMS_ADDITIONAL_INFORMATION_EGOV_INIT'
export const CMS_ADDITIONAL_INFORMATION_EGOV_SET_USER_INFO =
  'CMS_ADDITIONAL_INFORMATION_EGOV_SET_USER_INFO'
export const CMS_ADDITIONAL_INFORMATION_SUPPLY_CHAIN_FINANCING_READONLY_FIELDS_INIT =
  'CMS_ADDITIONAL_INFORMATION_SUPPLY_CHAIN_FINANCING_READONLY_FIELDS_INIT'
export const CMS_ADDITIONAL_INFORMATION_SUPPLY_CHAIN_FINANCING_FIELDS_INIT =
  'CMS_ADDITIONAL_INFORMATION_SUPPLY_CHAIN_FINANCING_FIELDS_INIT'
export const CMS_ADDITIONAL_INFORMATION_SUPPLY_CHAIN_FINANCING_OWNERS_INIT =
  'CMS_ADDITIONAL_INFORMATION_SUPPLY_CHAIN_FINANCING_OWNERS_INIT'
export const CMS_ADDITIONAL_INFORMATION_SUPPLY_CHAIN_FINANCING_ADD_OWNER =
  'CMS_ADDITIONAL_INFORMATION_SUPPLY_CHAIN_FINANCING_ADD_OWNER'
export const CMS_ADDITIONAL_INFORMATION_SUPPLY_CHAIN_FINANCING_EDIT_OWNER =
  'CMS_ADDITIONAL_INFORMATION_SUPPLY_CHAIN_FINANCING_EDIT_OWNER'
export const CMS_ADDITIONAL_INFORMATION_SUPPLY_CHAIN_FINANCING_REMOVE_OWNER =
  'CMS_ADDITIONAL_INFORMATION_SUPPLY_CHAIN_FINANCING_REMOVE_OWNER'
