import { useRouteMatch } from 'react-router-dom'
import rbankLogo from '../../01-assets/images/rbank-logo-green.png'
import { Link } from '../../02-components'
import {
    ACCESS_EXISTING_APP,
    ADD_INDIVIDUAL_ROLES,
    BUSINESS_DOCUMENTS,
    BUSINESS_INFORMATION,
    BUSINESS_INFORMATION_1,
    BUSINESS_INFORMATION_2,
    BUSINESS_INFORMATION_3,
    CONFIRMATION_PAGE,
    ONLINE_BANKING_ENROLLMENT,
    OWNERS_INFORMATION_1,
    REVIEW_AND_SUBMIT,
    SELECT_PRODUCT_PAGE,
    YOUR_APPLICATION_PAGE,
} from '../../07-data/paths'
import {
    CMS_ACCESS_EXISTING_APPLICATION,
    CMS_ADDITIONAL_INFORMATION,
    CMS_APPROVAL_MATRIX,
    CMS_BUSINESS_DOCUMENTS,
    CMS_BUSINESS_INFORMATION,
    CMS_CONFIRMATION,
    CMS_PRICING_DETAILS,
    CMS_REVIEW_AND_SUBMIT,
    CMS_USER_ACCESS_RIGHTS,
    CMS_YOUR_APPLICATION,
} from '../../cms/constants/pathnames'
import NavbarExitButton from './NavbarExitButton'
import NavbarHelpButton from './NavbarHelpButton'
import NavbarHomeButton from './NavbarHomeButton'
import './index.scss'

const navbarMenuRoutes = [
    SELECT_PRODUCT_PAGE,
    YOUR_APPLICATION_PAGE,
    `/:type${ADD_INDIVIDUAL_ROLES}`,
    `/:type${BUSINESS_DOCUMENTS}`,
    `/:type${BUSINESS_INFORMATION}`,
    `/:type${BUSINESS_INFORMATION_1}`,
    `/:type${BUSINESS_INFORMATION_2}`,
    `/:type${BUSINESS_INFORMATION_3}`,
    `/:type${CONFIRMATION_PAGE}`,
    `/:type${ONLINE_BANKING_ENROLLMENT}`,
    `/:type${OWNERS_INFORMATION_1}`,
    `/:type${REVIEW_AND_SUBMIT}`,

    CMS_YOUR_APPLICATION,
    CMS_BUSINESS_INFORMATION,
    CMS_USER_ACCESS_RIGHTS,
    CMS_ADDITIONAL_INFORMATION,
    CMS_APPROVAL_MATRIX,
    CMS_BUSINESS_DOCUMENTS,
    CMS_PRICING_DETAILS,
    CMS_REVIEW_AND_SUBMIT,
    CMS_CONFIRMATION,
]

function NavBar() {
    const showExistingApplicationLink = useRouteMatch({
        path: '/',
        exact: true,
    })
    const showNavbar = useRouteMatch(navbarMenuRoutes)
    const showOnlyExitButton = useRouteMatch(YOUR_APPLICATION_PAGE)

    return (
        <div className="navbar">
            <div className="navbar__logo">
                <Link onClick={(e) => e.preventDefault()} type="normal">
                    <img
                        className="navbar__logo-img"
                        src={rbankLogo}
                        alt="logo"
                        width={800}
                        height={121}
                    />
                </Link>
            </div>

            {showExistingApplicationLink && (
                <div className="navbar__right">
                    <Link type="secondary" to={ACCESS_EXISTING_APP}>
                        Go to Existing Application
                    </Link>{' '}
                    <Link type="secondary" to={CMS_ACCESS_EXISTING_APPLICATION}>
                        Go to Existing CMS Application
                    </Link>
                </div>
            )}

            {showNavbar && (
                <div className="navbar__right">
                    <div className="navbar__menu">
                        {!showOnlyExitButton && <NavbarHomeButton />}
                        {!showOnlyExitButton && <NavbarHelpButton />}
                        <NavbarExitButton />
                    </div>
                </div>
            )}
        </div>
    )
}

export default NavBar
