import React from 'react'
import './index.scss'

const BASE_CLASSNAME = 'button'

function Button({
    extraClassName,
    type,
    isSecondary,
    linkButton,
    alternateColor,
    block,
    ...props
}) {
    let className = BASE_CLASSNAME
    if (isSecondary) {
        className += ` ${BASE_CLASSNAME}_outline`
    }
    if (props.disabled) {
        className += ` ${BASE_CLASSNAME}_disabled`
    }
    if (alternateColor) {
        className += ` ${BASE_CLASSNAME}_color_blue`
    }
    if (linkButton) {
        className += ` ${BASE_CLASSNAME}_link-button`
    }
    if (block) {
        className += ` ${BASE_CLASSNAME}_block`
    }

    if (extraClassName) {
        className += ` ${extraClassName}`
    }

    return <button {...props} className={className} type={type} />
}

export default Button
