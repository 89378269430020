import React from 'react'
import ScaleLoader from 'react-spinners/ScaleLoader'
import './index.scss'

function Spinner() {
    return (
        <div className="spinner">
            <div className='spinner-loader'>
                <ScaleLoader height={55} color='#6ab63e' loading />
                <br />
                <h4 style={{ color: '#99DF71' }}>Loading ...</h4>{' '}
            </div>
        </div>
    )
}

export default Spinner
