// @ts-check

import {
  CMS_ADDITIONAL_INFORMATION_SUPPLY_CHAIN_FINANCING_ADD_OWNER,
  CMS_ADDITIONAL_INFORMATION_SUPPLY_CHAIN_FINANCING_EDIT_OWNER,
  CMS_ADDITIONAL_INFORMATION_SUPPLY_CHAIN_FINANCING_FIELDS_INIT,
  CMS_ADDITIONAL_INFORMATION_SUPPLY_CHAIN_FINANCING_OWNERS_INIT,
  CMS_ADDITIONAL_INFORMATION_SUPPLY_CHAIN_FINANCING_READONLY_FIELDS_INIT,
  CMS_ADDITIONAL_INFORMATION_SUPPLY_CHAIN_FINANCING_REMOVE_OWNER,
} from '../types'

/**
 * @typedef {object} ScfOwner
 * @property {string} id
 * @property {string} name
 * @property {string} age
 * @property {string} position
 * @property {string} ownership
 * @property {string} contactNumber
 * @property {string} email
 */

const initialState = {
  readonlyFields: {
    companyOrTradeName: '',
    dateEstablished: '',
    businessOrBorrowerType: '',
    businessAddress: '',
    tinNo: '',
  },
  fields: {
    secNo: '',
    vatNo: '',
    anchorBuyer: '',
    /** @type {'yes' | 'no' | ''} */
    businessUnitServed: '',
    /** @type {'yes' | 'no' | ''} */
    authorizedWithholdingAgent: '',
    natureOfBusiness: '',
    natureOfBusinessOther: '',
    productsOrServices: '',
    rbankAccountNumber: '',
    authorization: false,
    nomination: false,
    acknowledgment: false,
  },
  owners: {
    /** @type {{ [id: string]: ScfOwner | null }} */
    byId: {},
    /** @type {string[]} */
    allIds: [],
  },
}

/**
 * @typedef {object} SupplyChainFinancingReadonlyFieldsInitActionPayload
 * @property {string} companyOrTradeName
 * @property {string} dateEstablished
 * @property {string} businessOrBorrowerType
 * @property {string} businessAddress
 * @property {string} tinNo
 *
 * @typedef {object} SupplyChainFinancingReadonlyFieldsInitAction
 * @property {typeof CMS_ADDITIONAL_INFORMATION_SUPPLY_CHAIN_FINANCING_READONLY_FIELDS_INIT} type
 * @property {SupplyChainFinancingReadonlyFieldsInitActionPayload} payload
 *
 * @typedef {object} SupplyChainFinancingFieldsInitActionPayload
 * @property {string} secNo
 * @property {string} vatNo
 * @property {string} anchorBuyer
 * @property {'yes' | 'no' | ''} businessUnitServed
 * @property {'yes' | 'no' | ''} authorizedWithholdingAgent
 * @property {string} natureOfBusiness
 * @property {string} natureOfBusinessOther
 * @property {string} productsOrServices
 * @property {string} rbankAccountNumber
 * @property {boolean} authorization
 * @property {boolean} nomination
 * @property {boolean} acknowledgment
 *
 * @typedef {object} SupplyChainFinancingFieldsInitAction
 * @property {typeof CMS_ADDITIONAL_INFORMATION_SUPPLY_CHAIN_FINANCING_FIELDS_INIT} type
 * @property {SupplyChainFinancingFieldsInitActionPayload} payload
 *
 * @typedef {object} SupplyChainFinancingOwnersInitActionPayload
 * @property {ScfOwner[]} owners
 *
 * @typedef {object} SupplyChainFinancingOwnersInitAction
 * @property {typeof CMS_ADDITIONAL_INFORMATION_SUPPLY_CHAIN_FINANCING_OWNERS_INIT} type
 * @property {SupplyChainFinancingOwnersInitActionPayload} payload
 *
 * @typedef {object} SupplyChainFinancingAddOwnerAction
 * @property {typeof CMS_ADDITIONAL_INFORMATION_SUPPLY_CHAIN_FINANCING_ADD_OWNER} type
 * @property {ScfOwner} payload
 *
 * @typedef {object} SupplyChainFinancingEditOwnerAction
 * @property {typeof CMS_ADDITIONAL_INFORMATION_SUPPLY_CHAIN_FINANCING_EDIT_OWNER} type
 * @property {ScfOwner} payload
 *
 * @typedef {object} SupplyChainFinancingRemoveOwnerAction
 * @property {typeof CMS_ADDITIONAL_INFORMATION_SUPPLY_CHAIN_FINANCING_REMOVE_OWNER} type
 * @property {{ id: string }} payload
 *
 * @typedef {| SupplyChainFinancingReadonlyFieldsInitAction
 * | SupplyChainFinancingFieldsInitAction
 * | SupplyChainFinancingOwnersInitAction
 * | SupplyChainFinancingAddOwnerAction
 * | SupplyChainFinancingEditOwnerAction
 * | SupplyChainFinancingRemoveOwnerAction} Actions
 */

/**
 * @param {typeof initialState} state
 * @param {Actions} action
 * @returns {typeof initialState}
 */
export default function supplyChainFinancingReducer(
  state = initialState,
  { type, payload }
) {
  switch (type) {
    case CMS_ADDITIONAL_INFORMATION_SUPPLY_CHAIN_FINANCING_READONLY_FIELDS_INIT: {
      return {
        ...state,
        readonlyFields: payload,
      }
    }
    case CMS_ADDITIONAL_INFORMATION_SUPPLY_CHAIN_FINANCING_FIELDS_INIT: {
      return {
        ...state,
        fields: payload,
      }
    }
    case CMS_ADDITIONAL_INFORMATION_SUPPLY_CHAIN_FINANCING_OWNERS_INIT: {
      const { owners } = payload

      /** @type {typeof state.owners.byId} */
      const byId = {}
      const allIds = []
      for (const owner of owners) {
        byId[owner.id] = {
          id: owner.id,
          name: owner.name,
          age: owner.age,
          ownership: owner.ownership,
          position: owner.position,
          contactNumber: owner.contactNumber,
          email: owner.email,
        }
        allIds.push(owner.id)
      }

      return {
        ...state,
        owners: {
          byId,
          allIds,
        },
      }
    }
    case CMS_ADDITIONAL_INFORMATION_SUPPLY_CHAIN_FINANCING_ADD_OWNER: {
      if (state.owners.byId[payload.id]) {
        return state
      }

      return {
        ...state,
        owners: {
          byId: {
            ...state.owners.byId,
            [payload.id]: payload,
          },
          allIds: [...state.owners.allIds, payload.id],
        },
      }
    }
    case CMS_ADDITIONAL_INFORMATION_SUPPLY_CHAIN_FINANCING_EDIT_OWNER: {
      if (!state.owners.byId[payload.id]) {
        return state
      }

      return {
        ...state,
        owners: {
          ...state.owners,
          byId: {
            ...state.owners.byId,
            [payload.id]: payload,
          },
        },
      }
    }
    case CMS_ADDITIONAL_INFORMATION_SUPPLY_CHAIN_FINANCING_REMOVE_OWNER: {
      const updatedOwnersById = { ...state.owners.byId }
      delete updatedOwnersById[payload.id]

      return {
        ...state,
        owners: {
          byId: updatedOwnersById,
          allIds: state.owners.allIds.filter((id) => id !== payload.id),
        },
      }
    }
    default: {
      return state
    }
  }
}
