import React, { memo } from 'react'
import { Typography } from '../../02-components'
import './index.scss'

function Declined({ user, message }) {
    return (
        <>
            <Typography type='h4'>Dear {user},</Typography>

            <Typography type='p'>
                We regret to inform you that your application is declined.
            </Typography>

            <div className='declined-inquiry'>
                <Typography type='p'>{message}</Typography>
            </div>
        </>
    )
}

export default memo(Declined)
