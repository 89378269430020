import {
  CMS_BUSINESS_INFO_FETCH,
  CMS_SET_SELECTED_ACCOUNT_NUMBER_ID,
} from './types'

const initialState = {
  data: {
    cifNumber: '',
    accountNumbers: [],
    otherName: '',
    dateOfRegistration: '',
    companyName: '',
    // businessTIN: '',
    dtiSecRegistrationNumber: '',
    businessType: '',
    // corporateType: '',
    countryOfIncorporation: '',
    sourceOfFunds: '',
    // onlinePresence: '',
    registeredBusinessAddress: '',
    region: '',
    province: '',
    cityTown: '',
    zipCode: '',
    country: '',
  },
  selectedAccountNumberId: '',
}

/**
 * @returns {typeof initialState}
 */
export default function cmsBusinessInformationReducer(
  state = initialState,
  { type, payload }
) {
  switch (type) {
    case CMS_BUSINESS_INFO_FETCH: {
      return {
        ...state,
        data: {
          ...state.data,
          ...payload,
        },
        selectedAccountNumberId: payload.accountNumbers[0]?.id ?? '',
      }
    }
    case CMS_SET_SELECTED_ACCOUNT_NUMBER_ID: {
      return {
        ...state,
        selectedAccountNumberId: payload.accountNumberId,
      }
    }
    default: {
      return state
    }
  }
}
