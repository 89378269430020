// @ts-check

export const CMS_ROOT = '/cms'
export const CMS_ACCESS_EXISTING_APPLICATION =
  '/cms/access-existing-application'
export const CMS_ADDITIONAL_INFORMATION = '/cms/additional-information'
export const CMS_APPROVAL_MATRIX = '/cms/approval-matrix'
export const CMS_BUSINESS_DOCUMENTS = '/cms/business-documents'
export const CMS_BUSINESS_INFORMATION = '/cms/business-information'
export const CMS_CONFIRMATION = '/cms/confirmation'
export const CMS_PRICING_DETAILS = '/cms/pricing-details'
export const CMS_REGISTRATION_ERROR = '/cms/registration-error'
export const CMS_REPRESENTATIVE_INFORMATION = '/cms/representative-information'
export const CMS_REVIEW_AND_SUBMIT = '/cms/review-and-submit'
export const CMS_USER_ACCESS_RIGHTS = '/cms/user-access-rights'
export const CMS_USER_VERIFICATION = '/cms/user-verification'
export const CMS_VALIDATE_OTP = '/cms/validate-otp'
export const CMS_YOUR_APPLICATION = '/cms/your-application'
