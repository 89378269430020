import React from 'react'
import { AiFillQuestionCircle } from 'react-icons/ai'
import './index.scss'

function Tooltip({ description, list }) {
    return (
        <div className='tooltip'>
            <AiFillQuestionCircle />
            {description && <span>{description}</span>}
            {list && (
                <ul>
                    {list.map((l, i) => (
                        <li key={i}>{l}</li>
                    ))}
                </ul>
            )}
        </div>
    )
}

export default Tooltip
