import React from 'react'
import './index.scss'

function RadioButton({ title, name, checked, value, onChange, ...inputProps }) {
    return (
        <label className={`radioButton${inputProps.disabled ? ' radioButton_disabled' : ''}`}>
            <input {...inputProps} onChange={onChange} checked={checked} type='radio' name={name} value={value} />
            <span>{title}</span>
        </label>
    )
}

export default RadioButton
