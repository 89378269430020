import React, { useEffect, useState } from 'react'
import { AiFillCheckCircle } from 'react-icons/ai'
import './index.scss'

function StepCheck({ showLine = true, secondToLast = false, children, ...props }) {
    const [height, setHeight] = useState(0)
    const minus = 15

    useEffect(() => {
        const container = document.querySelector('[data-is-second-to-last=true]')

        const container2 = document.querySelector('.stepperCheck')

        setHeight(secondToLast ? container?.clientHeight - minus : container2?.clientHeight - minus)
    }, [secondToLast])
    useEffect(() => {
        const container = document.querySelector('[data-is-second-to-last=true]')
        const container2 = document.querySelector('.stepperCheck')

        const handleResize = () => {
            if (secondToLast) setHeight(container?.clientHeight)
            else setHeight(container2?.clientHeight - minus)
        }

        window.addEventListener('resize', handleResize)

        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [height, secondToLast])

    return (
        <div className='stepperCheck' {...props}>
            <div className='stepperCheckContainer'>
                <AiFillCheckCircle className='stepperCheckIcon' />
                <span
                    className={`${showLine ? 'show' : ''}`}
                    style={{ height: `${height}px` }}
                ></span>
            </div>
            <div style={{ textAlign: 'left' }}>{children}</div>
        </div>
    )
}

export default StepCheck
